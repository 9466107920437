import { ThemeProperties } from "../../../Theme/ThemeProperties";
import { Typography, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { Tooltip } from "antd";
import Events from "../../../Analytics/Events";
import { getColumnTitleSort } from "../../../Features/Table/AntDesign/TableFunctions";
import { convertUTCtoZone } from "../../../Helper/DatePicker/DateConverter";
import TextHyperLinkWithCopyIcon from "../../../Helper/Operations/TextHyperLinkWithCopyIcon";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

function renderBatteryIDCopy(item, name) {
  return (
    <>
      {item && (
        <>
          <Typography variant="tableRow">{item}</Typography>
          <Tooltip title={"Click to copy"} zIndex={30000}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                copy(item);
                Events(`Copied ${name}`);
              }}
            >
              <ContentCopyIcon
                sx={{
                  fontSize: "13px",
                  cursor: "pointer",
                  "&:hover": {
                    color: ThemeProperties.purple,
                  },
                }}
              />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
}

function OnBatteryIDClick() {}

const newColumns = [
  {
    dataIndex: "pingStatus",
    key: "pingStatus",
    fixed: "left",
    defaultSortOrder: "",
    showSorterTooltip: false,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "pingStatusActive",
    align: "center",
    width: 40,
    render: (pingStatus) => (
      <CircleRoundedIcon
        sx={{
          color: pingStatus ? "green" : "#A9A9A9",
          fontSize: "14px",
        }}
      />
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Device ID</Typography>,
    name: "Device ID",
    fixed: "left",
    sorter: false,
    filterSearch: false,
    width: 170,
    className: "undragableColumn",
    // maxWidth:"18px",
    render: (record) => (
      <div className="renderID">
        {TextHyperLinkWithCopyIcon(
          record.batteryID,
          "Device ID",
          OnBatteryIDClick,
          record
        )}
      </div>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Alert Description</Typography>,
    name: "Alert Description",
    dataIndex: "alertDescription",
    key: "alertDescription",
    sorter: false,
    showSorterTooltip: false,
    checkedOptions: [],
    width: 170,
    render: (alertDescription) => (
      <Typography variant="tableRow">{alertDescription}</Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Alert State</Typography>,
    dataIndex: "alertStatus",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (alertStatus) => (
      <Typography variant="tableRow">{alertStatus}</Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Severity Latest</Typography>,
    dataIndex: "severityName",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    width: 160,
    ellipsis: {
      showTitle: false,
    },
    render: (severityName) => (
      <Typography variant="tableRow">{severityName}</Typography>
    ),
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "startTime", "Alert First Seen"),
    name: "Alert First Seen",
    dataIndex: "startTime",
    key: "startTime",

    sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime),
    defaultSortOrder: "descend",
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "startTimeActive",
    width: 170,
    render: (startTime) => (
      <Typography variant="tableRow">{convertUTCtoZone(startTime)}</Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Packs Affected</Typography>,
    dataIndex: "packCount",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (packCount) => (
      <Typography variant="tableRow">{packCount}</Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Modules Affected</Typography>,
    dataIndex: "moduleCount",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    width: 140,
    ellipsis: {
      showTitle: false,
    },
    render: (moduleCount) => (
      <Typography variant="tableRow">{moduleCount}</Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Cells Affected</Typography>,
    dataIndex: "cellCount",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (cellCount) => (
      <Typography variant="tableRow">{cellCount}</Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Action Taken By</Typography>,
    dataIndex: "openedBy",
    sorter: false,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "openedByActive",
    width: 170,
    render: (openedBy) => (
      <Typography variant="tableRow">{openedBy}</Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Action Taken By</Typography>,
    dataIndex: "openedBy",
    sorter: false,
    width: 170,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "openedByActive",
    render: (openedBy) => (
      <Typography variant="tableRow">{openedBy}</Typography>
    ),
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "openedAt", "Action Taken At"),
    name: "Action Taken At",
    dataIndex: "openedAt",
    key: "openedAt",
    sorter: (a, b) => new Date(a.openedAt) - new Date(b.openedAt),
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
    showSorterTooltip: false,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "openedAtActive",
    width: 170,
    render: (openedAt) => (
      <Typography variant="tableRow">
        {convertUTCtoZone(openedAt)}
      </Typography>
    ),
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "statusUpdateTime", "Resolved At"),
    name: "Resolved At",
    dataIndex: "statusUpdateTime",
    key: "statusUpdateTime",
    sorter: (a, b) => new Date(a.statusUpdateTime) - new Date(b.statusUpdateTime),
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
    showSorterTooltip: false,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "statusUpdateTimeActive",
    width: 170,
    render: (statusUpdateTime) => (
      <Typography variant="tableRow">
        {convertUTCtoZone(statusUpdateTime)}
      </Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Resolution Time</Typography>,
    name: "Resolution Time",
    dataIndex: "activeDuration",
    key: "activeDuration",
    sorter: false,
    showSorterTooltip: false,
    checkedOptions: [],
    width: 170,
    uniqueKey: "activeDurationActive",
    render: (activeDuration) => (
      <Typography variant="tableRow">{activeDuration}</Typography>
    ),
    tabs: ["Resolved"],
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "statusUpdateTime", "Expired At"),
    name: "Expired At",
    dataIndex: "statusUpdateTime",
    key: "statusUpdateTime",
    sorter: (a, b) => new Date(a.statusUpdateTime) - new Date(b.statusUpdateTime),
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
    showSorterTooltip: false,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "statusUpdateTimeActive",
    width: 170,
    render: (statusUpdateTime) => (
      <Typography variant="tableRow">
        {convertUTCtoZone(statusUpdateTime)}
      </Typography>
    ),
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "soc", "SOC-Latest (%)"),
    name: "SOC-Latest (%)",
    dataIndex: "soc",
    key: "soc",
    align: "center",
    sorter: (a, b) => new Date(a.soc) - new Date(b.soc),
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "",
    showSorterTooltip: false,
    checkedOptions: [],
    width: 160,
    uniqueKey: "socActive",
    render: (soc) => (
      <Typography variant="tableRow">{soc}</Typography>
    ),
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "voltage", "Voltage-Latest (V)"),
    name: "Voltage-Latest (V)",
    dataIndex: "voltage",
    key: "voltage",
    align: "center",
    sorter: (a, b) => new Date(a.voltage) - new Date(b.voltage),
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "",
    showSorterTooltip: false,
    checkedOptions: [],
    width: 160,
    uniqueKey: "voltageActive",
    render: (voltage) => (
      <Typography variant="tableRow">
        {voltage?.toFixed(1)}
      </Typography>
    ),
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "temperature", "Temperature-Latest (°C)"),
    name: "Temperature-Latest (°C)",
    dataIndex: "temperature",
    key: "temperature",
    align: "center",
    sorter: (a, b) => new Date(a.temperature) - new Date(b.temperature),
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "",
    showSorterTooltip: false,
    checkedOptions: [],
    width: 160,
    uniqueKey: "temperatureActive",
    render: (temperature) => (
      <Typography variant="tableRow">{temperature}</Typography>
    ),
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
    name: "Last Pinged",
    dataIndex: "lastPingedTime",
    key: "lastPingedTime",
    sorter: (a, b) => new Date(a.lastPingedTime) - new Date(b.lastPingedTime),
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
    showSorterTooltip: false,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "lastPingedTimeActive",
    width: 170,
    render: (lastPingedTime) => (
      <Typography variant="tableRow">
        {convertUTCtoZone(lastPingedTime)}
      </Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Location</Typography>,
    dataIndex: "location",
    sorter: false,
    width: 170,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "locationActive",
    render: (location) => (
      <Typography variant="tableRow">{location}</Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">Organization</Typography>,
    dataIndex: "applicationOEM",
    sorter: false,
    width: 170,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "applicationOEMActive",
    render: (applicationOEM) => (
      <Typography variant="tableRow">{applicationOEM}</Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">IOT</Typography>,
    dataIndex: "iotOEM",
    sorter: false,
    width: 170,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "iotOEMActive",
    render: (iotOEM) => (
      <Typography variant="tableRow">{iotOEM}</Typography>
    ),
  },
  {
    title: <Typography variant="tableHeadBold">ProductID</Typography>,
    dataIndex: "cellModelNumber",
    sorter: false,
    width: 170,
    filterMode: "tree",
    filterSearch: false,
    uniqueKey: "cellModelNumberActive",
    render: (cellModelNumber) => (
      <Typography variant="tableRow">{cellModelNumber}</Typography>
    ),
  },
];

export { newColumns };
