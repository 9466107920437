import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPagesMetaData, getFilterData } from "../Api/Api";
import CODE from "../Static/Constants/StatusCodes";
import { SetSessionExpired } from "../Actions";
import { getMetricTimeStamp } from "../Helper/DatePicker/DateConverter";
import TextWithCopyIcon from "../Helper/Operations/TextWithCopyIcon";
import TextHyperLinkWithCopyIcon from "../Helper/Operations/TextHyperLinkWithCopyIcon";
import { Box, Tooltip, IconButton, Typography } from "@mui/material";
import CustomIcon from "../Components/CustomIcon"
import info from "../Assets/Images/Common/info.png";
import { getColumnTitleSort } from "../Features/Table/AntDesign/TableFunctions";
import { SetFilterValues } from "../Actions/Filters";
import { extractArrayValuesForFilters } from "../Helper/Operations/GetFilterKeyValue";
import { SetSearchConfig, SetSearchType } from "../Actions/Search";
import { decryptTheParams } from "../Helper/QueryParams/EncryptDecrypt";

export const usePageMeta = (pageName) => {  
  const dispatch = useDispatch();
  const [pagesContent, setPagesContent] = useState({
    pageName,
    batteryType: "",
    mounted: false,
  });

  useEffect(() => {
    getPageMeta();
  }, []);

  const fetchFiltersData = async (filterConfigurations) => {
    let filtersData = {};
    
    if (!filterConfigurations) {
      return filtersData
    }

    await Promise.all(
      Object.keys(filterConfigurations).map(async (sectionKey) => {
        const sectionFiltersList = filterConfigurations[sectionKey]?.filters || [];

        for (let filter of sectionFiltersList) {
          if (filtersData[filter.key]) {
            continue;
          }

          if (filter.type === 'static') {
            filtersData[filter.key] = filter.staticData;
            continue;
          }
  
          const res = await getFilterData(filter);
          if (res && res?.responseStatus?.code === CODE.SUCCESS) {
            filtersData[filter.key] = res.response.response.filterData;
          }     
        }
      })
    );
  
    return filtersData;
  };

  const getPageMeta = async () => {
    const res = await getPagesMetaData(pageName);
    let filterConfigurations = {};
    let columns = [];
    let searchSelectConfig = {};
    let pageHeader = "";
    const queryParams = decryptTheParams()

    if (res && res?.responseStatus?.code === CODE.SUCCESS) {
      const pageData = res.response?.response
      const tablesData = pageData.metaInfo.table
      filterConfigurations = pageData.metaInfo?.filterConfigurations
      pageHeader = pageData.metaInfo?.pageHeader
      
      searchSelectConfig = pageData.metaInfo?.searchSelectOptions
      if (searchSelectConfig) {
        dispatch(SetSearchConfig(searchSelectConfig))
        dispatch(SetSearchType(queryParams.selectedSearchType || searchSelectConfig?.defaultValue))
      }

      columns = initializeTableColumns(tablesData)
    } else if (res?.responseStatus?.code === CODE.SESSION_EXPIRED) {
      SetSessionExpired(true);
      return null;
    }

    const filtersValues = await fetchFiltersData(filterConfigurations);
    const defaultFilterValues = extractArrayValuesForFilters(filtersValues)
    
    let selectedFiltersValues = defaultFilterValues
    if (queryParams.filters) {
      selectedFiltersValues = JSON.parse(queryParams.filters)
    }
    dispatch(SetFilterValues(selectedFiltersValues))
    
    setPagesContent({
      ...pagesContent,
      filtersOptions: filtersValues,
      pageHeader, pageHeader,
      defaultFilterValues: defaultFilterValues,
      filterConfigurations: filterConfigurations,
      searchSelectConfig: searchSelectConfig,
      columns: columns
    });
  };

  function initializeTableColumns(tablesData) {
    const columnsConfig = [];
    Object.keys(tablesData).map((tableKey) => {
      const config = tablesData[tableKey] || [];
      columnsConfig.push({
        name: tableKey,
        columns: getColumnsConfig(config)
      })
    })

    return columnsConfig
  }

  const getColumnsConfig = (tableData) => {
    const columnOrdering = tableData?.columnOrdering || []
    const columnsConfig = []
    columnOrdering.map((columnKey) => {
      const config = tableData.columnsConfig?.[columnKey]
      if (!config) {
        return null
      }
      columnsConfig.push({
        dataIndex: config?.dataIndex,
        title: titleProps => getColumnTitleFromTitleType(
          config.title, 
          titleProps, 
          config.titleRenderingType, 
          config?.dataIndex, 
          config?.sorter,
          config?.icon
        ),
        key: config?.key || columnKey,
        sorter: config?.sorter || false,
        defaultSortOrder: config?.defaultSortOrder || "",
        showSorterTooltip: config?.showSorterTooltip || false,
        sortDirections: config?.sortDirections,
        filterMode: config?.filterMode || "tree",
        filterSearch: config?.filterSearch || false,
        decimalPlaces: config?.decimalPlaces || 0,
        uniqueKey: columnKey+"UniqueKey",
        dbColumnKey: config?.dbColumnKey,
        align: config?.align || "left",
        width: config?.width || 170,
        render: (value, record) => getColumnValueFromValueType(
          record,
          config
        ),
        show: config?.tabs,
      })
    })

    return columnsConfig
  }

  const getColumnTitleFromTitleType = (title, titleProps, type, key, sorter, icon) => {
    if (type === 'bold-text' && sorter === true) {
      return getColumnTitleSort(titleProps, key, title)
    }
    
    if (type === 'bold-text') {
      return <Typography variant="tableHeadBold">{title}</Typography>
    }

    if (type === "text-with-icon") {
      let iconComponent
      switch (icon?.type) {
        case "info":
        default:
          iconComponent = info
      }
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="tableHeadBold">{title}</Typography>
          <Tooltip
            placement={icon?.tooltip?.placement}
            title={
              <Box display="flex" flexDirection="column">
                {icon?.tooltip?.textLines?.map((line) => (
                  <h2>{line}</h2>
                ))}
              </Box>
            }
            zIndex={2000}
          >
            <IconButton>
              <img src={iconComponent} height={15} width={15} />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  }

  const getColumnValueFromValueType = (record, config, callback = () => {}) => {
    const value = record?.[config?.dataIndex];
    const decimalPlaces = config?.decimalPlaces
    const type = config?.valueRenderingType
    const copyToClipBoardTypeName = config?.copyToClipBoardTypeName

    switch(type) {
      case 'ping-status-icon':
        return <CustomIcon name={value === "Active" ? "online" : "offline"} style={{ width: "24px" }} />;
      case 'text':
        return <Typography variant="tableRow">{value}</Typography>;
      case 'text-with-copy':
        return <div className="renderID">{TextWithCopyIcon(value, copyToClipBoardTypeName)}</div>;
      case 'number':
        return <Typography variant="tableRow">{value?.toFixed(decimalPlaces)}</Typography>;
      case 'timestamp':
        return <Typography variant="tableRow">{getMetricTimeStamp(value)}</Typography>;
      case 'digital-twin-list-series-parallel':
        const level = config?.seriesParallelConfigLevel
        const tableValue = level === "pack" 
                            ? `${record?.seriesPacks}S / ${record?.parallelPacks}P` 
                            : level === "module"
                              ? `${record?.seriesModulesInPack}S / ${record?.parallelModulesInPack}P`
                              : `${record?.seriesModulesInPack * record?.seriesCellsInModule}S / ${record?.parallelModulesInPack * record?.parallelCellsInModule}P`
        return <Typography variant="tableRow">{tableValue}</Typography>;
      case 'digital-twin-list-edit':
        return callback();
      case 'battery-configuration-edit':
        return callback();
      case 'digital-twin-link':
        return <div className="renderID">{TextHyperLinkWithCopyIcon(value, "Device ID", callback, record)}</div>;
      case 'battery-config-link':
        return <div className="renderID">{TextHyperLinkWithCopyIcon(value, "Config Name", callback, record)}</div>;
      case 'age':
        return <Typography variant="tableRow">{value ? (value/30).toFixed(decimalPlaces) : ""}</Typography>
      default:
        return null;
    }
  }

  return { pagesContent };
};
