import React, { useEffect, useRef, useState } from "react";
import DashboardMain from "./DashboardMain";
import { getPagesComponents } from "../../Api/Api";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  socFilter,
  socFilter1,
  socFilter2,
  partner,
  partner1,
  partner2,
} from "../../Static/Data/_Common/TempFilters";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Input,
  Checkbox,
} from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  SetIsBatteryIsPartnerIsNull,
  SetDashboardCategory,
  SetDashboardLocation,
  SetDashboardStatus,
  SetDashboardColumns,
  SetDashboardHiddenColumns,
  SetDashboardType,
  SetDashboardStartDate,
  SetDashboardEndDate,
  SetDashboardBatteryID,
  SetDashboardPartner,
  SetDashboardTabValue,
  SetDashboardBatteryPartnerSelect,
  SetDashboardSeverity,
  SetDashboardIOT,
  SetDashboardPack,
  SetDashboardStack,
  SetDashboardTabCounts,
  SetDashboardFilters,
  SetDashboardOrganization,
} from "../../Actions/Dashboard";
import { SetSessionExpired } from "../../Actions";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import NoInternet from "../../Features/Dialog/NoInternet";
// import { stack } from "../../Static/Data/Dashboard/DashboardResponses";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { GetAlertsColumns } from "../../Static/Data/Dashboard/AlertsColumns";
import { getDashboardFilters } from "../../Api/DashboardApi";
import CODE from "../../Static/Constants/StatusCodes";

function getActualArrayValues(data) {
  let arr = data.length === 0 ? [] : data.split(",");
  return arr;
}

function Dashboard(props) {
  const dispatch = useDispatch();
  const [pagesContent, setPagesContent] = useState({
    pageHeader: "",
    filtersWithData: [],
    searchTypeFilter: {},
    filteredArray: [],
    mounted: false,
  });
  const searchInput = useRef(null);
  const [alertTypes, setAlertTypes] = useState([])
  const tempSocFilter = [...socFilter],
    tempPartnerFilter = [...partner],
    tempSocFilter1 = [...socFilter1],
    tempPartnerFilter1 = [...partner1],
    tempSocFilter2 = [...socFilter2],
    tempPartnerFilter2 = [...partner2];
  tempSocFilter.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempPartnerFilter.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempSocFilter1.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempPartnerFilter1.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempSocFilter2.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempPartnerFilter2.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });

  const dashboardFilters = useSelector((state) => state.DashboardFilters);
  const columns = GetAlertsColumns();
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    GetFilters();
  }, []);

  useEffect(() => {
    const filterArr = [
      {
        itemName: "Organization",
        items: dashboardFilters?.fleetName,
      },
      {
        itemName: "Severity",
        items: dashboardFilters?.severityName,
      },
      {
        itemName: "Status",
        items: dashboardFilters?.batteryStatus,
      },
      {
        itemName: "Location",
        items: dashboardFilters?.location,
      },
      {
        itemName: "IOT",
        items: dashboardFilters?.iotOEM,
      },
      {
        itemName: "ProductID",
        items: dashboardFilters?.cellModelNumber,
      },
    ];
    setFilters(filterArr);
    dispatch(
      SetDashboardLocation(dashboardFilters?.location?.map((i) => i.value))
    );
    dispatch(
      SetDashboardOrganization(dashboardFilters?.fleetName?.map((i) => i.value))
    );
    dispatch(
      SetDashboardSeverity(dashboardFilters?.severityName?.map((i) => i.value))
    );
    dispatch(SetDashboardIOT(dashboardFilters?.iotOEM?.map((i) => i.value)));
    dispatch(
      SetDashboardPack(dashboardFilters?.cellModelNumber?.map((i) => i.value))
    );
    dispatch(
      SetDashboardStatus(dashboardFilters?.batteryStatus?.map((i) => i.value))
    );
  }, [dashboardFilters]);

  useEffect(() => {
    const newBatteryType =
      props.batteryType === undefined || props.batteryType === ""
        ? ""
        : props.batteryType;
    setTimeout(() => {
      dispatch(SetDashboardType(newBatteryType));
      // dispatch(SetDashboardStack(stack));
      let newFilterWithData = [];
      filters?.forEach(
        (
          item //res.response.response.filter.filtersWithData
        ) => newFilterWithData.push(item["itemName"])
      );
      const filteredArray = newFilterWithData;

      const queryParams = decryptTheParams();

      for (var value of Object.keys(queryParams)) {
        if (filteredArray.includes(value)) {
          if (value === "Organization") {
            dispatch(
              SetDashboardOrganization(getActualArrayValues(queryParams[value]))
            );
          } else if (value === "Location") {
            dispatch(
              SetDashboardLocation(getActualArrayValues(queryParams[value]))
            );
          } else if (value === "Status") {
            dispatch(
              SetDashboardStatus(getActualArrayValues(queryParams[value]))
            );
          } else if (value === "Severity") {
            dispatch(
              SetDashboardSeverity(getActualArrayValues(queryParams[value]))
            );
          } else if (value === "IOT") {
            dispatch(SetDashboardIOT(getActualArrayValues(queryParams[value])));
          } else if (value === "ProductID") {
            dispatch(
              SetDashboardPack(getActualArrayValues(queryParams[value]))
            );
          }
        } else {
          if (value === "startDate") {
            dispatch(SetDashboardStartDate(queryParams[value]));
          } else if (value === "endDate") {
            dispatch(SetDashboardEndDate(queryParams[value]));
          } else if (value === "tabValue") {
            dispatch(SetDashboardTabValue(+queryParams[value]));
          } else if (value === "batteryPartnerSelect") {
            dispatch(SetDashboardBatteryPartnerSelect(queryParams[value]));
          } else if (value === "batteryID") {
            dispatch(SetDashboardBatteryID(queryParams[value]));
          } else if (value === "partner") {
            dispatch(SetDashboardPartner(queryParams[value]));
          } else if (value === "IsBatteryIsPartnerIsNull") {
            queryParams[value] === "null"
              ? dispatch(SetIsBatteryIsPartnerIsNull(null))
              : dispatch(SetIsBatteryIsPartnerIsNull(queryParams[value]));
          } else if (value === "Alert Type") {
            dispatch(SetDashboardCategory(queryParams[value]));
          }
        }
      }
      setPagesContent({
        ...pagesContent,
        pageHeader: "Alerts",
        filtersWithData: filters,
        searchTypeFilter: {
          // res.response.response.filter.searchTypeFilter
          items: ["Battery ID"],
          itemName: "Select",
        },
        filteredArray: filteredArray,
        batteryType: newBatteryType,
        alertType: alertTypes,
        mounted: true,
      });

      const handleSearch = (
        selectedKeys,
        confirm,
        dataIndex,
        tabvalue,
        close
      ) => {
        const tempColumns = [...columns];
        tempColumns[tabvalue]["columns"].forEach((col) => {
          if (col.dataIndex === dataIndex) {
            col.checkedOptions = [...selectedKeys];
          }
        });
        dispatch(SetDashboardColumns([...tempColumns]));
        close();
      };
      const handleReset = (
        setSelectedKeys,
        confirm,
        filters,
        dataIndex,
        tabvalue,
        close
      ) => {
        const tempColumns = [...columns];
        filters.forEach((ele) => {
          ele.isChecked = false;
        });
        tempColumns[tabvalue]["columns"].forEach((col) => {
          if (col.dataIndex === dataIndex) {
            col.checkedOptions = [];
          }
        });
        setSelectedKeys([]);
        dispatch(SetDashboardColumns([...tempColumns]));
        close();
      };
      const onChangeFilter = (
        ele,
        dataIndex,
        selectedKeys,
        setSelectedKeys,
        filters
      ) => {
        if (ele.isChecked) {
          filters.forEach((element) => {
            if (element === ele) {
              ele.isChecked = false;
            }
          });

          const arr =
            selectedKeys.length > 0
              ? selectedKeys.filter((item) => item !== ele.value)
              : [];

          setSelectedKeys(arr);
        } else {
          filters.forEach((element) => {
            if (element === ele) {
              ele.isChecked = true;
            }
          });

          if (selectedKeys[0] === undefined) {
            setSelectedKeys([(selectedKeys[0] = "")]);
          }
          setSelectedKeys([...selectedKeys, ele.value]);
        }
        var isFilterEmpty = true;
        filters.forEach((ele) => {
          if (ele.isChecked === true) {
            isFilterEmpty = false;
          }
        });

        if (isFilterEmpty) {
          setSelectedKeys([]);
        }
      };

      const handleSelectAllFilters = (
        e,
        filters,
        selectedKeys,
        setSelectedKeys,
        dataIndex
      ) => {
        if (e.target.checked) {
          const arr = [];

          if (selectedKeys[0] === undefined) {
            setSelectedKeys([(selectedKeys[0] = "")]);
          }
          filters
            .filter((ele) => ele.value.includes(selectedKeys[0]))
            .forEach((ele, key) => {
              ele.isChecked = true;
              arr.push(ele.value);
            });

          setSelectedKeys([...selectedKeys, ...arr]);
        } else {
          filters.forEach((ele) => {
            ele.isChecked = false;
          });
          setSelectedKeys([]);
        }
      };

      let checker = (arr) => arr.every((v) => v === true);

      const getColumnSearchProps = (dataIndex, tabvalue) => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
          filters,
        }) => (
          <div
            style={{
              padding: 8,
            }}
            // onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => e?.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys([
                  (selectedKeys[0] = e.target.value),
                  ...selectedKeys.splice(1),
                ])
              }
              style={{
                marginBottom: 8,
                display: "block",
              }}
            />

            {filters.filter((ele) =>
              ele.value.includes(
                selectedKeys[0] === undefined ? "" : selectedKeys[0]
              )
            ).length !== 0 ? (
              <div>
                <Checkbox
                  onClick={(e) =>
                    handleSelectAllFilters(
                      e,
                      filters,
                      selectedKeys,
                      setSelectedKeys,
                      dataIndex
                    )
                  }
                  checked={
                    filters.filter((ele) =>
                      ele.value.includes(
                        selectedKeys[0] === undefined ? "" : selectedKeys[0]
                      )
                    ).length !== 0
                      ? checker(
                          filters
                            .filter((ele) =>
                              ele.value.includes(
                                selectedKeys[0] === undefined
                                  ? ""
                                  : selectedKeys[0]
                              )
                            )
                            .forEach((ele) => ele.isChecked === true)
                        )
                      : false
                  }
                >
                  Select all Items
                </Checkbox>

                <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
                  {filters
                    .filter((ele) =>
                      ele.value.includes(
                        selectedKeys[0] === undefined ? "" : selectedKeys[0]
                      )
                    )
                    .forEach((ele, key) => {
                      return (
                        <>
                          <div>
                            <Checkbox
                              key={key}
                              checked={ele.isChecked}
                              onChange={(e) =>
                                onChangeFilter(
                                  ele,
                                  dataIndex,
                                  selectedKeys,
                                  setSelectedKeys,
                                  filters
                                )
                              }
                            >
                              {ele.value}
                            </Checkbox>
                            <br></br>
                          </div>
                        </>
                      );
                    })}
                </div>

                <div className="dropDownBottom">
                  <Button
                    onClick={() =>
                      handleReset(
                        setSelectedKeys,
                        confirm,
                        filters,
                        dataIndex,
                        tabvalue,
                        close
                      )
                    }
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    // disabled={selectedKeys.length>1?false:true}
                    onClick={() =>
                      handleSearch(
                        selectedKeys,
                        confirm,
                        dataIndex,
                        tabvalue,
                        close
                      )
                    }
                  >
                    Apply
                  </Button>
                </div>
              </div>
            ) : (
              <p style={{ textAlign: "center" }}>No Options Found</p>
            )}
          </div>
        ),

        filterMultiple: true,

        onFilter: (value, record) => {
          if (value.length != 0) {
            return record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        },

        onFilterDropdownOpenChange: (visible) => {
          const tempColumns = [...columns];
          if (!visible) {
            tempColumns[tabvalue]["columns"].forEach((col) => {
              if (col.dataIndex === dataIndex) {
                col.filters.forEach((item) => {
                  if (col.checkedOptions.includes(item.text)) {
                    item.isChecked = true;
                  } else {
                    item.isChecked = false;
                  }
                });
              }
            });
            dispatch(SetDashboardColumns([...tempColumns]));
          } else {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        filterResetToDefaultFilteredValue: true,
        filterIcon: (filtered) => (
          <FilterAltIcon
            style={{
              color:
                columns[tabvalue]["columns"].filter(
                  (element) => element.dataIndex === dataIndex
                )[0]["checkedOptions"].length > 0
                  ? ThemeProperties.purple
                  : undefined,
            }}
          />
        ),
        render: (text) => text,
      });

      dispatch(SetDashboardColumns(columns));
      dispatch(SetDashboardHiddenColumns([]));
    }, 2);

    return () => {
      setPagesContent({
        ...pagesContent,
        pageHeader: "",
        filtersWithData: [],
        filteredArray: [],
        batteryType: "",
        mounted: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const GetFilters = async () => {
    await getDashboardFilters().then((res) => {
      if (res && res?.responseStatus?.code === CODE.SUCCESS) {
        const filterData = res.response?.data
        filterData.severityName = filterData?.severityName?.filter(item => item.key !== "Expired")
        dispatch(SetDashboardFilters(filterData));
        setAlertTypes(filterData?.alertType)
        return filterData;
      } else if (res?.responseStatus?.code === CODE.SESSION_EXPIRED) {
        SetSessionExpired(true);
        return null;
      }
    });
  };

  var input = navigator.onLine ? (
    <div>
      {pagesContent.mounted ? (
        <>
          <DashboardMain pagesContent={pagesContent} />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  ) : (
    <NoInternet />
  );
  return <>{input}</>;
}

export default Dashboard;
