import React, { useEffect, useState } from "react";
import {
  Dialog,
  Typography,
  IconButton,
  Box,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OutlinedButton } from "../../Components/CustomButton";
import ToField from "../../Helper/MailToField/ToField";
import {
  getCartBatteries,
  sendPostTakeAction,
  postPopFromCart,
  getSelectAllBatteries,
  getCSVOfBatteries,
  getEmailIDList,
} from "../../Api/CartBatteriesApi";
import CartTable from "../Table/AntDesign/CartTable";

import { newColumns as columns } from "../../Static/Data/Appbar/BatteriesInStackAntd";
import Confirm from "../../Helper/Loaders/Confirm";
import CartLoader from "../../Helper/Loaders/CartLoader";
import Error from "../../Helper/Loaders/Error";
import { useDispatch, useSelector } from "react-redux";
import { SetIsDeleted, SetSessionExpired } from "../../Actions";
import { DashboardExistingBatteries } from "../../Actions/Dashboard";
import Events from "../../Analytics/Events";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import FindTheSeverity from "../../Helper/Operations/FindTheSeverity";
import DownloadCSV from "../../Helper/CSVOperations/DownloadCSV";
import NameTheCSV from "../../Helper/CSVOperations/NameTheCSV";

function BatteriesInCart(props) {
  const dispatch = useDispatch();

  const [to, setTo] = useState([]);
  const [actual_to, setActualTo] = useState([]);
  const [cc, setCC] = useState([]);
  const [bcc, setBCC] = useState([]);
  const [helperText, setHelperText] = useState(false);
  const [mounted, setMounted] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const rowsPerPage = useSelector((state) => state.StackTableRowsPerPage.value);
  const orderChangedTo = useSelector((state) => state.StackTableOrder.value);
  const sortChangedTo = useSelector((state) => state.StackTableSort.value);

  const [batteries, setBatteries] = useState({
    batteries: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [selectedBatteries, setSelectedBatteries] = useState([]);

  const [loader, setLoader] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [error, setError] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(null)

  const GetEmailList = async () => {
    await getEmailIDList().then((res) => {
      if (res.responseStatus?.code === 200) {
        setActualTo(res.response.data);
      }
    });
  };

  const SetBatteriesInCart = () => {
    setMounted(false);
    getCartBatteries(
      pageNumber,
      sortChangedTo,
      orderChangedTo,
      rowsPerPage
    ).then((res) => {
      if (res.responseStatus.code === 200) {
        setBatteries({
          batteries: res.response.data,
          total: res.response.total.total,
          responseStatus: {
            code: res?.responseStatus?.code,
            msg: res?.responseStatus?.msg,
          },
        });
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
        setBatteries({
          batteries: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
      setMounted(true);
    }).catch((error) => {
      setBatteries({
        batteries: [],
        responseStatus: { code: -1, msg: "" },
      });
    });
  };

  const popFromCart = () => {
    postPopFromCart(selectedBatteries).then((res) => {
      if (res.responseStatus.code === 200) {
        props.SetCountForCart();
        SetBatteriesInCart();
      } else {
        if (res.responseStatus.code === -2)
          dispatch(SetSessionExpired(true));
      }
    });
  }

  const handleClickDownLoadCSV = () => {
    setActionInProgress("downloadCSV")
    setLoader(true);
    let newBatteries = [];

    getCSVOfBatteries(selectedBatteries).then((res) => {
      if (res.responseStatus.code === 200) {
        Events("Download CSV from Selected Batteries For Action");
        res.response.data.forEach((row, index) => {
          newBatteries.push({
            "Ping Status": row?.pingStatus,
            "Device ID": row?.batteryID,
            "Alert Description": row?.alertDescription,
            "Alert State": row?.alertStatus,
            "Severity-Latest": row?.severityName,
            "Alert First Seen": row?.startTime,
            "Device ID": row?.batteryID,
            "Packs Affected": row?.cells?.length,
            "Modules Affected": row?.modules?.length,
            "Cells Affected": row?.packs.length,
            "Action Taken By": row?.openedBy,
            "Action Taken At": row?.openedAt,
            "Resolved At": row?.statusUpdateTime,
            "Resolution Time": row?.activeDuration,
            "Expired At": row?.statusUpdateTime,
            "SOC-Latest (%)": row?.soc,
            "Volt-Latest (V)": row?.voltage,
            "Temperature-Latest (C)": row?.temperature,
            "Last Pinged": row?.lastPingedTime,
            "Location": row?.lastPingedTime,
            "Organization": row?.applicationOEM,
            "IOT": row?.iotOEM,
            "ProductID": row?.cellModelNumber,
          });
        });
        DownloadCSV(
          NameTheCSV("Selected_Batteries_For_Action", "Download CSV"),
          newBatteries
        );
        setConfirmation(true);
        popFromCart()
        props.getALertType()
        props.getALertTabs()
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      }
      setLoader(false);
    });
  };

  const handleClickSendEmail = () => {
    setActionInProgress("sendMail")
    if (to?.length !== 0) {
      setLoader(true);
      sendPostTakeAction(to, cc, bcc, selectedBatteries).then((res) => {
        if (res.responseStatus.code === 200) {
          setConfirmation(true);
          setLoader(false);
          popFromCart()
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setLoader(false);
          setError(true);
        }
        props.getALertType()
        props.getALertTabs()
      });
      Events("Mail sent from Selected Batteries For Action");
    } else {
      setHelperText(true);
    }
  };

  const handleClickRemove = () => {
    setMounted(false);
    postPopFromCart(selectedBatteries).then((res) => {
      if (res.responseStatus.code === 200) {
        props.SetCountForCart();
        SetBatteriesInCart();
        setPageNumber(1);
        Events("Removed from Selected Batteries For Action");
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      }
    });
    setSelectedBatteries([]);
    dispatch(SetIsDeleted("yes"));
  };

  const handleClickEmpty = () => {
    setMounted(false);
    let itemIds = [];
    getSelectAllBatteries()
      .then((res) => {
        if (res.responseStatus.code === 200) {
          itemIds = res.response.data;
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          // setCartBatteries(0);
          itemIds = [];
        }
        Events("Emptied Selected Batteries For Action");
        dispatch(DashboardExistingBatteries([]));
        props.setCartBatteriesCount(0);
        return itemIds;
      })
      .then((tempIDs) =>
        postPopFromCart(tempIDs).then((res) => {
          if (res.responseStatus.code === 200) {
            // props.SetCountForCart("empty");
            SetBatteriesInCart();
            Events("Emptied the cart");
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));
          }
        })
      );

    setSelectedBatteries([]);
    dispatch(SetIsDeleted("yes"));
  };

  useEffect(() => {
    if (props.batteries.batteries?.length > 0) {
      setBatteries(props.batteries);
    } else {
      setBatteries({
        batteries: [],
        responseStatus: props?.batteries?.responseStatus || { code: -1, msg: "" },
      });
    }
    GetEmailList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batteries.batteries]);

  useEffect(() => {
    SetBatteriesInCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, orderChangedTo, sortChangedTo, rowsPerPage]);

  useEffect(() => {
    setSelectedBatteries(
      batteries?.batteries?.map((item) => {
        const alertMetaID = props.alertTypeList?.find(alertType => alertType.alertType === item.alertType)?.id;
        
        return {
          batteryID: item.batteryID,
          configBOPID: item.configBOPID,
          alertMetaID: alertMetaID,
          alertStatus: item.alertStatus,
          alertStateIds: item.alertStateIds
        }
      })
    )
  }, [batteries]);

  useEffect(() => {
    const timer = setTimeout(() => {
      confirmation && props.handleCloseBatteryStackDialog();
    }, 5000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation]);

  function handleSelectedBatteries(batteries) {
    setSelectedBatteries(batteries);
  }

  return (
    <div>
      {!confirmation && !error ? (
        <Dialog
          onClose={props.handleCloseBatteryStackDialog}
          aria-labelledby="customized-dialog-title"
          open={props.openBatteryStackDialog}
          fullWidth
          maxWidth="none"
          PaperProps={{
            sx: {
              width: "90%",
              maxHeight: "92%",
            },
          }}
        >
          <DialogContent>
            <Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="paperHeader">
                  Selected Batteries For Action
                </Typography>
                <IconButton
                  onClick={props.handleCloseBatteryStackDialog}
                  fontSize="small"
                >
                  <CloseIcon style={{ fontSize: "18px" }} />
                </IconButton>
              </div>

              {!loader && !confirmation && !error ? (
                <>
                  <ToField
                    actual_to={actual_to}
                    to={to}
                    cc={cc}
                    bcc={[]}
                    helperText={helperText}
                    setTo={setTo}
                    setCC={setCC}
                    setBCC={setBCC}
                    setHelperText={setHelperText}
                  />
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <CartTable
                      mounted={mounted}
                      addToCartInProgress={props.addToCartInProgress}
                      data={batteries}
                      columns={columns}
                      selection={true}
                      setPageNumber={setPageNumber}
                      selectedBatteries={selectedBatteries}
                      handleSelectedBatteries={handleSelectedBatteries}
                      SetCountForCart={props.SetCountForCart}
                      existingBatteries={[]}
                      alertTypeList={props.alertTypeList}
                    />
                  </Box>

                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <OutlinedButton
                          variant="outlined"
                          color="primary"
                          onClick={() => handleClickRemove()}
                          disabled={selectedBatteries?.length === 0}
                        >
                          <Typography
                            variant={
                              selectedBatteries?.length === 0
                                ? "textSubtitleGrey"
                                : "textSubtitlePurple"
                            }
                          >
                            Remove
                          </Typography>
                        </OutlinedButton>
                        <OutlinedButton
                          variant="outlined"
                          color="primary"
                          sx={{ ml: 2 }}
                          onClick={() => handleClickEmpty()}
                          disabled={
                            selectedBatteries?.length === 0 &&
                            batteries.batteries?.length === 0
                          }
                        >
                          <Typography
                            variant={
                              selectedBatteries?.length === 0 &&
                              batteries.batteries?.length === 0
                                ? "textSubtitleGrey"
                                : "textSubtitlePurple"
                            }
                          >
                            Clear
                          </Typography>
                        </OutlinedButton>
                      </Box>

                      <Box>
                        <OutlinedButton
                          variant="contained"
                          disabled={selectedBatteries?.length === 0}
                          onClick={() => handleClickDownLoadCSV()}
                        >
                          <Typography variant="textSubtitleWhite">
                            Download CSV
                          </Typography>
                        </OutlinedButton>
                        <OutlinedButton
                          variant="contained"
                          sx={{ ml: 2 }}
                          onClick={() => handleClickSendEmail()}
                          disabled={selectedBatteries?.length === 0}
                        >
                          <Typography variant="textSubtitleWhite">
                            Send Email
                          </Typography>
                        </OutlinedButton>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {!confirmation && !error && (
                    <div style={{ height: "636.75px" }}>
                      <CartLoader 
                        text={
                          actionInProgress === "sendMail"
                            ? "Sending Mail"
                            : actionInProgress === "downloadCSV"
                            ? "Preparing CSV"
                            : null
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          onClose={props.handleCloseBatteryStackDialog}
          aria-labelledby="customized-dialog-title"
          open={props.openBatteryStackDialog}
        >
          <DialogContent>
            <Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="paperHeader">
                  {/* Selected Batteries For Action */}
                </Typography>
                <IconButton
                  onClick={props.handleCloseBatteryStackDialog}
                  fontSize="small"
                >
                  <CloseIcon style={{ fontSize: "18px" }} />
                </IconButton>
              </div>
            </Box>
            {confirmation && (
              <div style={{ height: "227px", width: "500px" }}>
                <Confirm text={"Alerts are moved to Open Alerts"} />
              </div>
            )}
            {error && (
              <div style={{ height: "227px", width: "500px" }}>
                <Error />
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
export default BatteriesInCart;
