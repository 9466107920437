const initialFilterValues = {
  value: {},
};

const initialSortColumn = {
  value: null
}

const initialSortingOrder = {
  value: 'desc'
}

const SelectedFilterValues = (state = initialFilterValues, action) => {
  switch (action.type) {
    case "selectedFilters":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const TableSortColumn = (state = initialSortColumn, action) => {
  switch (action.type) {
    case "tableSortColumn":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const TableSortOrder = (state = initialSortingOrder, action) => {
  switch (action.type) {
    case "tableSortOrder":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

export { 
  SelectedFilterValues,
  TableSortColumn,
  TableSortOrder
}