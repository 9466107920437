import qs from "qs";
import { GetData } from "./Functions/SettingData";
import {
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
} from "../Helper/DatePicker/DateConverter";
import {
  newCity,
  newIOT,
  newPack,
  newSeverity,
  newStatus,
  newZone,
} from "../Static/Data/Dashboard/DashboardFilters";
import { areArraysEqual } from "../Helper/Operations/ArrayOps";

async function getAlertTypeList(
  tabvalue,
  organization,
  category,
  location,
  batterystatus,
  severity,
  iotVendor,
  product,
  batteryID,
  startDate,
  endDate,
  batteryType,
  queryParams
) {
  let alertState =
    tabvalue === 0
      ? "Active"
      : tabvalue === 1
        ? "Open"
        : tabvalue === 2
          ? "Resolved"
          : tabvalue === 3
            ? "Expired"
            : "Ignored";

  let newDeviceID, newBatteryID;
  if (queryParams && queryParams["type"] === "Device") {
    newDeviceID = queryParams["deviceID"];
    newBatteryID = "";
  } else if (queryParams && queryParams["type"] === "Battery") {
    newDeviceID = "";
    newBatteryID = batteryID;
  } else {
    newDeviceID = "";
    newBatteryID = batteryID;
  }
  var rawJson = {
    fleetName: organization ?? [],
    location: location ?? [],
    alertCode: "",
    product: product,
    batteryStatus: batterystatus ?? [],
    severity: severity ?? [],
    iotVendor: iotVendor ?? [],
    alertStatus: alertState,
    alertType: category,
    startDate: startDate ? convertZonetoUTCStart(startDate) : null,
    endDate: endDate ? convertZonetoUTCEnd(endDate) : null,
    batteryID: newBatteryID,
    batteryType: batteryType,
  };
  var config = {
    method: "get",
    // url: `/api/alert_group_count/v2`,
    url: `/api/alert/alert_code_list_count`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  // let data = alertTypeResponse;

  let data = GetData(config);
  return data;
}

async function getTableBatteries(
  id,
  tabvalue,
  alertCode,
  organization,
  category,
  location,
  batterystatus,
  severity,
  iotVendor,
  product,
  priority,
  batteryID,
  partner,
  startDate,
  endDate,
  currentPage,
  sortBy,
  orderBy,
  rowsPerPage,
  batteryType,
  queryParams,
  filters
) {
  let alertState =
    tabvalue === 0
      ? "Active"
      : tabvalue === 1
        ? "Open"
        : tabvalue === 2
          ? "Resolved"
          : tabvalue === 3
            ? "Expired"
            : "Ignored";

  let newDeviceID, newBatteryID;
  if (queryParams && queryParams["type"] === "Device") {
    newDeviceID = queryParams["deviceID"];
    newBatteryID = "";
  } else if (queryParams && queryParams["type"] === "Battery") {
    newDeviceID = "";
    newBatteryID = batteryID;
  } else {
    newDeviceID = "";
    newBatteryID = batteryID;
  }

  var rawJson = {
    id: id,
    location: areArraysEqual(location, filters?.location) ? [] : location,
    alertCode: alertCode,
    fleetName: areArraysEqual(organization, filters?.fleeName)
      ? []
      : organization,
    batteryStatus: areArraysEqual(batterystatus, filters?.batteryStatus)
      ? []
      : batterystatus,
    severity: areArraysEqual(severity, filters?.severity) ? [] : severity,
    iotVendor: areArraysEqual(iotVendor, filters?.iotOEM) ? [] : iotVendor,
    priority: priority,
    alertStatus: alertState,
    alertType: category,
    startDate: startDate === "" ? startDate : convertZonetoUTCStart(startDate),
    endDate: endDate === "" ? endDate : convertZonetoUTCEnd(endDate),
    assetID: newDeviceID,
    batteryID: newBatteryID,
    partner: partner,
    batteryType: batteryType,
    pageCount: rowsPerPage,
    sortBy: sortBy,
    order: orderBy,
  };
  var config = {
    method: "get",
    url: `/api/alert/alerts_item_list/${currentPage}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  // let data = alertListReponse;
  let data = GetData(config);
  return data;
}

async function getDashboardKPI(batteryType) {
  var config = {
    method: "get",
    url: "/api/battery_count",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      batteryType: batteryType,
    },
  };
  let data = GetData(config);
  return data;
}

async function getBatteryPartnerList(columnName, search, limit, tabvalue, startDate, endDate) {
  let newColumnName = columnName === "Battery ID" ? "batteryID" : "partner";
  let alertState =
    tabvalue === 0
      ? "Active"
      : tabvalue === 1
        ? "Open"
        : tabvalue === 2
          ? "Resolved"
          : tabvalue === 3
            ? "Expired"
            : "Ignored";

  var rawJson = {
    columnName: newColumnName,
    columnSearch: search,
    columnLimit: limit,
    alertStatus: alertState,
    startDate: startDate ? convertZonetoUTCStart(startDate) : null,
    endDate: endDate ? convertZonetoUTCEnd(endDate) : null,
  };
  var config = {
    method: "get",
    url: `/api/alert/list_search`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getSelectAllBatteries(
  id,
  city,
  zone,
  volt,
  category,
  alertCode,
  batterystatus,
  severity,
  iotVendor,
  batteryVendor,
  tabvalue,
  priority,
  batteryState,
  date,
  batteryID,
  partner,
  batteryType
) {
  let alertState =
    tabvalue === 0
      ? "Active"
      : tabvalue === 1
        ? "Open"
        : tabvalue === 2
          ? "Resolved"
          : tabvalue === 3
            ? "Expired"
            : "Ignored";

  var rawJson = {
    id: id,
    location: areArraysEqual(city, newCity) ? [] : city,
    zone: areArraysEqual(zone, newZone) ? [] : zone,
    voltConf: volt,
    alertCode: alertCode,
    batteryStatus: areArraysEqual(batterystatus, newStatus)
      ? []
      : batterystatus,
    severity: areArraysEqual(severity, newSeverity) ? [] : severity,
    iotVendor: areArraysEqual(iotVendor, newIOT) ? [] : iotVendor,
    batteryVendor: areArraysEqual(batteryVendor, newPack) ? [] : batteryVendor,
    priority: priority,
    alertStatus: alertState,
    batteryState: "",
    alertType: category,
    startDate: date[0] === "" ? date[0] : convertZonetoUTCStart(date[0]),
    endDate: date[1] === "" ? date[1] : convertZonetoUTCEnd(date[1]),
    assetID: batteryID,
    batteryID: "",
    partner: partner,
    batteryType: batteryType,
    batteryFlag: [0],
  };
  var config = {
    method: "get",
    url: `/api/alert/item_ids`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getTabCounts(startDate, endDate) {
  var rawJson = {
    startDate: startDate ? convertZonetoUTCStart(startDate) : null,
    endDate: endDate ? convertZonetoUTCEnd(endDate) : null,
  }
  var config = {
    method: "get",
    url: `/api/alert/state_count`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getDashboardFilters(filtersArray) {
  const rawJson = {
    filters: filtersArray || [
      "fleetName",
      "severityName",
      "batteryStatus",
      "iotOEM",
      "pack",
      "cellModelNumber",
      "location",
      "alertType"
    ],
  };

  var config = {
    method: "get",
    url: `/api/alert/filter_list`,
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config, true);
  return data;
}

export {
  getTableBatteries,
  getAlertTypeList,
  getDashboardKPI,
  getBatteryPartnerList,
  getSelectAllBatteries,
  getTabCounts,
  getDashboardFilters,
};
