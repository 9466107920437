import * as React from "react";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { Typography } from "@mui/material";
import {
  StyledTabContainer,
  StyledIndividualTab,
} from "../../Components/CustomTab";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";

export default function TabWithColorDashboard(props) {
  const value = useSelector((state) => state.DashboardTabValue.value);

  const handleChange = (event, newValue) => {
    props.ChangeTheTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <StyledTabContainer
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
        elevation={1}
        myColor={ThemeProperties.purple}
      >
        {props.tabs.map((item, index) => (
          <StyledIndividualTab
            key={index}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip placement={item.placement} title={item.description}>
                  <Typography
                    variant="specificTabBold"
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    <Box>{item.name}</Box>
                    <Box
                      borderRadius={1}
                      border={1}
                      sx={{
                        borderColor: "lightgray", // Light border color
                        borderWidth: 1, // Border width
                        padding: "2px 4px", // Adjust padding as needed
                      }}
                      fontSize={12}
                    >
                      {item.count}
                    </Box>
                  </Typography>
                </Tooltip>
              </div>
            }
            myColor={ThemeProperties.purple}
          />
        ))}
      </StyledTabContainer>
    </Box>
  );
}
