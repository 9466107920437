import React, { useEffect, useState, useCallback } from "react";
import debounce from 'lodash.debounce';
import { Box, Checkbox, Icon, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import LShapedLine from "../../Assets/Icons/NestedMultiSelect/LShapedLine.svg";
import LShapedLineWithContinuation from "../../Assets/Icons/NestedMultiSelect/LShapedLineWithContinuation.svg";
import StraightLine from "../../Assets/Icons/NestedMultiSelect/StraightLine.svg";
import DownArrow from "../../Assets/Icons/NestedMultiSelect/DownArrow.svg";
import RightArrow from "../../Assets/Icons/NestedMultiSelect/RightArrow.svg";
import {
  checkBoxIcon,
  checkedIcon,
} from "../../Assets/Icons/DigitalTwin/CheckBoxIcon";
import { Tooltip } from "antd";
import { getTruncatedEntityName, sortBySequence } from "../../Helper/DigitalTwin/DigitalTwinHelper";

const CustomCheckBox = (props) => {
  const {
    hasChildren,
    structureType,
    label,
    level,
    isLastElementOfLevel,
    isFirstElementOfLevel,
    packNumber,
    moduleNumber,
    cellNumber,
    packName,
    moduleName,
    cellName,
    packCount,
    moduleCountInPack,
    cellCountInModule,
    isBatterSystemExpanded,
    expandedPacks,
    expandedModules,
    isShown,
    onExpand,
    isExpanded,
    onChange,
    checked,
    disabled,
    alertCount,
    soc,
    showLowSOCMessage,
    ...other
  } = props;
  const lowSoc =  level === "pack" ? (Number.isFinite(soc) ? soc < 30 : true) : false 
  let isRootElement = level === 'battery'

  if (packCount === 1 && level === "pack") {
    isRootElement = true
  }

  const handleExpandClick = () => {
    onExpand(!isExpanded);
  };
  const [isHidden, setIsHidden] = useState(false)
  const [indentationLength, setIndentationLength] = useState(["module", "cell"].includes(level)
  ? "17px"
  : "-2px")

  const checkboxColor = disabled ? "#828A92" : "#586CE9"

  useEffect(() => {
    let hideElement = false
    switch (structureType) {
      case 'pc':
        hideElement = level === 'module'
        if (level === 'cell') {
          setIndentationLength(packCount === 1 ? "3px" : "10px")
        }
        break
    }

    if (hideElement && level === 'module') {
      expandedPacks.forEach(name => {
        if (packName === name){
          onExpand(true)
        }
      })
      setIsHidden(true)
    }
  }, [expandedPacks])
  
  const renderLabel = () => {
    return (
      <Box
        style={{
          display: "flex",
          marginLeft: "4px",
          padding: "4px 2px",
          marginBottom: "2px",
        }} 
      >
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: "400",
            color: disabled ? "#828A92" : showLowSOCMessage && lowSoc ? "#EE534F" : "#475467",
          }}
        >
          {getTruncatedEntityName(label, 14, 5, 5)}
        </Typography>
        <Tooltip
          placement="right"
          title={`Low SOC: ${Number.isFinite(soc) ? soc + "%" : "NA"}`}
          zIndex={2000}
        >
          {showLowSOCMessage && lowSoc && <Typography
            style={{
              marginLeft: "4px",
              fontFamily: "Roboto",
              fontSize: "10px",
              lineHeight: "16px",
              fontWeight: "300",
              color: lowSoc ? "#EE534F" : "#475467",
            }}
          >
            {"SOC < 30%"}
          </Typography>}
        </Tooltip>
      </Box>
    )
  }

  return (
    <Box
      style={{
        ...props.style,
        width: "100%",
        display: isHidden ? "none" : "block"
      }}
    >
      <Box
        style={{
          display: isShown ? "flex" : "none",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            style={{
              display: ["module", "cell"].includes(level) ? "block" : "none",
              marginTop: "-4px",
              marginRight: indentationLength,
            }}
            src={packNumber !== packCount && StraightLine}
          />
          <Box
            component="img"
            style={{
              display: level === "cell" ? "black" : "none",
              marginTop: "-4px",
              marginRight: indentationLength,
            }}
            src={moduleNumber !== moduleCountInPack && StraightLine}
          />
          <Box
            component="img"
            style={{
              display:
                !isRootElement && !isLastElementOfLevel ? "block" : "none",
              marginTop: "-4px",
            }}
            src={LShapedLineWithContinuation}
          />
          <Box
            component="img"
            style={{
              display: !isRootElement && isLastElementOfLevel ? "block" : "none",
              marginTop: "-4px",
              marginLeft: "-7px",
            }}
            src={LShapedLine}
          />
          <Box
            style={{
              display: hasChildren ? "block" : "none",
            }}
          >
            <Box
              component="img"
              onClick={() => {
                handleExpandClick();
              }}
              style={{
                display: isExpanded ? "block" : "none",
                cursor: "pointer",
                marginLeft: isExpanded ? "2px" : "6px",
                marginBottom: "2px",
              }}
              src={DownArrow}
            />
            <Box
              component="img"
              onClick={() => {
                handleExpandClick();
              }}
              style={{
                display: isExpanded ? "none" : "block",
                cursor: "pointer",
                marginLeft: isExpanded ? "2px" : "6px",
                marginBottom: "2px",
              }}
              src={RightArrow}
            />
          </Box>
          <FormControlLabel
            style={{
              marginLeft: "5px",
            }}
            control={
              <Checkbox
                style={{
                  width: "16px",
                  height: "16px",
                  padding: 0,
                  marginLeft: isExpanded ? "5px" : "6px",
                  marginBottom: "2px",
                }}
                icon={checkBoxIcon(checkboxColor, "13px", "13px")}
                checkedIcon={checkedIcon(checkboxColor, "13px", "13px")}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
              />
            }
            label={
              <Box>
                {renderLabel()}
              </Box>
            }
          ></FormControlLabel>
        </Box>
        {false && alertCount > 0 && (
          <Typography
            sx={{
              ml: "8px",
              mr: level === "battery" ? "7px" : "14px",
              pl: "6px",
              pr: "6px",
              textAlign: "left",
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: "10px",
              lineHeight: "16px",
              borderRadius: "4px",
              color: "#EE534F",
              backgroundColor: "#FEEEEE",
              textAlign: "center",
            }}
          >
            {alertCount || 0}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const BatteryHierarchySelect = (props) => {
  const {
    onChange,
    hierarchy,
    alertsCountHierarchical,
    hierarchyLiveMetricData,
    selectedHierarchy,
    defaultHierarchy,
    hierarchySequence,
    defaultHierarchyLevel,
    selectedHierarchyLevel,
    ...other
  } = props;
  const [structureType, setStructureType] = useState(null)
  const packCount = hierarchy?.batteryConfig?.packCount;
  const maxLimitToCheck = 16;
  const disabledLevelsData = [
    {
      'level': 'pack',
      'disable': hierarchy?.batteryConfig?.disablePacks || false
    },
    {
      'level': 'module',
      'disable': hierarchy?.batteryConfig?.disableModules || false
    },
    {
      'level': 'cell',
      'disable': hierarchy?.batteryConfig?.disableCells || false
    },
  ]
  const disabledLevels = disabledLevelsData.filter(level => level.disable).map(level => level.level)
  const moduleCountInPack = hierarchy?.batteryConfig?.moduleCountPerPack;
  const cellCountInModule = hierarchy?.batteryConfig?.cellCountPerModule;
  const [isBatterSystemExpanded, setIsBatterSystemExpanded] = useState(true);
  const [expandedPacks, setExpandedPacks] = useState([]);
  const [expandedModules, setExpandedModules] = useState([]);
  const [checkedItems, setCheckedItems] = useState(selectedHierarchy || defaultHierarchy);
  const [maxItemsChecked, setMaxItemsChecked] = useState(
    selectedHierarchy.length === maxLimitToCheck
  );
  const [selectedLevel, setSelectedLevel] = useState(
    selectedHierarchyLevel || defaultHierarchyLevel
  );

  const screenHeight = window.innerHeight;

  const debouncedOnChange = useCallback(debounce((checkedItems, selectedLevel) => {
    onChange(checkedItems, selectedLevel);
  }, 800), []);

  useEffect(() => {
    debouncedOnChange(checkedItems, selectedLevel);
    if (checkedItems.length === 0) {
      setSelectedLevel(defaultHierarchyLevel);
      setCheckedItems(defaultHierarchy);
    }
    setMaxItemsChecked(checkedItems.length === maxLimitToCheck);
  }, [checkedItems]);

  useEffect(() => {
    setSelectedLevel(selectedHierarchyLevel);
    if (selectedHierarchy.length > 0) {
      setCheckedItems(selectedHierarchy);
    }
  }, [selectedHierarchyLevel]);

  useEffect(() => {
    setStructureType(hierarchy?.batteryConfig?.buildingBlockType)
  }, [hierarchy]);

  useEffect(() => {
    if (!selectedHierarchy || selectedHierarchy?.length === 0) {
      return;
    }

    let packs = []
    let modules = []
    let uniquePacks, uniqueModules
    switch (selectedHierarchyLevel) {
      case "module":
        selectedHierarchy?.map(entity => {
          const packID = findParentPack(hierarchy.batteryStructure, entity)
          packs.push(packID)
        })
        uniquePacks = [...new Set(packs)]
        setExpandedPacks(uniquePacks);
        break;
      case "cell":
        selectedHierarchy?.map(entity => {
          const result = findParentModuleAndPack(hierarchy.batteryStructure, entity)
          if (!result) {
            return
          }
          packs.push(result.pack)
          modules.push(result.module)
        })
        uniquePacks = [...new Set(packs)]
        uniqueModules = [...new Set(modules)]
        setExpandedPacks(uniquePacks);
        setExpandedModules(uniqueModules);
        break;
    }
  }, []);

  const handleClickOnPack = (event, packName) => {
    if (event.target.checked) {
      if (maxItemsChecked && selectedHierarchyLevel === "pack") {
        return;
      }

      let selectedEntities = checkedItems
      .filter((item) => {
        const allPackNames = hierarchy?.batteryStructure?.map(
          (obj) => obj.name?.toLowerCase()
        );
        return allPackNames.includes(item);
      })
      .concat([packName.toLowerCase()])

      selectedEntities = sortBySequence(selectedEntities, hierarchySequence, 'pack')
      setCheckedItems(selectedEntities);
      setSelectedLevel("pack");
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== packName));
    }
  }

  const handleClickOnModule = (event, packNumber, moduleNumber) => {
    if (event.target.checked) {
      if (maxItemsChecked && selectedHierarchyLevel === "module") {
        return;
      }
      let selectedEntities = checkedItems
        .filter((item) => {
          let allModulesName = []
          hierarchy?.batteryStructure?.map(pack => {
            const allModulesNameForPack = pack.content?.map((pack) => pack.name?.toLowerCase())
            allModulesName = [...allModulesName, ...allModulesNameForPack]
          });

          return allModulesName.includes(item);
        })
        .concat([
          hierarchy?.batteryStructure?.[packNumber - 1]?.content?.[
            moduleNumber - 1
          ]?.name?.toLowerCase(),
        ])

      selectedEntities = sortBySequence(selectedEntities, hierarchySequence, 'module')
      setCheckedItems(selectedEntities);
      setSelectedLevel("module");
    } else {
      setCheckedItems(
        checkedItems.filter(
          (item) =>
            item !==
            hierarchy?.batteryStructure?.[packNumber - 1]?.content?.[
              moduleNumber - 1
            ]?.name?.toLowerCase()
        )
      );
    }
  }

  const handleClickOnCell = (event, packNumber, moduleNumber, packName, moduleName, cellName) => {
    if (event.target.checked) {
      if (maxItemsChecked && selectedHierarchyLevel === "cell") {
        return;
      }

      let selectedEntities = checkedItems
        .filter((item) => {
          let allCellsName = []
          hierarchy?.batteryStructure?.map(pack => {
            pack.content?.map(module => {
              const allCellNamesForModule = module?.content?.map((cell) => cell.name?.toLowerCase())
              allCellsName = [...allCellsName, ...allCellNamesForModule]
            })
          });
          return allCellsName.includes(item);
        })
        .concat([cellName.toLowerCase()])

      selectedEntities = sortBySequence(selectedEntities, hierarchySequence, 'cell')
      setCheckedItems(selectedEntities);
      setSelectedLevel("cell");
    } else {
      setCheckedItems(
        checkedItems.filter((item) => item !== cellName)
      );
    }
  }

  function findParentPack(jsonArray, targetModule) {
    for (const packObj of jsonArray) {
      const packName = packObj.name;
      const packData = packObj.content;
  
      for (const module of packData) {
        if (module.name.toLowerCase() === targetModule.toLowerCase()) {
          return packName.toLowerCase();
        }
  
        for (const cell of module.content) {
          if (cell.name.toLowerCase() === targetModule.toLowerCase()) {
            return packName.toLowerCase();
          }
        }
      }
    }
    return null;
  }
  
  function findParentModuleAndPack(jsonArray, targetCell) {
    for (const packObj of jsonArray) {
      const packName = packObj.name;
      const packData = packObj.content;
  
      for (const module of packData) {
        const moduleName = module.name;
  
        for (const cell of module.content) {
          if (cell.name.toLowerCase() === targetCell.toLowerCase()) {
            return { pack: packName.toLowerCase(), module: moduleName.toLowerCase() };
          }
        }
      }
    }
    return null;
  }

  const renderCheckboxes = () => {
    const checkboxHierarchy = [];
    const showLowSOCMessage = hierarchyLiveMetricData !== null
    if (packCount > 1) {
      checkboxHierarchy.push(
        <CustomCheckBox
          key={"batterySystemCheckbox"}
          name={"batterySystemCheckbox"}
          structureType={structureType}
          hasChildren={true}
          label={"Battery System"}
          level={"battery"}
          isFirstElementOfLevel={true}
          isLastElementOfLevel={false}
          packCount={packCount}
          moduleCountInPack={moduleCountInPack}
          cellCountInModule={cellCountInModule}
          isBatterSystemExpanded={isBatterSystemExpanded}
          expandedPacks={expandedPacks}
          expandedModules={expandedModules}
          showLowSOCMessage={showLowSOCMessage}
          isShown={true}
          isExpanded={isBatterSystemExpanded}
          onExpand={(value) => {
            setIsBatterSystemExpanded(value);
            setExpandedPacks([]);
            setExpandedModules([]);
          }}
          checked={checkedItems.includes("battery")}
          disabled={false}
          onChange={(event) => {
            if (event.target.checked) {
              setCheckedItems(["battery"]);
              setSelectedLevel("battery");
            } else {
              setCheckedItems(defaultHierarchy);
              setSelectedLevel(defaultHierarchyLevel);
            }
          }}
          alertCount={alertsCountHierarchical?.data?.alertsCount}
        />
      );
    }
    
    hierarchy?.batteryStructure?.forEach(packData => {
      const packNumber = packData?.sequence || ""
      const packName = packData?.name?.toLowerCase();
      const label = `Pack ${packNumber}`
      checkboxHierarchy.push(
        <CustomCheckBox
          key={packName}
          packName={packName}
          structureType={structureType}
          style={{
            marginTop: "4px",
            marginLeft: "7px",
            fontSize: "14px",
          }}
          hasChildren={true}
          isFirstElementOfLevel={packNumber === 1}
          isLastElementOfLevel={packNumber === packCount}
          label={label}
          level={"pack"}
          packCount={packCount}
          soc={hierarchyLiveMetricData?.[packName]?.soc}
          showLowSOCMessage={showLowSOCMessage}
          moduleCountInPack={moduleCountInPack}
          cellCountInModule={cellCountInModule}
          isBatterSystemExpanded={isBatterSystemExpanded}
          expandedPacks={expandedPacks}
          expandedModules={expandedModules}
          isExpanded={expandedPacks.includes(packName)}
          isShown={isBatterSystemExpanded}
          onExpand={(value) => {
            setExpandedPacks(
              value
                ? expandedPacks.concat([packName])
                : expandedPacks.filter((item) => item !== packName)
            );

            setExpandedModules(
              expandedModules.filter((item) => {
                const allModulesNameForPack = hierarchy?.batteryStructure?.[
                  packNumber - 1
                ]?.content?.map((obj) => obj.name?.toLowerCase());
                return !allModulesNameForPack.includes(item);
              })
            );
          }}
          checked={checkedItems.includes(packName)}
          disabled={disabledLevels.includes("pack") || packName === undefined}
          onChange={(event) => {handleClickOnPack(event, packName)}}
          alertCount={
            alertsCountHierarchical?.data?.packs?.find(
              (pack) => pack.packID?.toLowerCase() === packName?.toLowerCase()
            )?.alertsCount
          }
        />
      );
      packData.content?.forEach(moduleData => {
        const moduleNumber = moduleData?.sequence
        const moduleName = moduleData?.name?.toLowerCase();
        const moduleLabel = `Module ${moduleNumber}`
        checkboxHierarchy.push(
          <CustomCheckBox
            key={moduleName}
            moduleName={moduleName}
            structureType={structureType}
            style={{
              marginLeft: "7px",
            }}
            hasChildren={true}
            isFirstElementOfLevel={moduleNumber === 1}
            isLastElementOfLevel={moduleNumber === moduleCountInPack}
            label={moduleLabel}
            level={"module"}
            packNumber={packNumber}
            packName={packName}
            packCount={packCount}
            moduleCountInPack={moduleCountInPack}
            cellCountInModule={cellCountInModule}
            soc={hierarchyLiveMetricData?.[moduleName]?.soc}
            showLowSOCMessage={showLowSOCMessage}
            isBatterSystemExpanded={isBatterSystemExpanded}
            expandedPacks={expandedPacks}
            expandedModules={expandedModules}
            isExpanded={expandedModules.includes(moduleName)}
            isShown={expandedPacks.includes(packName)}
            onExpand={(value) => {
              setExpandedModules(
                value
                  ? expandedModules.concat([moduleName])
                  : expandedModules.filter((item) => item !== moduleName)
              );
            }}
            checked={checkedItems.includes(moduleName)}
            disabled={disabledLevels.includes("module") || moduleName === undefined}
            onChange={(event) => {handleClickOnModule(event, packNumber, moduleNumber)}}
            alertCount={
              alertsCountHierarchical?.data?.packs
                ?.find(
                  (pack) =>
                    pack.packID?.toLowerCase() === packName?.toLowerCase()
                )
                ?.modules?.find(
                  (module) =>
                    module.moduleID.toLowerCase() === moduleName.toLowerCase()
                )?.alertsCount
            }
          />
        );
        moduleData.content?.forEach(cellData => {
          const cellNumber = cellData?.sequence
          const cellName = cellData?.name?.toLowerCase();
          const cellLabel = `Cell ${cellNumber}`
          checkboxHierarchy.push(
            <CustomCheckBox
              key={cellName}
              cellName={cellName}
              structureType={structureType}
              style={{
                marginLeft: "7px",
              }}
              hasChildren={false}
              isFirstElementOfLevel={cellNumber === 1}
              isLastElementOfLevel={cellNumber === cellCountInModule}
              label={cellLabel}
              level={"cell"}
              packNumber={packNumber}
              packName={packName}
              moduleNumber={moduleNumber}
              moduleName={moduleName}
              packCount={packCount}
              soc={hierarchyLiveMetricData?.[cellName]?.soc}
              showLowSOCMessage={showLowSOCMessage}
              moduleCountInPack={moduleCountInPack}
              cellCountInModule={cellCountInModule}
              isBatterSystemExpanded={isBatterSystemExpanded}
              expandedPacks={expandedPacks}
              expandedModules={expandedModules}
              isShown={expandedModules.includes(moduleName)}
              checked={checkedItems.includes(cellName)}
              disabled={disabledLevels.includes("cell") || cellName === undefined}
              onChange={(event) => {handleClickOnCell(event, packNumber, moduleNumber, packName, moduleName, cellName)}}
              alertCount={
                alertsCountHierarchical?.data?.packs
                  ?.find(
                    (pack) =>
                      pack.packID?.toLowerCase() === packName?.toLowerCase()
                  )
                  ?.modules?.find(
                    (module) =>
                      module.moduleID.toLowerCase() === moduleName.toLowerCase()
                  )
                  ?.cells?.find(
                    (cell) =>
                      cell.cellID?.toLowerCase() === cellName.toLowerCase()
                  )?.alertsCount
              }
            />
          );
        })
      })
    })

    return checkboxHierarchy;
  };

  return (
    <Box style={{ ...props.style }}>
      <Box
        style={{
          marginLeft: "8px",
          height: screenHeight <= 700 ? "45vh" : "65vh",
          overflowY: "scroll",
          overflowX: "hidden",
          paddingBottom: "100px",
        }}
      >
        {renderCheckboxes()}
      </Box>
    </Box>
  );
};

export default BatteryHierarchySelect;
