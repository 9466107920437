import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material"
import GenericMultipleSelect from "./GenericMultipleSelect"
import { FilterContainer, ResetFilterBox } from "../../Components/CustomBox"
import { areArraysEqual } from "../../Helper/Operations/ArrayOps"
import { SetFilterValues } from "../../Actions/Filters"
import { decryptTheParams, encryptTheParams, updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt"

export default function GenericFilterContainer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filtersConfig = props.filtersConfig || []
  const [resetButton, setResetButton] = useState(false);
  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value);

  useEffect(() => {
    showResetButton()
  }, [selectedFilters])

  const handleFilterValueChange = (key, label, selectedValue) => {
    const newSelectedValues = {...selectedFilters}
    const queryParams = decryptTheParams();
    
    newSelectedValues[key] = selectedValue
    dispatch(SetFilterValues(newSelectedValues))
    props.onSelectFilter(key, label, selectedValue)
    
    const temp = {
      ...queryParams,
      filters: JSON.stringify(newSelectedValues)
    };
    encryptTheParams(temp, navigate);
  }

  const resetFilters = () => {
    dispatch(SetFilterValues(props.defaultFilterValues))
    setResetButton(false);
    updateQueryParams(navigate, {
      filters: ""
    })
    props.onResetFilters()
  }

  const showResetButton = () => {
    const allAvailableFilters = props.filtersConfig || []
    for (let filterConfig of allAvailableFilters) {
      if (!areArraysEqual(props.defaultFilterValues[filterConfig.key], selectedFilters[filterConfig.key])) {
        setResetButton(true);
        return
      }
    }

    setResetButton(false);
  }

  const getMultiSelectDropdownFilter = (data) => {
    return (
      <GenericMultipleSelect
        label={data?.label}
        options={props.filtersOptions[data.key]}
        maxWidth={"100px"}
        optionValue={selectedFilters[data.key] || []}
        handleSetFilter={(label, value) => {
          handleFilterValueChange(data.key, label, value)
        }}
        id="overflow-text-3"
      />
    )
  }

  const renderFilter = (filterData) => {
    switch (filterData.displayType) {
      case "multiSelectDropdown":
        return getMultiSelectDropdownFilter(filterData)
    }
  }

  return (
    <FilterContainer>
      {resetButton && (
        <ResetFilterBox
          onClick={resetFilters}
        >
          Reset
        </ResetFilterBox>
      )}
      {filtersConfig.map((filter, index) => {
        if (filter.tabs) {
          return filter.tabs.includes(props.activeTabValue) ? (
            <Box key={index}>{renderFilter(filter)}</Box>
          ) : null
        }

        return <Box key={index}>{renderFilter(filter)}</Box>
      })}
    </FilterContainer>
  )
}
