import { formatDateWithoutYear } from "../DatePicker/DateFormatters"

const getDefaultRawDataXAxisRanges = (startDate) => {
  return [
    { x: startDate + " 00:00:00", y: null },
    { x: startDate + " 01:00:00", y: null },
    { x: startDate + " 02:00:00", y: null },
    { x: startDate + " 03:00:00", y: null },
    { x: startDate + " 04:00:00", y: null },
    { x: startDate + " 05:00:00", y: null },
    { x: startDate + " 06:00:00", y: null },
    { x: startDate + " 07:00:00", y: null },
    { x: startDate + " 08:00:00", y: null },
    { x: startDate + " 09:00:00", y: null },
    { x: startDate + " 10:00:00", y: null },
    { x: startDate + " 11:00:00", y: null },
    { x: startDate + " 12:00:00", y: null },
    { x: startDate + " 13:00:00", y: null },
    { x: startDate + " 14:00:00", y: null },
    { x: startDate + " 15:00:00", y: null },
    { x: startDate + " 16:00:00", y: null },
    { x: startDate + " 17:00:00", y: null },
    { x: startDate + " 18:00:00", y: null },
    { x: startDate + " 19:00:00", y: null },
    { x: startDate + " 20:00:00", y: null },
    { x: startDate + " 21:00:00", y: null },
    { x: startDate + " 22:00:00", y: null },
    { x: startDate + " 23:00:00", y: null },
    { x: startDate + " 23:59:59", y: null }
  ]
}

const getXAxisLabelForWeeklyGranularity = (startDate, endDate, seriesLength) => {
  if (seriesLength > 10) {
    return [
      [formatDateWithoutYear(startDate) +  "-"], 
      [formatDateWithoutYear(endDate)]
    ]
  } else {
    return `${formatDateWithoutYear(
      startDate
    )} - ${formatDateWithoutYear(endDate)}`
  }
}

const calculateDynamicRange = (yMin, yMax, lowerThreshold, upperThreshold) => {
  let maxValue = yMax * 1.01
  let minValue = yMin * 0.99

  let maxValueSlice = Math.ceil(maxValue/5) * 5
  let minValueSlice = Math.floor(minValue/5) * 5

  let min = Math.min(minValueSlice, lowerThreshold) 
  let max = Math.max(maxValueSlice, upperThreshold) 

  return {min, max}
}

export {
  getDefaultRawDataXAxisRanges,
  getXAxisLabelForWeeklyGranularity,
  calculateDynamicRange
}
