import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { KeyboardBackspace } from "@mui/icons-material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getConnectedBatteries } from "../../Api/Api";
import { SetSessionExpired } from "../../Actions";
import { ConnectedBatteriesCount, ConnectedBatteriesLabel, PageHeadingText } from "../../Components/CustomBox";

function DisplayHeading(name) {
  return (
    <Box sx={{ mt: 0.5 }}>
      <PageHeadingText variant="textSubtitleBold">{name}</PageHeadingText>
    </Box>
  );
}
function DisplayNonClickableKPI(kpi, index, connectedBatteries) {
  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        width: "100%",
        cursor: "default",
      }}
    >
      <Box sx={{ borderRight: index === 2 ? "none" : "none" }}>
        <Box sx={{ pr: index === 2 ? 0 : 1 }}>
          <Box>
            <ConnectedBatteriesLabel variant="textSubtitleSmall">{kpi}</ConnectedBatteriesLabel>
          </Box>
          <Box sx={{ mt: 0.2 }}>
            <ConnectedBatteriesCount variant="textSubtitle">
              <b>{connectedBatteries}</b>
            </ConnectedBatteriesCount>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function PageHeaderKPI(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [connectedBatteries, setConnectedBatteries] = useState(0);

  useEffect(() => {
    getConnectedBatteries().then((res) => {
      if (res.responseStatus.code === 200) {
        setConnectedBatteries(res.response.data[0]["count"])
      } else if (res.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
  }, [])

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", ...props.sx }}>
      <Box sx={{display:"flex"}}>
      {props.showBackButton && <Box>
      <IconButton fontSize="small" 
      onClick={()=>navigate("/Reports")} 
      sx={{mt:-0.2,mr:1}}
      >
        <KeyboardBackspace
          fontSize="small"
          cursor="pointer"
          sx={{
            fontWeight: 200,
            color: ThemeProperties.purple,
            fontSize: "18px",
          }}
        />
      </IconButton>
      </Box>}
      {DisplayHeading(props.name)}
      </Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: ThemeProperties.white,
          borderRadius: "12px",
          border: "1px solid #ececec",
          cursor: "default",
          p: 1,
        }}
      >
        {props.nonClickableKpis.map((item, index) => (
          <Box key={index} sx={{ p: 0.5 }}>
            {DisplayNonClickableKPI(item.name, index, connectedBatteries)}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default PageHeaderKPI;
