import React, { useEffect, useState } from "react";
import TabWithColor from "../../../Features/Tabs/TabWithColor";
import { useSelector } from "react-redux";
import AntDatePicker from "../../../Helper/DatePicker/AntDatePicker";
import { Box } from "@mui/material";
import VitalTab from "./Vital/VitalTab";
import AlertLogTab from "./AlertLog/AlertLogTab";
import ChargerVitalsTab from "./ChargerVitals/ChargerVitalsTab";
import HealthTab from "./Health/HealthTab";
import BMSErrorLogTab from "./BMSErrorLog/BMSErrorLogTab";
import Events from "../../../Analytics/Events";
import {
  CustomTabSection,
  CustomTabOutline,
} from "../../../Components/CustomBox";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { getDatePickerDate } from "../../../Helper/DatePicker/DateConverter";
import Cookies from "universal-cookie";

const TabSection = (props) => {
  const {
    pagesContent,
    batteryID,
    hierarchy,
    tabs,
    dateFilters,
    hierarchySequence,
    alertsCountHierarchical,
    alertList,
    getAlerts,
    alertsFilters,
    alertsSorts,
    isAlertFetching,
    setDateFilters,
    alertsForAnnotation,
    openNotification,
    liveMetricDataForBattery,
    hierarchyLiveMetricData,
    ...other
  } = props;

  const navigate = useNavigate();
  const queryParams = decryptTheParams();
  const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);
  const [activeTabValue, setActiveTabValue] = useState(
    parseInt(queryParams?.tabValue) || 0
  );

  const changeTheDate = (startDate, endDate, extraPrams = {}) => {
    Events("On digital twin page, changed calendar date.");
    const temp = {
      ...queryParams,
      ...extraPrams,
      startDateSB: "",
      endDateSB: "",
    };

    setDateFilters({
      startDate: startDate || "",
      endDate: endDate || startDate || "",
    });

    encryptTheParams(temp, navigate, true);
  };

  const handleTabChange = (value) => {
    setActiveTabValue(value);
    const temp = {
      ...queryParams,
      tabValue: value,
    };
    encryptTheParams(temp, navigate, true);
  };

  useEffect(() => {
    const cookies = new Cookies()
    const oldTimeZone = decryptTheParams()?.oldTimeZone
    const currentTimeZone = cookies.get('timeZone')

    if (oldTimeZone === currentTimeZone) {
      return
    }

    const startDate = getDatePickerDate();
    const endDate = getDatePickerDate();
    changeTheDate(startDate, endDate)
  }, [timezoneChanged])

  return (
    <Box>
      <CustomTabSection>
        {tabs.length && <TabWithColor
          changeTheTab={handleTabChange}
          selectedTabValue={Math.max(tabs.findIndex(item => item.name === queryParams?.tabName), 0)}
          tabs={tabs}
        />}
        {![4].includes(activeTabValue) && <Box
          sx={{
            position: "absolute",
            bottom: 3,
            right: 75,
            zIndex: 1,
            pt: 1,
          }}
        >
          <AntDatePicker
            page="digitalTwin"
            changeTheDate={changeTheDate}
            date={[dateFilters.startDate, dateFilters.endDate]}
            allowClear={false}
          />
        </Box>}
      </CustomTabSection>

      <CustomTabOutline>
        {activeTabValue === 0 && (
          <VitalTab
            pagesContent={pagesContent}
            batteryID={batteryID}
            hierarchy={hierarchy}
            hierarchySequence={hierarchySequence}
            dateFilters={dateFilters}
            alertsCountHierarchical={alertsCountHierarchical}
            alertList={alertList}
            getAlerts={getAlerts}
            alertsFilters={alertsFilters}
            alertsSorts={alertsSorts}
            isAlertFetching={isAlertFetching}
            alertsForAnnotation={alertsForAnnotation}
            openNotification={openNotification}
            changeTheDate={changeTheDate}
            liveMetricDataForBattery={liveMetricDataForBattery}
            hierarchyLiveMetricData={hierarchyLiveMetricData}
          />
        )}
        {activeTabValue === 1 && (
          <ChargerVitalsTab
            pagesContent={pagesContent}
            batteryID={batteryID}
            hierarchy={hierarchy}
            dateFilters={dateFilters}
            alertsCountHierarchical={alertsCountHierarchical}
            alertList={alertList}
            getAlerts={getAlerts}
            alertsFilters={alertsFilters}
            alertsSorts={alertsSorts}
            isAlertFetching={isAlertFetching}
            alertsForAnnotation={alertsForAnnotation}
            openNotification={openNotification}
          />
        )}
        {activeTabValue === 2 && <AlertLogTab />}
        {activeTabValue === 3 && <BMSErrorLogTab />}
        {activeTabValue === 4 && 
          <HealthTab
            batteryID={batteryID}
            hierarchy={hierarchy}
            hierarchySequence={hierarchySequence}
          />
        }
      </CustomTabOutline>
    </Box>
  );
};

export default TabSection;
