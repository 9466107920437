import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BatteryInfoBox,
  BatteryInfoContainer,
  BatteryInfoGotToSBContainer,
  BatteryInfoItem,
  BatteryInfoLabel,
  BatteryInfoSOHBox,
  BatteryInfoSOHValueText,
  BatteryInfoValue,
  LaunchIcon,
  GoToBatteryPageText,
} from "./Components/StyledComponent";
import Events from "../../Analytics/Events";
import { useNotification } from "../../Hooks/useNotification";
import CardWithHeader from "../../Features/Cards/CardWithHeader";
import CustomIcon from "../../Components/CustomIcon";
import { getRealTimeStatus } from "../../Api/SpecificBatteryApi";
import CODE from "../../Static/Constants/StatusCodes";
import { SetSessionExpired } from "../../Actions";
import { findAgeInMonths } from "../../Helper/BatteryHealth/BatteryHealthHelper";
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";

const initialBatteryInfo = {
  soh: null,
  sohText: "-",
  age: "Not found",
  rul: "Not found",
  eqCycle: "Not found",
  deviceID: "Not found",
  batteryID: null,
  packOEM: "Not found",
  maxSoh: "Not found",
  minSOh: "Not found",
  initialCapacity: "Not found",
  presentCapacity: "Not found",
  cellType: "Not found",
  predictedEOL: "Not found",
  optimisticEOL: "Not found",
  pessimisticEOL: "Not found",
};

const BatteryInfoCardSection = ({ searchID, searchType, searchColumn, setBatterySOH }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openNotification, closeNotification } = useNotification();
  const [batteryInfo, setBatteryInfo] = useState(initialBatteryInfo);

  useEffect(() => {
    fetchBatteryInfo();
  }, [searchID, searchColumn]);

  const batteryInfoFields = [
    { label: "Serial Number", value: batteryInfo.deviceID },
    { label: "Pack Manufacturer", value: batteryInfo.packOEM },
    { label: "Initial Capacity (Ah)", value: batteryInfo.initialCapacity },
    { label: "Present Capacity (Ah)", value: batteryInfo.presentCapacity },
    { label: "Age (months)", value: batteryInfo.age },
    { label: "Eq. Cycle", value: batteryInfo.eqCycle },
    { label: "RUL (months)", value: batteryInfo.rul },
  ];

  const fetchBatteryInfo = async () => {
    setBatteryInfo({
      sohText: "...",
      soh: null,
      age: "...",
      rul: "...",
      eqCycle: "...",
      deviceID: "...",
      batteryID: null,
      maxSoh: "...",
      minSOh: "...",
      initialCapacity: "...",
      presentCapacity: "...",
      packOEM: "...",
      cellType: "...",
      predictedEOL: "...",
      optimisticEOL: "...",
      pessimisticEOL: "...",
    });

    try {
      const { responseStatus, response } = await getRealTimeStatus(searchColumn, searchID);

      if (responseStatus.code === CODE.SUCCESS) {
        updateBatteryInfo(response.batteryData);
      } else {
        handleError(responseStatus.code);
      }
    } catch (error) {
      handleError();
    }
  };

  const updateBatteryInfo = (data) => {
    const {
      sohEst,
      calendarAgeDaysEst,
      RULCalendarAgeDaysPredicted,
      eqCycleEst,
      initialCapacity,
      presentCapacity,
      deviceID,
      batteryID,
      packOEM,
      maxSoh,
      minSOh,
      cellType,
      eol,
      optimisticEol,
      pessimisticEol,
    } = data;

    const soh = parseFloat(sohEst?.toFixed(1)) || null;

    setBatteryInfo({
      soh,
      sohText: Number.isFinite(soh) ? `${soh}%` : "Not found",
      deviceID: deviceID || "Not found",
      batteryID: batteryID || null,
      cellType: cellType || "Not found",
      predictedEOL: eol || "Not found",
      packOEM: packOEM || "Not found",
      initialCapacity: Number.isFinite(initialCapacity) ? initialCapacity.toFixed(2) : "Not found",
      presentCapacity: Number.isFinite(presentCapacity) ? presentCapacity.toFixed(2) : "Not found",
      optimisticEOL: optimisticEol || "Not found",
      pessimisticEOL: pessimisticEol || "Not found",
      age: findAgeInMonths(calendarAgeDaysEst, "Not found"),
      rul: findAgeInMonths(RULCalendarAgeDaysPredicted, "Not found"),
      eqCycle: Number.isFinite(eqCycleEst) ? eqCycleEst : "Not found",
      maxSoh: Number.isFinite(maxSoh) ? maxSoh : "Not found",
      minSOh: Number.isFinite(minSOh) ? minSOh : "Not found",
    });

    setBatterySOH(soh);
  };

  const handleError = (statusCode) => {
    if (statusCode === CODE.SESSION_EXPIRED) {
      dispatch(SetSessionExpired(true));
    }
    setBatteryInfo(initialBatteryInfo);
  };

  const handleGoToBatteryPage = () => {
    Events("Battery Health: Clicked go to specific battery page");

    if (!batteryInfo.batteryID) {
      return
    }

    const queryParams = {
      batteryID: batteryInfo.batteryID,
      deviceID: batteryInfo.deviceID,
      navigateFromTab: true,
      tabValue: 4,
      tabName: "Health"
    };

    updateQueryParams(navigate, queryParams, true)
  };

  return (
    <CardWithHeader
      title="Present SOH"
      subtitle={`SOH of ${searchType} ${searchID}`}
      iconElement={<CustomIcon name="healthWithLine" />}
      pageLabel="Battery Health"
      onMouseEnter={() =>
        Events("Battery Health Page: Hover over Battery Info Card")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
    >
      <BatteryInfoContainer>
        <BatteryInfoSOHBox>
          <BatteryInfoSOHValueText soh={batteryInfo.soh}>
            {batteryInfo.sohText}
          </BatteryInfoSOHValueText>
        </BatteryInfoSOHBox>

        {/* Battery Info Fields */}
        <BatteryInfoBox>
          {batteryInfoFields.map((item, index) => (
            <BatteryInfoItem key={index}>
              <BatteryInfoLabel>{item.label}</BatteryInfoLabel>
              <BatteryInfoValue>{item.value}</BatteryInfoValue>
            </BatteryInfoItem>
          ))}
        </BatteryInfoBox>

        {/* Go to Battery Page */}
        <BatteryInfoGotToSBContainer onClick={handleGoToBatteryPage}>
          <GoToBatteryPageText>Go to battery page</GoToBatteryPageText>
          <LaunchIcon />
        </BatteryInfoGotToSBContainer>
      </BatteryInfoContainer>
    </CardWithHeader>
  );
};

BatteryInfoCardSection.defaultProps = {
  setBatterySOH: () => {},
};

export default BatteryInfoCardSection;
