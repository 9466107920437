const GetFilterKeyValue = (data) => {
  if ((!data) || data.length < 1) {
    return []
  }
  let MapLocationFilter = data.split(",");
  let mapFilterItems = [];

  MapLocationFilter.forEach((item) => {
    mapFilterItems.push({
      value: item,
      id: item,
    });
  });

  return mapFilterItems;
}

const extractArrayValuesForFilters = (filters) => {
  const result = {}
  for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
        result[key] = filters[key].map(item => item.id);
    }
  }
  return result
}

export {
  GetFilterKeyValue,
  extractArrayValuesForFilters
};