import React, { useState, useEffect, useRef, useMemo } from "react";
import { notification} from "antd";

import { NavLink } from "react-router-dom";
import {
  getDatePickerDate,
  getMetricTimeStamp,
} from "../../Helper/DatePicker/DateConverter";

import Coulomb_AI from "../../Assets/Icons/CoulombLogo.svg";
import Samsar from "../../Assets/Images/Sidebar/Samsar-big.svg";
import EcamSecure from "../../Assets/Images/Sidebar/EcamSecureLogo.svg";
import NSI from "../../Assets/Images/Sidebar/NSI_new.svg";
import LVT from "../../Assets/Images/Sidebar/LVT.svg";
import VerMac from "../../Assets/Images/Sidebar/VerMac_new.svg";

import ProfileAvatar from "../Avatar/ProfileAvatar";
import ProfilePopover from "../Popover/ProfilePopover";
import { CustomToolbar } from "../../Components/CustomToolbar";
import MiniDrawer from "./MiniDrawer";

import { AppBar } from "./AppbarStyles";
import {
  SetGlobalBatterySearched,
  SetGlobalBatteryTypedValue,
  SetSessionExpired,
} from "../../Actions";
import {
  SetAllBatteriesState,
  SetAllBatteriesVolt,
  SetAllBatteriesCity,
  SetAllBatteriesStatus,
  SetAllBatteriesMultiplePartners,
  SetAllBatteriesMultiplePartnersDropdown,
  SetAllBatteriesMultiplePartnersSelectAll,
  SetAllBatteriesUserInputBatteries,
  SetAllBatteriesFoundBatteries,
  SetAllBatteriesNotFoundBatteries,
  SetAllBatteriesPageNumber,
  SetAllBatteriesBatteryPartnerSelect,
  SetAllBatteriesStack,
} from "../../Actions/AllBatteries";

import {
  SetDashboardStartDate,
  SetDashboardEndDate,
  SetDashboardOpenAddSelectedBatteries,
} from "../../Actions/Dashboard";

import {
  SetIsBatteryIsPartnerIsNullDiagnostics,
  SetDiagnosticsBatteryID,
  SetDiagnosticsPartner,
  SetDiagnosticsCity,
  SetDiagnosticsZone,
  SetDiagnosticsStatus,
  SetDiagnosticsPageNumber,
  SetDiagnosticsErrorTypeIndex,
  SetDiagnosticsErrorTypeCode,
  SetDiagnosticsTotal,
  SetDiagnosticsStartDate,
  SetDiagnosticsEndDate,
  SetDiagnosticsErrorTypeList,
  SetDiagnosticsIOT,
  SetDiagnosticsPack,
  SetDiagnosticsOpenAddSelectedBatteries,
  SetDiagnosticsStack,
  SetDiagnosticsDeviceID,
} from "../../Actions/Diagnostics";

import {
  SetBatteryHealthPageNumber,
  SetBatteryHealthRowsPerPage,
} from "../../Actions/BatteryHealth";

import {
  SetIsBatteryIsPartnerIsNullAnomaly,
  SetAnomalyBatteryID,
  SetAnomalyPartner,
  SetAnomalyVolt,
  SetAnomalyCell,
  SetAnomalyCity,
  SetAnomalyZone,
  SetAnomalyVersion,
  SetAnomalyBuildNo,
  SetAnomalyStatus,
  SetAnomalyTabValue,
  SetAnomalyIndex,
  SetAnomalyCode,
  SetAnomalyTotal,
  SetAnomalyStartDate,
  SetAnomalyEndDate,
  SetAnomalyList,
  SetAnomalyListOfBatteries,
} from "../../Actions/Anomaly";

import {
  SetWarrantyTabValue,
  SetWarrantyIndex,
  SetWarrantyCode,
  SetWarrantyTotal,
  SetWarrantyList,
  SetWarrantyListOfBatteries,
  SetWarrantyIOT,
  SetWarrantyStack,
  SetWarrantyPack,
  SetWarrantyCycle,
  SetWarrantyAge,
  SetWarrantySOH,
  SetWarrantyOpenAddSelectedBatteries,
} from "../../Actions/Warranty";

import {
  SetMapFullScreen,
  SetMapSearch,
  SetMapSearchedBattery,
  SetMapBatteryPartnerSelect,
  SetMapUserInputBatteries,
  SetMapFoundBatteries,
  SetMapNotFoundBatteries,
  SetMapMultiplePartners,
  SetMapVolt,
  SetMapCity,
  SetMapStatus,
  SetMapSeverity,
  SetMapZone,
  SetMapAlertType,
  SetMapIotVendor,
  SetMapBatteryVendor,
  SetOrgFilter,
} from "../../Actions/Map";

import {
  SetAlertAnalyticsEndDate,
  SetAlertAnalyticsGranularity,
  SetAlertAnalyticsIOT,
  SetAlertAnalyticsPack,
  SetAlertAnalyticsStartDate,
} from "../../Actions/Reports/AlertAnalytics";

import {
  SetReportsDQIOT,
  SetReportsDQPack,
  SetReportsDQStartDate,
  SetReportsDQEndDate,
  SetReportsDQGranularity,
} from "../../Actions/Reports/DataQuality";

import Cookies from "universal-cookie";
import Events from "../../Analytics/Events";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getLastUpdated } from "../../Api/Api";
import AdvanceSearchBatteries from "../Dialog/AdvanceSearchBatteries";
import {
  formatDateNumeric,
  returnLastSunday,
} from "../../Helper/DatePicker/DateFormatters";
import {
  newIOT,
  newPack,
  newStatus,
  newZone,
} from "../../Static/Data/Dashboard/DashboardFilters";
import moment from "moment";
import CODE from "../../Static/Constants/StatusCodes";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { LastUpdatedTimeBox, LastUpdatedTimeStampText, LastUpdatedTimeText } from "../../Components/StyledComponent";
const Context = React.createContext({
  name: "Default",
});

export default function Appbar(props) {
  const cookies = new Cookies();
  const location = useLocation();
  const dispatch = useDispatch();
  const newCity = localStorage.getItem("LocationFilter")?.split(",");
  const newOrg = localStorage.getItem("OrgFilter")?.split(",");
  var userTimeZone = cookies.get("timeZone");

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      // message: `Notification ${placement}`,s
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const universal = useSelector((state) => state.DashboardStack.arr);
  const [currentLocation, setCurrentLocation] = useState("");
  const previousLocation = useRef(null);
  const [lastUpdated, setLastUpdated] = useState("DD MMM, 00:00 PM");

  const [anchorElProfilePopup, setAnchorElProfilePopup] = React.useState(null);
  const openProfilePopup = Boolean(anchorElProfilePopup);
  const idProfilePopup = openProfilePopup
    ? "simple-popover-profile"
    : undefined;
  const handleOpenProfilePopup = (event) => {
    setAnchorElProfilePopup(event.currentTarget);
  };
  const handleCloseProfilePopup = () => {
    setAnchorElProfilePopup(null);
  };

  const [advancedSearch, setAdvancedSearch] = useState(false);
  const handleCloseAdvancedSearch = () => {
    setAdvancedSearch(false);
  };

  const fleetID = +cookies.get("fleetID");
  const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);

  useEffect(() => {
    let itemIDS = [];
    universal.map((item) => itemIDS.push(...item.itemIDs));

    if (itemIDS.length > 0) {
      window.onbeforeunload = function () {
        return "";
      };
      dispatch(SetDashboardOpenAddSelectedBatteries(true));
    }

    getLastUpdated().then((res) => {
      if (res?.responseStatus?.code === CODE.SUCCESS) {
        setLastUpdated(
          moment(getMetricTimeStamp(res.response.data.lastPingedTime)).format(
            "DD MMM'YY, hh:mm A"
          )
        );
      } else if (res.responseStatus.code === CODE.SESSION_EXPIRED) {
        dispatch(SetSessionExpired(true));
      }
    });

    previousLocation.current = currentLocation;
    setCurrentLocation(location.pathname);


    if (location.pathname !== "/specificBattery") {
      dispatch(SetGlobalBatterySearched(""));
      dispatch(SetGlobalBatteryTypedValue(""));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/AllBatteries"
    ) {
      dispatch(SetAllBatteriesStack([]));
      dispatch(SetAllBatteriesState(""));
      dispatch(SetAllBatteriesVolt(""));
      dispatch(SetAllBatteriesCity(""));
      dispatch(SetAllBatteriesStatus(""));
      dispatch(SetAllBatteriesMultiplePartners([]));
      dispatch(SetAllBatteriesMultiplePartnersDropdown([]));
      dispatch(SetAllBatteriesMultiplePartnersSelectAll(false));
      dispatch(SetAllBatteriesUserInputBatteries([]));
      dispatch(SetAllBatteriesFoundBatteries([]));
      dispatch(SetAllBatteriesNotFoundBatteries([]));
      dispatch(SetAllBatteriesPageNumber(1));
      dispatch(SetAllBatteriesBatteryPartnerSelect("Battery ID"));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/Diagnostics"
    ) {
      dispatch(SetDiagnosticsStack([]));
      dispatch(SetIsBatteryIsPartnerIsNullDiagnostics(null));
      dispatch(SetDiagnosticsBatteryID(null));
      dispatch(SetDiagnosticsDeviceID(null));
      dispatch(SetDiagnosticsPartner(""));

      dispatch(SetDiagnosticsCity(newCity));
      dispatch(SetOrgFilter(newOrg));
      dispatch(SetDiagnosticsZone(newZone));
      dispatch(SetDiagnosticsStatus(newStatus));
      dispatch(SetDiagnosticsIOT(newIOT));
      dispatch(SetDiagnosticsPack(newPack));

      dispatch(SetDiagnosticsPageNumber(1));
      dispatch(SetDiagnosticsErrorTypeList([]));
      dispatch(SetDiagnosticsErrorTypeCode(""));
      dispatch(SetDiagnosticsErrorTypeIndex(0));

      dispatch(SetDiagnosticsTotal(0));

      dispatch(
        SetDiagnosticsStartDate(
          getDatePickerDate(
            decryptTheParams()['startDate'] || new Date(new Date().setDate(new Date().getDate() - 45))
          )
        )
      );
      dispatch(SetDiagnosticsEndDate(decryptTheParams()['endDate'] || getDatePickerDate()));
      dispatch(SetDiagnosticsOpenAddSelectedBatteries(false));
      localStorage.removeItem("bpSelectDiag");
      localStorage.removeItem("bpValueDiag");
    }

    if (
      location.pathname === "/Alerts"
    ) {
      dispatch(
        SetDashboardStartDate(
          getDatePickerDate(
            new Date(new Date().setDate(new Date().getDate() - 45))
          )
        )
      );
      dispatch(SetDashboardEndDate(getDatePickerDate()));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/Anomaly"
    ) {
      dispatch(SetIsBatteryIsPartnerIsNullAnomaly(null));
      dispatch(SetAnomalyBatteryID(null));
      dispatch(SetAnomalyPartner(null));

      dispatch(SetAnomalyVolt(""));
      dispatch(SetAnomalyCell(""));
      dispatch(SetAnomalyCity(""));
      dispatch(SetAnomalyZone(""));
      dispatch(SetAnomalyVersion(""));
      dispatch(SetAnomalyBuildNo([]));
      dispatch(SetAnomalyStatus(""));

      dispatch(SetAnomalyTabValue(0));
      dispatch(SetAnomalyIndex(0));
      dispatch(SetAnomalyCode(""));
      dispatch(SetAnomalyTotal(0));

      dispatch(SetAnomalyStartDate(""));
      dispatch(SetAnomalyEndDate(""));
      dispatch(SetAnomalyList([]));
      dispatch(SetAnomalyListOfBatteries([]));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/Warranty"
    ) {
      dispatch(SetWarrantyStack([]));
      dispatch(SetWarrantyIOT(""));
      dispatch(SetWarrantyPack(""));
      dispatch(SetWarrantyCycle(""));
      dispatch(SetWarrantyAge(""));
      dispatch(SetWarrantySOH(""));
      dispatch(SetWarrantyOpenAddSelectedBatteries(false));

      dispatch(SetWarrantyTabValue(0));
      dispatch(SetWarrantyIndex(0));
      dispatch(SetWarrantyCode(""));
      dispatch(SetWarrantyTotal(0));

      dispatch(SetWarrantyList([]));
      dispatch(SetWarrantyListOfBatteries([]));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/BatteryHealth"
    ) {
      dispatch(SetBatteryHealthPageNumber(1));
      dispatch(SetBatteryHealthRowsPerPage(20));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/Map"
    ) {
      const queryParamsOrg = decryptTheParams()['Organization']
      let selectedOrganization = newOrg
      if (queryParamsOrg && queryParamsOrg.length > 0) {
        selectedOrganization = queryParamsOrg.split(',')
      }
      // console.log("this is not called");
      dispatch(SetMapFullScreen(false));
      dispatch(SetMapSearch(false));
      dispatch(SetMapSearchedBattery(""));
      dispatch(SetMapBatteryPartnerSelect("Device ID"));
      dispatch(SetMapUserInputBatteries([]));
      dispatch(SetMapFoundBatteries([]));
      dispatch(SetMapNotFoundBatteries([]));
      dispatch(SetMapMultiplePartners([]));
      dispatch(SetMapVolt(""));
      dispatch(SetMapCity(newCity));
      dispatch(SetOrgFilter(selectedOrganization));
      dispatch(SetMapStatus(newStatus));
      dispatch(SetMapSeverity(""));
      dispatch(SetMapZone(""));
      dispatch(SetMapAlertType(""));
      dispatch(SetMapIotVendor(newIOT));
      dispatch(SetMapBatteryVendor(newPack));
      localStorage.removeItem("InputMapBatteries");
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/_Reports/AlertAnalytics"
    ) {
      // console.log("this is not called");
      dispatch(
        SetAlertAnalyticsStartDate(
          formatDateNumeric(
            new Date(new Date().setDate(new Date().getDate() - 34))
          )
        )
      );
      dispatch(
        SetAlertAnalyticsEndDate(
          formatDateNumeric(new Date(returnLastSunday()))
        )
      );
      dispatch(SetAlertAnalyticsGranularity("Weekly"));
      dispatch(SetAlertAnalyticsIOT(""));
      dispatch(SetAlertAnalyticsPack(""));
    }

    if (
      location.pathname !== "/specificBattery" &&
      location.pathname !== "/_Reports/DataQuality"
    ) {
      // console.log("this is not called");
      dispatch(
        SetReportsDQStartDate(
          formatDateNumeric(
            new Date(new Date().setDate(new Date().getDate() - 34))
          )
        )
      );
      dispatch(
        SetReportsDQEndDate(formatDateNumeric(new Date(returnLastSunday())))
      );
      dispatch(SetReportsDQGranularity("Weekly"));
      dispatch(SetReportsDQIOT(""));
      dispatch(SetReportsDQPack(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, timezoneChanged]);

  const pagesLinks = props.appbarLinks;
  const AppbarLinks = [];
  pagesLinks.forEach((item) => {
    if (cookies.get("pages") && cookies.get("pages").includes(item["mykey"])) {
      if (item["queryParam"] && Object.keys(item["queryParam"]).length !== 0) {
        const queryParam = Object.entries(item["queryParam"]);
        const path =
          item["to"] + "/" + queryParam[0][0] + "=" + queryParam[0][1];
        AppbarLinks.push({
          to: path,
          mykey: item["mykey"],
          textPrimary: item["text"],
          icon: item["src"],
          icon_white: item["src_white"],
          iconName: item["iconName"],
        });
      } else {
        const path = item["to"];

        AppbarLinks.push({
          to: path,
          mykey: item["mykey"],
          textPrimary: item["text"],
          icon: item["src"],
          icon_white: item["src_white"],
          iconName: item["iconName"],
        });
      }
    }
    const queryParam = Object.entries(item["queryParam"]);
  });

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        <div>
          <AppBar>
            <CustomToolbar>
              <NavLink exact to="/">
                <div
                  style={{
                    height: "42px",
                    width: "150px",
                    backgroundColor:
                      [1, 3, 13].includes(fleetID)
                        ? "black"
                        : "transparent",
                    padding: "4px",
                    paddingLeft: "17px",
                    display:"flex",
                    paddingRight: "24px",
                    boxSizing: 'initial'
                  }}
                >
                  <img
                    src={
                      fleetID === 0
                        ? Samsar
                        : fleetID === 1
                          ? VerMac
                          : fleetID === 2
                            ? LVT
                            : fleetID === 3
                              ? NSI
                              : fleetID === 13
                                ? EcamSecure
                                : Coulomb_AI
                    }
                    alt="not found"
                    onClick={(event) => {
                      let itemIDS = [];
                      universal.map((item) => itemIDS.push(...item.itemIDs));

                      if (itemIDS.length > 0) {
                        event.preventDefault();
                        dispatch(SetDashboardOpenAddSelectedBatteries(true));
                      } else {
                        Events("clicked Landing page");
                      }
                    }}
                    height={"39px"}
                    width={
                      fleetID === 2 
                        ? "50px" 
                        : fleetID === 13
                          ? "150px"
                          : "121px"
                    }
                    style={{ margin: 0 }}
                  />
                </div>
              </NavLink>

              <LastUpdatedTimeBox>
                <LastUpdatedTimeText>
                  Last Updated
                </LastUpdatedTimeText>

                <LastUpdatedTimeStampText>
                  {lastUpdated} {userTimeZone}
                </LastUpdatedTimeStampText>

                <div onClick={handleOpenProfilePopup}>
                  <ProfileAvatar
                    showIcon={false}
                    openProfilePopup={openProfilePopup}
                  />
                </div>
              </LastUpdatedTimeBox>
            </CustomToolbar>
          </AppBar>

          <MiniDrawer hideLogo={![0, 1, 2, 3, 13].includes(fleetID)} appbarLinks={AppbarLinks} />

          {openProfilePopup && (
            <ProfilePopover
              idProfilePopup={idProfilePopup}
              openProfilePopup={openProfilePopup}
              anchorElProfilePopup={anchorElProfilePopup}
              handleCloseProfilePopup={handleCloseProfilePopup}
              openNotification={openNotification}
            />
          )}
          <AdvanceSearchBatteries
            advancedSearch={advancedSearch}
            handleCloseAdvancedSearch={handleCloseAdvancedSearch}
          />
        </div>
      </Context.Provider>
    </>
  );
}
