import React, { useState, useEffect } from "react";
// import useState from "react-usestateref";
// import "./table.css";
import { Table, Pagination } from "antd";
import ReactDragListView from "react-drag-listview";
import { ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  SetDashboardStack,
  SetDashboardColumnSort,
  SetDashboardPageNumber,
  SetDashboardRowsPerPage,
  SetDashboardColumns,
} from "../../../Actions/Dashboard";
import { SetSessionExpired } from "../../../Actions";
import { SetTableOrder } from "../../../Actions";
import { Tooltip } from "antd";
import { getSelectAllBatteries } from "../../../Api/DashboardApi";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import NoData from "../../../Helper/Loaders/NoData";
import CODE from "../../../Static/Constants/StatusCodes";
import { handleScroll } from "./TableFunctions";
import Events from "../../../Analytics/Events";
import Loader from "../../../Helper/Loaders/Loader";

export default function TableComponent(props) {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(
    props.columns[props.tabvalue]["columns"]
  );

  const [list, setList] = useState({
    batteries: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });

  const universalStack = useSelector((state) => state.DashboardStack.arr);
  const existingBatteries = useSelector(
    (state) => state.DashboardExistingBatteries.arr
  );
  const pageNumber = useSelector((state) => state.DashboardPageNumber.value);
  const rowsPerPage = useSelector((state) => state.DashboardRowsPerPage.value);
  const sortChangedTo = useSelector((state) => state.DashboardColumnSort.arr);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let dragIndex = props.rowSelection
        ? fromIndex > 1 && toIndex > 1
        : fromIndex > 0 && toIndex > 0;

      if (dragIndex) {
        const newColumns = [...columns];
        const item = props.rowSelection
          ? newColumns.splice(fromIndex - 1, 1)[0]
          : newColumns.splice(fromIndex, 1)[0];
        props.rowSelection
          ? newColumns.splice(toIndex - 1, 0, item)
          : newColumns.splice(toIndex, 0, item);

        setColumns(newColumns);

        const finalColumns = [...props.columns];
        finalColumns[props.tabvalue]["columns"] = newColumns;

        dispatch(SetDashboardColumns(finalColumns));
      }
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  let itemIDS = [];

  universalStack.map((item) => {
    const IDList = item.itemIDs?.map((itemList) => {
      return `${itemList.batteryID}-${itemList.alertStatus}-${itemList.alertMetaID}`
    }) || []

    itemIDS.push(...IDList)
  });

  const rowSelection = {
    selectedRowKeys: itemIDS,

    onSelectAll: function (selected, selectedRows, changeRows) {
      clickedSelectAllCheckbox(selected);
    },

    onSelect: function (selected, selectedRows, changeRows) {
      let itemIds = universalStack.find(
        (item) => item.alertCode === props.alertCode
      )["itemIDs"];

      let index = universalStack
        .map((item) => item.alertCode)
        .indexOf(props.alertCode);
      let newState = [...universalStack];

      if (selectedRows) {
        let stackData = [...itemIds, {
          "batteryID": selected.batteryID,
          "configBOPID": selected.configBOPID,
          "alertMetaID": props.alertTypeIndexRedux,
          "alertStatus": selected.alertStatus,
          "alertStateIds": selected.alertStateIds
        }];
        newState[index]["itemIDs"] = [...stackData];
        dispatch(SetDashboardStack(newState));
      } else {
        let stackData = itemIds.filter((key) => `${key.batteryID}-${key.alertStatus}-${key.alertMetaID}`.toLowerCase().replace(/ /g, "") !== selected.id);
        newState[index]["itemIDs"] = [...stackData];
        dispatch(SetDashboardStack(newState));
      }
    },
    renderCell: (checked, record, index, originNode) => {
      return originNode.props.disabled ? (
        <Tooltip
          placement="top"
          title={
            "Battery added to take action. If needed to be removed, please remove from cart"
          }
        >
          <IndeterminateCheckBoxIcon
            sx={{ fontSize: "20px", color: "#d8d8d8", cursor: "no-drop" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Tooltip>
      ) : (
        originNode
      )
    },
    getCheckboxProps: (record) => {
      const idsInStack = existingBatteries.map((item) => {
        return `${item.batteryID}-${item.alertStatus}-${props.alertTypeIndexRedux}`.toLowerCase().replace(/ /g, "")
      })
      return {
        disabled: idsInStack.includes(record.id),
        // Column configuration not to be checked
      }
    },
  };

  function hideColumns(columns) {
    return columns.filter((item) => !item.hidden);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.column) {
      let order = sorter.order === "ascend" ? "asc" : "desc";
      const tempSort = [...sortChangedTo];
      tempSort[props.tabvalue]["sortBy"] = sorter.columnKey;
      dispatch(SetDashboardColumnSort(tempSort));
      dispatch(SetTableOrder(order));
    }
  };

  const handlePageChange = (page, pageSize) => {
    dispatch(SetDashboardPageNumber(page));
  };

  const onShowSizeChange = (current, pageSize) => {
    dispatch(SetDashboardPageNumber(1));

    dispatch(SetDashboardRowsPerPage(pageSize));
  };

  function clickedSelectAllCheckbox(selectAll) {
    let newtempIds = [];
    if (selectAll) {
      getSelectAllBatteries(
        props.alertTypeIndexRedux,
        props.city,
        props.zone,
        props.volt,
        props.category,

        props.alertCode,
        props.batterystatus,
        props.severity,
        props.iotVendor,
        props.packVendor,
        props.tabvalue,

        props.priority,
        props.batteryState,
        props.date,

        props.batteryID,
        props.partner,
        props.batteryType
      ).then((res) => {
        if (res.responseStatus.code === 200) {
          newtempIds = res.response.data;

          let tempSelected = res.response.data;

          let finalArr = tempSelected.filter((n) => !newtempIds.includes(n));
          let final2 = universalStack
            .find((item) => item.alertCode === props.alertCode)
          ["itemIDs"].filter((n) => !finalArr.includes(n));
          let final3 = final2.concat(newtempIds);
          let final4 = final3.filter(item1 => {
            return !existingBatteries.some(item2 => item2.batteryID === item1.batteryID && item2.alertMetaID === item1.alertMetaID && item2.alertStatus === item1.alertStatus);
          });

          let uniqueChars = [...new Set(final4)];
          uniqueChars.forEach(item => {
            item.alertStateIds = JSON.parse(item.alertStateIds);
          });
          let index = universalStack
            .map((item) => item.alertCode)
            .indexOf(props.alertCode);
          let newState = [...universalStack];
          newState[index]["itemIDs"] = [...uniqueChars];
          props.openNotification(
            "bottomLeft",
            `${uniqueChars.length} alerts selected`
          );
          dispatch(SetDashboardStack(newState));
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          dispatch(SetDashboardStack([]));
        }
      });
    } else {
      let index = universalStack
        .map((item) => item.alertCode)
        .indexOf(props.alertCode);
      let newState = [...universalStack];
      newState[index]["itemIDs"] = [];
      dispatch(SetDashboardStack(newState));
    }
    Events("clicked global select Alerts page table");
  }

  useEffect(() => {
    if (props.data && props.data.batteries?.length > 0) {
      // props.data.responseStatus.code !== 1999/404
      let newData = props.data.batteries.map((row) => ({
        ...row,
        checked: false,
      }));

      setList({
        batteries: newData,
        responseStatus: {
          code: props.extraStatus === -1 ? -1 : props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    } else {
      setList({
        batteries: [],
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: ThemeProperties.purple,
          },
        }}
      >
        <ReactDragListView.DragColumn {...dragProps}>
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  // let itemIDS = [];
                  // universalStack.map((item) => itemIDS.push(...item.itemIDs));
                  if (
                    event.target.classList[1] ===
                    "ant-table-selection-column" ||
                    event.target.classList[0] === "ant-checkbox-inner" ||
                    event.target.classList[2] === "ant-checkbox-checked" ||
                    event.target.classList[0] === "ant-checkbox-wrapper" ||
                    event.target.classList[0] === "ant-checkbox"
                  ) {
                  }
                },
              };
            }}
            className={
              props.rowSelection
                ? "LandingPageTable Selection"
                : "LandingPageTable NoSelection"
            }
            locale={{
              emptyText: (
                <div style={{ minHeight: "500px", height: "500px" }}>
                  {list.responseStatus.code === 1999 ||
                    list.responseStatus.code === 404 ||
                    list.responseStatus.code === -2 ? (
                    <div style={{ height: "100%" }}>
                      <NoData text={"No alerts found"} />
                    </div>
                  ) : (
                    <p style={{ height: "100%", color: "white" }}></p>
                  )}
                </div>
              ),
            }}
            columns={hideColumns(columns)}
            rowSelection={props.rowSelection && rowSelection}
            rowKey={(record) => record.id}
            rowClassName="rowClass"
            dataSource={
              list.responseStatus.code === CODE.LOADING
                ? []
                : list.responseStatus.code === 1999 ||
                  list.responseStatus.code === 404 ||
                  list.responseStatus.code === -2
                  ? []
                  : list.batteries
            }
            pagination={false}
            loading={{
              spinning:
                list.responseStatus.code === CODE.LOADING
                  ? true
                  : list.responseStatus.code === CODE.NODATA
                    ? false
                    : false,
              indicator: (
                <>
                  <div style={{ height: "130px" }}></div>
                  <Loader />
                </>
              ),
            }}
            onChange={handleTableChange}
            style={{ height: "575px" }}
            scroll={handleScroll([...columns])}
            bordered
            size="middle"
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              pageSize={rowsPerPage}
              pageSizeOptions={[10, 20, 30]}
              current={pageNumber}
              onChange={handlePageChange}
              total={props.total}
              showTotal={(total, range) => `${range[0]}-${range[1]}/${total}`}
            />
          </div>
        </ReactDragListView.DragColumn>
      </ConfigProvider>
    </>
  );
}
