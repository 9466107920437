import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { findTotalBatteries } from "../../Helper/BatteryHealth/BatteryHealthHelper"
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { SetBatteryHealthSOH } from "../../Actions/BatteryHealth";
import Loader from "../../Helper/Loaders/Loader"
import ApexBasicDonut from "../../Charts/Donut/ApexBasicDonut";
import { BatteriesCountPostFixBox, BatteriesCountPostFixText, BatteryCountText, HealthDistributionBatteriesCountBox, HealthDistributionChartContainer, SelectedSOHBox, SOHLevelResetButton } from "./Components/StyledComponent";
import Events from "../../Analytics/Events";
import CODE from "../../Static/Constants/StatusCodes";
import CardWithHeader from "../../Features/Cards/CardWithHeader";
import { useNotification } from "../../Hooks/useNotification";
import CustomIcon from "../../Components/CustomIcon"

const SOHDoughnutChartSection = ({
  healthDistributionData,
  setHealthDistributionData
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const sohLevel = useSelector((state) => state.BatteryHealthSOH.value)
  const { openNotification, closeNotification } = useNotification();
  
  const handleSohValue = (value) => {
    let selectedSOHLevel = healthDistributionData.sohLevels[value] || {}
    if (sohLevel?.label === value) {
      selectedSOHLevel = {}
    } 
    dispatch(SetBatteryHealthSOH(selectedSOHLevel));
    updateQueryParams(navigate, {
      sohLevel: JSON.stringify(selectedSOHLevel),
    });
  };

  return (
    <CardWithHeader
      cardStyle={{ opacity: healthDistributionData.responseStatus.code === -1 ? 0.7 : 1 }}
      bodyStyle={{ height: "440px" }}
      iconElement={<CustomIcon name="healthWithLine"></CustomIcon>}
      onMouseEnter={() => Events("Hovered Fleet Battery Health Distribution")}
      title="Fleet Battery Health Distribution"
      subtitle="Distribution of SOH across the fleet"
      page="BatteryHealth"
      openNotification={openNotification}
      closeNotification={closeNotification}
    >
      <SelectedSOHBox>
        {sohLevel !== "" && (
          <SOHLevelResetButton
            onClick={() => {
              handleSohValue("")
            }}
          >
            Reset
          </SOHLevelResetButton>
        )}
      </SelectedSOHBox>
      {healthDistributionData.responseStatus.code === CODE.SUCCESS ? (
        <HealthDistributionChartContainer>
          <HealthDistributionBatteriesCountBox>
            <BatteryCountText>
              {findTotalBatteries(healthDistributionData, sohLevel.label)}
            </BatteryCountText>
          </HealthDistributionBatteriesCountBox>
          <BatteriesCountPostFixBox>
            <BatteriesCountPostFixText>
              {sohLevel?.label || "All Batteries"}
            </BatteriesCountPostFixText>
          </BatteriesCountPostFixBox>
          <ApexBasicDonut
            totalLabel={""}
            showLegend={true}
            data={healthDistributionData}
            sohLevel={sohLevel}
            setData={setHealthDistributionData}
            handleSohValue={handleSohValue}
          />
        </HealthDistributionChartContainer>
      ) : (
        <>
          {healthDistributionData.responseStatus.code === CODE.LOADING ? (
            <Loader />
          ) : (
            <h1>no data</h1>
          )}
        </>
      )}
    </CardWithHeader>
  )
}

export default SOHDoughnutChartSection
