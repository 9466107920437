import { ThemeProperties } from "../../../Theme/ThemeProperties";
import { useSelector } from "react-redux";
import React from "react";

function GetDashboardTabs(tabCounts) {
  const Tabs = [
    {
      id: 1,
      name: "Active",
      color: ThemeProperties.red_400,
      description: "Needs attention",
      placement: "topLeft",
      count: tabCounts.active,
    },
    {
      id: 2,
      name: "Open",
      color: ThemeProperties.blue_400,
      description: "Action taken",
      placement: "topLeft",
      count: tabCounts.open,
    },
    {
      id: 3,
      name: "Resolved",
      color: ThemeProperties.green_400,
      description: "Alert recovered",
      placement: "topLeft",
      count: tabCounts.resolved,
    },
    {
      id: 4,
      name: "Expired",
      color: ThemeProperties.green_400,
      description: "No more alerting",
      placement: "topLeft",
      count: tabCounts.expired,
    },
  ];

  return Tabs;
}

export default GetDashboardTabs;
