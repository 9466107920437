import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Events from "../../Analytics/Events";

const ApexBasicDonut = (props) => {
  const [chartState, setChartState] = useState({
    series: [],
    options: {},
  });

  const handleSetBatteryHealthSOH = (value) => {
    props.handleSohValue(value);
  };

  function mountTheChart(series, labels, colors, dataLabelColors, minAngle,decimalPlaces) {
    setChartState({
      series: series,
      options: {
        grid: {
          padding: {
            top: 20,
            bottom: 10,
          },
        },
        chart: {
          type: "donut",
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              Events("Fleet Battery Health Distribution selected category");
              handleSetBatteryHealthSOH(
                config.w.config.labels[config.dataPointIndex]
              );
            },
            legendClick: (chartContext, seriesIndex, config) => {
              Events("Fleet Battery Health Distribution selected category");
              handleSetBatteryHealthSOH(config.globals.labels[seriesIndex]);
            },
          },
        },
        labels: labels,
        plotOptions: {
          pie: {
            expandOnClick: false,
            dataLabels: {
              offset: 35,
              minAngleToShowLabel: minAngle,
            },
            donut: {
              size: "75%",
              labels: {
                show: false,
              },
            },
          },
        },
        colors: colors,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "13px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            colors: dataLabelColors,
          },
          dropShadow: {
            enabled: false,
          },
          formatter: function (val, opts) {
            return val?.toFixed(decimalPlaces) + "%"
        },
        },
        tooltip: {
          enabled: false,
        },

        legend: {
          show: props.showLegend,
          fontSize: "12px",
          color: "#1C1C1C",
          fontFamily: "Roboto",
          fontWeight: 400,
          horizontalAlign: 'left', 
          offsetY: 10,
          position: "bottom",
          itemMargin: {
            horizontal: 7,
            vertical: 5,
          },
          markers: {
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 14,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
          },
          onItemHover: {
            highlightDataSeries: false
        },
        },
        states: {
          hover: {
              filter: {
                  type: 'none',
              }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none',
            }
        },
      }
      },
    });
  }

  useEffect(() => {
    const newColors = [
      "#B5E398",
      "#64AA35",
      "#185E01",
      "#F0CC48",
      "#F89F35",
      "#F22C25",
      "#AA100A",
      "#430A07"
    ]
    
    const newLightColors = [
      "#B5E3984D",
      "#64AA354D",
      "#185E014D",
      "#F0CC484D",
      "#F89F354D",
      "#F22C254D",
      "#AA100A4D",
      "#430A074D"
    ]

    const tempColors=[],tempDataColors=[];

    if (!props.sohLevel?.label) {
      mountTheChart(props.data.series, props.data.labels, newColors, ['#1C1C1C'],2,1);
    } else {
      let itemIndex = props.data.labels.indexOf(props.sohLevel?.label);
      newColors.forEach((item, index) => {
        if (index === itemIndex) {
          tempColors.push(newColors[itemIndex]);
          tempDataColors.push("#1C1C1C");
        } else {
          tempColors.push(newLightColors[index]);
          tempDataColors.push("transparent");
        }
      });

      mountTheChart(props.data.series, props.data.labels, tempColors, tempDataColors,0,1);
    }
  }, [props.data, props.sohLevel]);

  useEffect(
    () => () => {
      setChartState({});
      props.setData({
        series: [],
        labels: [],
        totalLabel: props.totalLabel,
        responseStatus: { code: -1, msg: "" },
      });
    },
    []
  );

  return (
    <div id="donutChart" style={{ height: "100%" }}>
      <Chart
        options={chartState.options}
        series={chartState.series}
        type="donut"
        height="100%"
        id=""
      />
    </div>
  );
};
export default ApexBasicDonut;
