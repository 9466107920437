import { SvgIcon } from "@mui/material"
import React from "react"

const NavbarBatteryHealthIcon = (props) => {
  const { height, width, viewBox, fill, style, stroke, ...other } = props
  return (
    <SvgIcon
      style={style}
    >
      <svg 
        width={width}
        height={height}
        viewBox={viewBox}
        fill={'transparent'}
      >
        <path d="M1.3 17H8.7C9.14183 17 9.5 16.6418 9.5 16.2V3.8C9.5 3.35817 9.14183 3 8.7 3H7V1.8C7 1.35817 6.64183 1 6.2 1H3.8C3.35817 1 3 1.35817 3 1.8V3H1.3C0.858172 3 0.5 3.35817 0.5 3.8V16.2C0.5 16.6418 0.858172 17 1.3 17Z" stroke={fill} stroke-width="1.5"/>
        <path d="M4.28996 8.45711L4.99707 9.16421L5.70418 8.45711C5.75796 8.40332 5.83388 8.34581 5.91795 8.30474C6.00053 8.26439 6.06955 8.25 6.12207 8.25C6.21822 8.25 6.29994 8.28652 6.36456 8.35551C6.42955 8.42489 6.49707 8.55103 6.49707 8.75C6.49707 9.23822 6.2407 9.68071 5.79543 10.1005C5.53595 10.3452 5.25516 10.543 4.99707 10.7068C4.73898 10.543 4.45819 10.3452 4.19871 10.1005C3.75344 9.68071 3.49707 9.23822 3.49707 8.75C3.49707 8.55532 3.5641 8.42887 3.63152 8.35724C3.69963 8.28487 3.78297 8.25 3.87207 8.25C3.9246 8.25 3.99361 8.26439 4.0762 8.30474C4.16026 8.34581 4.23618 8.40332 4.28996 8.45711Z" fill="#E9E9E9" stroke={fill} stroke-width="2"/>
      </svg>
    </SvgIcon>
  )
}

NavbarBatteryHealthIcon.defaultProps = {
  width: "10",
  height: "18",
  viewBox: "0 0 10 18",
  fill: "#8D9FB2",
  stroke: ""
}

export default NavbarBatteryHealthIcon
