import React, { useEffect, useMemo } from "react";
import {
  Box,
  Paper,
  Button,
  Grid,
  Badge,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import "../../Features/Table/AntDesign/table.css";
import { OuterBox, InnerBox } from "../../Components/CustomBox";

import TabWithColorDashboard from "../../Features/Tabs/TabWithColorDashboard";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";
import ListWithSelectionNew from "../../Features/Lists/ListWithSelectionDashboard";
import GenericSelectRedux from "../../Features/Menu/GenericSelectRedux";
import AutocompleteDashboard from "../../Features/Autocomplete/AutocompleteDashboard";

import BasicTableDashboard from "../../Features/Table/AntDesign/BasicTableDashboard";
import Events from "../../Analytics/Events";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getConnectedBatteries } from "../../Api/Api";
import {
  postAddToCart,
  getCartBatteries,
  getSelectAllBatteries,
} from "../../Api/CartBatteriesApi";

import {
  getAlertTypeList,
  getTabCounts,
  getTableBatteries,
  // getBatteryPartnerList,
} from "../../Api/DashboardApi";
import {
  SetDashboardCategory,
  SetDashboardLocation,
  SetDashboardStatus,
  SetDashboardTabValue,
  SetDashboardAlertTypeCode,
  SetDashboardAlertTypeIndex,
  SetDashboardTotal,
  SetDashboardStartDate,
  SetDashboardEndDate,
  SetDashboardStack,
  SetDashboardPageNumber,
  SetDashboardColumns,
  SetDashboardBatteryPartnerSelect,
  SetDashboardBatteryID,
  SetDashboardPartner,
  SetDashboardSeverity,
  SetDashboardIOT,
  SetDashboardPack,
  DashboardExistingBatteries,
  SetDashboardOpenAddSelectedBatteries,
  SetDashboardSelectedBatteriesAction,
  SetDashboardTabCounts,
  SetDashboardOrganization,
} from "../../Actions/Dashboard";
import { SetSessionExpired } from "../../Actions";

import { theme } from "antd";

import useState from "react-usestateref";
import { useNavigate } from "react-router-dom";
import BatteriesInCart from "../../Features/Dialog/BatteriesInCart";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import {
  decryptTheParams,
  encryptTheParams,
  resetTheFilterValues,
  setTheFilterValues,
} from "../../Helper/QueryParams/EncryptDecrypt";
import RemoveSelectedBatteries from "../../Features/Dialog/RemoveSelectedBatteries";
import "../../Features/Toast/NewNotification.css";
import GenericMultipleSelect from "../../Features/Menu/GenericMultipleSelect";
import CODE from "../../Static/Constants/StatusCodes";
import { areArraysEqual } from "../../Helper/Operations/ArrayOps";
import AlertHeaderIcons from "../../PagesContent/Dashboard/AlertHeaderIcons";
import AntDatePicker from "../../Helper/DatePicker/AntDatePicker";
import GetDashboardTabs from "../../Static/Data/Dashboard/DashboardTabs";
import { getDatePickerDate } from "../../Helper/DatePicker/DateConverter";
import PageHeaderKPI from "../../Features/PageHeader/PageHeaderKPI";
import { dashboardNonClickablekpis } from "../../Static/Data/Dashboard/DashboardKPIS";

const Context = React.createContext({
  name: "Default",
});

const { useToken } = theme;

function DashboardMain(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useToken();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);
  const universal = useSelector((state) => state.DashboardStack.arr);
  const orderChangedTo = useSelector((state) => state.CustomOrder.orderBy);
  const sortChangedTo = useSelector((state) => state.DashboardColumnSort.arr);
  const filters = useSelector((state) => state.DashboardFilters);
  const alertTypeIndexRedux = useSelector(
    (state) => {
      return state.DashboardAlertTypeIndex.value
    }
  );

  const rowsPerPageCart = useSelector(
    (state) => state.StackTableRowsPerPage.value
  );
  const orderChangedToCart = useSelector(
    (state) => state.StackTableOrder.value
  );
  const sortChangedToCart = useSelector((state) => state.StackTableSort.value);

  const [resetButton, setResetButton] = useState(false);
  // const [existingBatteries, setExistingBatteries] = useState([]);

  const tabvalue = useSelector((state) => state.DashboardTabValue.value);
  const tabCounts = useSelector((state) => state.DashboardTabCounts);
  const alertCode = useSelector((state) => state.DashboardAlertTypeCode.value);
  const total = useSelector((state) => state.DashboardTotal.value);
  const indexDashboardAlertType = useSelector(
    (state) => state.DashboardAlertTypeIndex.value
  );

  const [reduxCountItemIds, setReduxCountItemIds] = useState(0);
  const [connectedBatteries, setConnectedBatteries] = useState(0);
  const [batteries, setBatteries] = useState({
    batteries: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [alertTypeList, setAlertTypeList] = useState({
    list: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [listOfBatteries, setListOfBatteries] = useState({
    batteries: [],
    responseStatus: { code: -1, msg: "" },
  });

  const batteryPartnerSelect = useSelector(
    (state) => state.DashboardBatteryPartnerSelect.value
  );
  const batteryID = useSelector((state) => state.DashboardBatteryID.value);
  const partner = useSelector((state) => state.DashboardPartner.value);

  const organization = useSelector(
    (state) => state.DashboardOrganization.value
  );
  const category = useSelector((state) => state.DashboardCategory.value);
  const location = useSelector((state) => state.DashboardLocation.value);
  const batterystatus = useSelector((state) => state.DashboardStatus.value);
  const severity = useSelector((state) => state.DashboardSeverity.value);
  const iotVendor = useSelector((state) => state.DashboardIOT.value);
  const product = useSelector((state) => state.DashboardPack.value);

  const priority = "";

  const reduxStartDate = useSelector((state) => state.DashboardStartDate.value) || getDatePickerDate(undefined, -45);
  const reduxEndDate = useSelector((state) => state.DashboardEndDate.value) || getDatePickerDate();
  // const [open, setOpen] = useState(false);
  const newColumns = useSelector((state) => state.DashboardColumns.value);

  // const columnHide = useSelector((state) => state.DashboardHiddenColumns.value);

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };
  const [cartBatteriesCount, setCartBatteriesCount] = useState(0);

  const [mounted, setMounted] = useState(false);
  const [mountedCartBatteries, setMountedCartBatteries] = useState(false);

  const filters2 = {
    Organization: organization || [],
    Severity: severity || [],
    Status: batterystatus || [],
    Location: location || [],
    IOT: iotVendor || [],
    ProductID: product || [],
  };

  const pageNumber = useSelector((state) => state.DashboardPageNumber.value);
  const rowsPerPage = useSelector((state) => state.DashboardRowsPerPage.value);
  const openRemoveSelectedBatteries = useSelector(
    (state) => state.DashboardOpenAddSelectedBatteries.value
  );
  const selectedBatteriesAction = useSelector(
    (state) => state.DashboardSelectedBatteriesAction.value
  );

  const [openBatteryStackDialog, setOpenBatteryStackDialog] = useState(false);
  const [addToCartInProgress, setAddToCartInProgress] = useState(false)

  const handleOpenBatteryStackDialog = (event) => {
    if (cartBatteriesCount + reduxCountItemIds > 0) {
      addToCart();
      setOpenBatteryStackDialog(true);
      Events("clicked Selected Batteries For Action");
    }
  };
  const handleCloseBatteryStackDialog = () => {
    setOpenBatteryStackDialog(false);
  };

  async function GetAlertTabs() {
    await getTabCounts(reduxStartDate, reduxEndDate).then((res) => {
      if (res?.responseStatus?.code === 200) {
        dispatch(SetDashboardTabCounts(res.response.data));
      } else if (res?.responseStatus?.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
  }

  const ChangeTheTab = (newValue) => {
    let itemIDS = [];
    universal.forEach((item) => itemIDS.push(...item.itemIDs));

    if (itemIDS?.length > 0) {
      dispatch(SetDashboardOpenAddSelectedBatteries(true));
    } else {
      let temp;
      setMounted(false);
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: -1, msg: "" },
      });
      setAlertTypeList({
        list: [],
        responseStatus: { code: -1, msg: "" },
      });
      dispatch(SetDashboardTabValue(newValue));
      dispatch(SetDashboardPageNumber(1));

      if (newValue === 0) {
        Events(`clicked Alerts Active Alerts`);
      } else if (newValue === 1) {
        Events(`clicked Alerts Open Alerts`);
      } else if (newValue === 2) {
        Events(`clicked Alerts Resolved Alerts`);
      } else if (newValue === 3) {
        Events(`clicked Alerts Ignored Alerts`);
      } else if (newValue === 4) {
        Events(`clicked Alerts Expired Alerts`);
      }
      const queryParams = decryptTheParams();
      temp = {
        ...queryParams,
        tabValue: newValue,
      };
      encryptTheParams(temp, navigate);
    }
  };

  function handleSetSelect(name, value) {
    let temp;
    const queryParams = decryptTheParams();

    temp = {
      ...queryParams,
      batteryPartnerSelect: value,
      batteryID: "",
      partner: "",
    };
    dispatch(SetDashboardBatteryPartnerSelect(value));
    dispatch(SetDashboardBatteryID(""));
    dispatch(SetDashboardPartner(""));
    if (batteryID !== "" || partner !== "") resetTheFilters();

    encryptTheParams(temp, navigate);
  }
  function handleSetFilter(filterName, value) {
    setTheFilterValues(filterName, value, navigate);
    dispatch(SetDashboardPageNumber(1));
    if (filterName === "Organization") {
      dispatch(SetDashboardOrganization(value));
    } else if (filterName === "Alert Type") {
      dispatch(SetDashboardCategory(value));
    } else if (filterName === "Location") {
      dispatch(SetDashboardLocation(value));
    } else if (filterName === "Status") {
      dispatch(SetDashboardStatus(value));
    } else if (filterName === "Severity") {
      dispatch(SetDashboardSeverity(value));
    } else if (filterName === "IOT") {
      dispatch(SetDashboardIOT(value));
    } else if (filterName === "ProductID") {
      dispatch(SetDashboardPack(value));
    }
  }

  function resetTheFilters(page = null) {

    dispatch(SetDashboardOrganization(filters?.fleetName?.map((i) => i.value)));
    dispatch(SetDashboardLocation(filters?.location?.map((i) => i.value)));
    dispatch(SetDashboardStatus(filters?.batteryStatus?.map((i) => i.value)));
    dispatch(SetDashboardSeverity(filters?.severityName?.map((i) => i.value)));
    dispatch(SetDashboardIOT(filters?.iotOEM.map((i) => i.value)));
    dispatch(SetDashboardPack(filters?.cellModelNumber.map((i) => i.value)));

    dispatch(SetDashboardEndDate(getDatePickerDate()));
    dispatch(SetDashboardPageNumber(1));

    resetTheFilterValues(props.pagesContent.filteredArray, navigate);
  }
  const removeSelection = () => {
    const stackArr = alertTypeList?.list?.map((codeItem) => {
      return {
        alertCode: codeItem?.alertCode,
        alertDescription: codeItem?.alertDescription,
        itemIDs: [],
      };
    });
    dispatch(SetDashboardStack(stackArr));
    dispatch(SetDashboardSelectedBatteriesAction(""));
  };
  const addToCart = () => {
    setAddToCartInProgress(true)
    removeSelection();
    setBatteries({
      batteries: [],
      responseStatus: { code: -1, msg: "" },
    });

    let itemIDS = [];
    universal.forEach((item) => itemIDS.push(...item.itemIDs));
    if (itemIDS.length > 0) {
      postAddToCart(itemIDS)
        .then((res) => {
          if (res.responseStatus.code === 200) {
            dispatch(SetDashboardStack([]));
          } else if (res.responseStatus.code === -2) {
            dispatch(SetSessionExpired(true));
          }
        })
        .then(() => {
          getCartBatteries(
            1,
            sortChangedToCart,
            orderChangedToCart,
            rowsPerPageCart
          ).then((res) => {
            let items = [];
            if (res.responseStatus.code === 200) {
              res.response.data.forEach((item) => {
                items.push(item.id);
              });
              dispatch(DashboardExistingBatteries(items));
              setBatteries({
                batteries: res.response.data,
                total: res.response.total.total,
                responseStatus: {
                  code: res.responseStatus.code,
                  msg: res.responseStatus.msg,
                },
              });
              removeSelection();
            } else {
              if (res.responseStatus.code === -2)
                dispatch(SetSessionExpired(true));

              dispatch(DashboardExistingBatteries([]));
              setBatteries({
                batteries: [],
                responseStatus: {
                  code: res.responseStatus.code,
                  msg: "",
                },
              });
            }

            setCartBatteriesCount(items?.length);
            setAddToCartInProgress(false)
          });
        });
    } else {
      setAddToCartInProgress(false)
    }
    dispatch(SetDashboardSelectedBatteriesAction(""));
  };

  const SetCountForCart = (type) => {
    setMountedCartBatteries(false);

    getSelectAllBatteries()
      .then((res) => {
        if (res?.responseStatus?.code === 200) {
          dispatch(DashboardExistingBatteries(res?.response.data));
          setCartBatteriesCount(res?.response?.data?.length);
        } else {
          if (res?.responseStatus.code === -2)
            dispatch(SetSessionExpired(true));
          setCartBatteriesCount(0);
          dispatch(DashboardExistingBatteries([]));
        }

        setMountedCartBatteries(true);
      })
      .then(() => {
        if (type === "empty") {
          dispatch(DashboardExistingBatteries([]));
        }
      });
  };

  function changeTheDate(startDate, endDate) {
    let temp;
    const queryParams = decryptTheParams();

    dispatch(SetDashboardPageNumber(1));
    if (startDate === null && endDate === null) {
      dispatch(SetDashboardStartDate(""));
      dispatch(SetDashboardEndDate(""));
      temp = {
        ...queryParams,
        startDate: "",
        endDate: "",
      };
    } else if (endDate === null) {
      dispatch(SetDashboardStartDate(startDate));
      dispatch(SetDashboardEndDate(startDate));
      temp = {
        ...queryParams,
        startDate: startDate,
        endDate: startDate,
      };
    } else if (startDate === null) {
      dispatch(SetDashboardStartDate(endDate));
      dispatch(SetDashboardEndDate(endDate));
      temp = {
        ...queryParams,
        startDate: endDate,
        endDate: endDate,
      };
    } else {
      dispatch(SetDashboardStartDate(startDate));
      dispatch(SetDashboardEndDate(endDate));
      temp = {
        ...queryParams,
        startDate: startDate,
        endDate: endDate,
      };
    }
    encryptTheParams(temp, navigate);
  }

  function GetAlertTypes() {
    let queryParams = decryptTheParams();

    getAlertTypeList(
      tabvalue,
      organization,
      category,
      location,
      batterystatus,
      severity,
      iotVendor,
      product,
      batteryID,
      reduxStartDate,
      reduxEndDate,
      props.pagesContent.batteryType,
      queryParams
    ).then((res) => {
      if (res && res?.responseStatus?.code === 200) {
        let finalArr =
          category === "" || category === "all"
            ? res.response?.data
            : res.response?.data?.filter(
              (x) => x.alertType.toLowerCase() === category?.toLowerCase()
            ) ?? [];

        finalArr = finalArr?.map((item) => {
          let color = "";
          let textColor = ThemeProperties.black;
          switch (item["alertType"].charAt(0).toLowerCase()) {
            case "s":
              color = ThemeProperties.safety_1;
              textColor = ThemeProperties.white;
              break;
            case "o":
              color = ThemeProperties.operational_1;
              textColor = ThemeProperties.black;
              break;
            case "v":
              color = ThemeProperties.violation_1;
              textColor = ThemeProperties.black;
              break;
          }
          return { ...item, color: color, textColor: textColor };
        });

        setAlertTypeList({
          list: finalArr,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });

        if (alertCode !== "") {
          if (indexDashboardAlertType === 0 && finalArr?.length > 0) {
            if (finalArr[0]["alertCode"] !== alertCode) {
              dispatch(SetDashboardAlertTypeIndex(finalArr[0]["id"]));
              dispatch(SetDashboardAlertTypeCode(finalArr[0]["alertCode"]));
              dispatch(SetDashboardTotal(finalArr[0]["alertCount"]));
            }
          } else {
            var result = finalArr.find((item) => item.alertCode === alertCode);

            if (result === undefined) {
              dispatch(SetDashboardAlertTypeIndex(0));
              dispatch(
                SetDashboardAlertTypeCode(
                  finalArr?.length > 0 ? finalArr[0]["alertCode"] : ""
                )
              );
              dispatch(
                SetDashboardTotal(
                  finalArr?.length > 0 ? finalArr[0]["alertCount"] : ""
                )
              );
              dispatch(SetDashboardAlertTypeIndex(finalArr?.length > 0 ? finalArr[0]["id"] : 0));
            } else {
              dispatch(SetDashboardTotal(result["alertCount"]));
              dispatch(SetDashboardAlertTypeIndex(result["id"]));
            }
          }
        } else {
          dispatch(
            SetDashboardAlertTypeCode(
              finalArr?.length > 0 ? finalArr[0]["alertCode"] : ""
            )
          );
          dispatch(
            SetDashboardTotal(
              finalArr?.length > 0 ? finalArr[0]["alertCount"] : ""
            )
          );
          dispatch(SetDashboardAlertTypeIndex(finalArr?.length > 0 ? finalArr[0]["id"] : 0));
        }

        const stackArr = finalArr.map((codeItem) => {
          return {
            alertCode: codeItem?.alertCode,
            alertDescription: codeItem?.alertDescription,
            itemIDs: [],
          };
        });
        dispatch(SetDashboardStack(stackArr));
      } else {
        if (res?.responseStatus?.code === -2) dispatch(SetSessionExpired(true));
        setAlertTypeList({
          list: [],
          responseStatus: {
            code: res?.responseStatus.code,
            msg: res?.responseStatus.msg,
          },
        });
      }
    }).catch((error) => {
      alertTypeList.responseStatus.code !== -1 &&
        setAlertTypeList({
          list: [],
          responseStatus: { code: -1, msg: "" },
        });
    });
  }

  function getListOfBatteries() {
    let queryParams = decryptTheParams();
    listOfBatteries.responseStatus.code !== -1 &&
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: -1, msg: "" },
      });
    if (alertCode === "") {
      setListOfBatteries({
        batteries: [],
        responseStatus: {
          code: alertTypeList.responseStatus.code,
          msg: "No Data Found",
        },
      });
    } else {
      alertTypeList.responseStatus.code !== -1 &&
        getTableBatteries(
          alertTypeIndexRedux,
          tabvalue,
          alertCode,
          organization,
          category,
          location,
          batterystatus,
          severity,
          iotVendor,
          product,
          priority,
          batteryID,
          partner,
          reduxStartDate,
          reduxEndDate,
          pageNumber,
          sortChangedTo[tabvalue]["sortBy"],
          orderChangedTo,
          rowsPerPage,
          props.pagesContent.batteryType,
          queryParams,
          filters
        ).then((res) => {
          if (res?.responseStatus?.code === 200) {
            const updatedData = res.response.data.map(item => {
              const id = `${item.batteryID}-${item.alertStatus}-${alertTypeIndexRedux}`.toLowerCase().replace(/ /g, "");
              return { ...item, id };
            });

            setListOfBatteries({
              batteries: updatedData,
              responseStatus: {
                code: res.responseStatus.code,
                msg: res.responseStatus.msg,
              },
            });

            if (batteryID !== "") {
              let temp;
              temp = {
                ...queryParams,
                batteryID: res.response.data[0]["batteryID"],
                type: "battery",
              };
              encryptTheParams(temp, navigate);
            }
          } else {
            if (res?.responseStatus?.code === -2)
              dispatch(SetSessionExpired(true));

            setListOfBatteries({
              batteries: [],
              responseStatus: {
                code: res?.responseStatus?.code,
                msg: res?.responseStatus?.msg,
              },
            });
          }
        });
    }
    setMounted(true);
  }

  function FilterBox() {
    const filtersArr = props.pagesContent?.filtersWithData?.map(
      ({ itemName, items }, index) => {
        const itemArr =
          items?.map(({ key, value }) => {
            return { id: value, value: key };
          }) ?? [];
        return { itemName: itemName, items: itemArr };
      }
    );

    return (
      <>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <GenericSelectRedux
                filter={props.pagesContent?.searchTypeFilter}
                handleSetFilter={handleSetSelect}
                selected={batteryPartnerSelect}
                maxWidth={"max-content"}
                showClearButton={false}
                id="batteryState"
              />
              <AutocompleteDashboard
                width={300}
                onClearSearch={resetTheFilters}
                tabValue={tabvalue}
                alertCode={props.pagesContent.alertType?.find(
                  (o) => o.itemName === "All"
                )}
                page={"Dashboard"}
                openNotification={openNotification}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={9}
            xl={9}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-end",
                xl: "flex-end",
              },
            }}
          >
            <Box className="filterDateRange">
              <Box sx={{ display: "flex" }}>
                {resetButton && (
                  <Button
                    sx={{ fontSize: "12px", height: "30px", mt: 1.5, mr: 0.5 }}
                    onClick={() => resetTheFilters()}
                  >
                    Reset
                  </Button>
                )}

                {filtersArr?.map(({ itemName, items }, index) => {
                  return (
                    <Box key={index}>
                      <GenericMultipleSelect
                        label={itemName}
                        options={items}
                        maxWidth={"100px"}
                        optionValue={filters2[itemName] ?? []}
                        handleSetFilter={handleSetFilter}
                        id="overflow-text-3"
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  function showResetButton() {
    if (
      !areArraysEqual(
        location,
        filters?.location?.map((i) => i.value)
      ) ||
      !areArraysEqual(
        batterystatus,
        filters?.batteryStatus?.map((i) => i.value)
      ) ||
      !areArraysEqual(
        severity,
        filters?.severityName?.map((i) => i.value)
      ) ||
      !areArraysEqual(
        organization,
        filters?.fleetName?.map((i) => i.value)
      ) ||
      !areArraysEqual(
        iotVendor,
        filters?.iotOEM?.map((i) => i.value)
      ) ||
      !areArraysEqual(
        product,
        filters?.cellModelNumber?.map((i) => i.value)
      )
    ) {
      setResetButton(true);
    } else {
      setResetButton(false);
    }
  }

  const SetTheAlertTypeList = (data) => {
    let obj =
      category === ""
        ? props.pagesContent.alertType.find((o) => o.itemName === "All")
        : props.pagesContent.alertType.find((o) => o.itemName === category);
    let newArr = obj.items;

    if (data.responseStatus.code === 200) {
      let finalArr = [];
      newArr.forEach((item, index) => {
        let tempObj = data.response.alertList.find(
          (o) => o.alertCode === item.alertCode
        );
        item.alertCount = tempObj === undefined ? 0 : tempObj.alertCount;
      });

      finalArr = newArr.filter((item) => item["alertCount"] > 0);

      setAlertTypeList({
        list: finalArr,
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
      if (alertCode !== "") {
        if (indexDashboardAlertType === 0) {
          if (finalArr[0]["alertCode"] !== alertCode) {
            dispatch(SetDashboardAlertTypeCode(finalArr[0]["alertCode"]));
            dispatch(SetDashboardTotal(finalArr[0]["alertCount"]));
          } else {
            dispatch(SetDashboardTotal(finalArr[0]["alertCount"]));
          }
        } else {
          var result = finalArr.find((item) => item.alertCode === alertCode);
          var index = finalArr.findIndex(
            (item) => item.alertCode === alertCode
          );

          if (result === undefined) {
            dispatch(SetDashboardAlertTypeIndex(0));
            dispatch(SetDashboardAlertTypeCode(finalArr[0]["alertCode"]));
            dispatch(SetDashboardTotal(finalArr[0]["alertCount"]));
          } else {
            dispatch(SetDashboardTotal(result["alertCount"]));
            dispatch(SetDashboardAlertTypeIndex(index));
          }
        }
      } else {
        dispatch(SetDashboardAlertTypeCode(finalArr[0]["alertCode"]));
        dispatch(SetDashboardTotal(finalArr[0]["alertCount"]));
      }
    } else {
      if (data.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      dispatch(SetDashboardTotal(newArr[0]["alertCount"]));
      setAlertTypeList({
        list: [],
        responseStatus: {
          code: data.responseStatus.code,
          msg: data.responseStatus.msg,
        },
      });
    }
  };

  useEffect(() => {
    getConnectedBatteries().then((res) => {
      if (res?.responseStatus?.code === 200) {
        setConnectedBatteries(res.response.data[0]["count"]);
      } else if (res?.responseStatus?.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pagesContent.batteryType]);

  useEffect(() => {
    GetAlertTabs();
  }, [tabvalue, reduxEndDate, reduxStartDate, timezoneChanged]);


  useEffect(() => {
    GetAlertTypes();

    showResetButton();
  }, [
    tabvalue,
    reduxStartDate,
    reduxEndDate,
    batteryID,
    organization,
    severity,
    category,
    location,
    batterystatus,
    iotVendor,
    product,
    partner,
    props.pagesContent.batteryType,
    timezoneChanged
  ]);

  useEffect(() => {
    setMounted(false);
    const tempColumns = [...newColumns];
    const newOrderChangedTo = orderChangedTo === "desc" ? "descend" : "ascend";
    tempColumns[tabvalue]["columns"].forEach((column, i) => {
      if (
        column.key === sortChangedTo[tabvalue]["sortBy"] &&
        column.sorter === true
      ) {
        column.defaultSortOrder = newOrderChangedTo;
      } else {
        column.defaultSortOrder = "";
      }
    });
    dispatch(SetDashboardColumns(tempColumns));

    if (
      severity?.length > 0 &&
      batterystatus?.length > 0 &&
      location?.length > 0 &&
      iotVendor?.length > 0 &&
      product?.length > 0
    ) {
      getListOfBatteries();
    } else {
      setMounted(true);
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: 404, msg: "" },
      });
    }
  }, [
    alertTypeList,
    alertCode,
    sortChangedTo[tabvalue]["sortBy"],
    orderChangedTo,
    rowsPerPage,
  ]);

  useEffect(() => {
    let itemIDS = [];
    universal.forEach((item) => itemIDS.push(...item.itemIDs));

    setReduxCountItemIds(itemIDS?.length);
    SetCountForCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universal]);

  useEffect(() => {
    if (selectedBatteriesAction === "Add") {
      addToCart();
    }
    if (selectedBatteriesAction === "Remove") {
      removeSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBatteriesAction]);

  // useEffect(() => {
  //   if (batteryID !== "" || partner !== "") {
  //     openNotification("bottomLeft", "Cleared all filters if selected");
  //   }
  //   showResetButton();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [batteryID, partner]);

  return (
    <div id="alerts-dashboard-main">
      <OuterBox>
        <InnerBox>
          <Context.Provider value={contextValue}>
            {contextHolder}
            <PageHeaderKPI
              name={"Alerts"}
              nonClickableKpis={dashboardNonClickablekpis}
              connectedBatteries={connectedBatteries}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pt: 1.5
              }}
            >
             <AlertHeaderIcons
                cartCount={cartBatteriesCount + reduxCountItemIds}
                openBatteryStackDialog={handleOpenBatteryStackDialog}
              />
            </Box>
            <Box sx={{ mt: 1, position: "relative" }}>
              <Box sx={{ position: "relative" }}>
                <Box sx={{ position: "absolute", right: 0, zIndex: 1, top: 5 }}>
                  <AntDatePicker
                    changeTheDate={changeTheDate}
                    date={[reduxStartDate, reduxEndDate]}
                    allowClear={true}
                  />
                </Box>

                <TabWithColorDashboard
                  ChangeTheTab={ChangeTheTab}
                  tabs={GetDashboardTabs(tabCounts)}
                />

                {GetDashboardTabs(tabCounts).map((item, index) => (
                  <BasicTabPanel
                    style={{
                      marginTop: "10px"
                    }}
                    value={tabvalue} index={index} key={index}>
                    <Paper
                      elevation={0}
                      sx={{
                        height: "100%",
                        borderRadius: "0px 12px 12px",
                        p: 1,
                        border: "1px solid #ececec",
                        minWidth: "800px",
                        overflowX: "scroll",
                      }}
                    >
                      {FilterBox()}
                      <hr style={{ borderTop: "1px solid #ececec" }}></hr>
                      <Box sx={{ mt: 1.5 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                            <ListWithSelectionNew
                              data={alertTypeList}
                              batteryType={props.pagesContent.batteryType}
                              handleSetFilter={handleSetFilter}
                              alertType={props.pagesContent.alertType}
                              category={category}
                            />
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                            {mounted && (
                              <BasicTableDashboard
                                alertTypeIndexRedux={alertTypeIndexRedux}
                                data={listOfBatteries}
                                total={total}
                                columns={newColumns}
                                rowSelection={
                                  tabvalue === 0
                                    ? true
                                    : tabvalue === 1
                                      ? true
                                      : false
                                }
                                extraStatus={alertTypeList.responseStatus.code}
                                location={location}
                                organization={organization}
                                category={category}
                                alertCode={alertCode}
                                batterystatus={batterystatus}
                                iotVendor={iotVendor}
                                product={product}
                                severity={severity}
                                tabvalue={tabvalue}
                                priority={priority}
                                batteryState={""}
                                date={[reduxStartDate, reduxEndDate]}
                                batteryID={batteryID}
                                partner={partner}
                                batteryType={props.pagesContent.batteryType}
                                getListOfBatteries={getListOfBatteries}
                                openNotification={openNotification}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </BasicTabPanel>
                ))}
              </Box>
            </Box>
          </Context.Provider>
        </InnerBox>
      </OuterBox>
      {openBatteryStackDialog && (
        <>
          <BatteriesInCart
            // anchorElBatteryStackDialog={anchorElBatteryStackDialog}
            batteries={batteries}
            openBatteryStackDialog={openBatteryStackDialog}
            handleCloseBatteryStackDialog={handleCloseBatteryStackDialog}
            SetCountForCart={SetCountForCart}
            setCartBatteriesCount={setCartBatteriesCount}
            alertTypeList={alertTypeList?.list}
            addToCartInProgress={addToCartInProgress}
            getALertType={GetAlertTypes}
            getALertTabs={GetAlertTabs}
          // getListOfBatteries={getListOfBatteries}
          />
        </>
      )}

      <RemoveSelectedBatteries
        openRemoveSelectedBatteries={openRemoveSelectedBatteries}
        // handleCloseRemoveSelectedBatteries={handleCloseRemoveSelectedBatteries}
        addToCart={addToCart}
        removeSelection={removeSelection}
      />
    </div>
  );
}

export default DashboardMain;
