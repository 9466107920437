import React, { useEffect, useMemo, useState } from "react";
import {
  SetDigitalTwinsPageNumber,
  SetDigitalTwinsTabValue,
  SetDigitalTwinsTotal,
  SetDigitalTwinsBatteryID,
  SetDigitalTwinsColumnsSelect,
  SetDigitalTwinsListOfBatteries,
} from "../../Actions/DigitalTwinsList";
import PageHeaderKPI from "../../Features/PageHeader/PageHeaderKPI";
import { DigitalTwinListNonClickablekpis } from "../../Static/Data/DigitalTwinList/DigitalTwinKPIS";
import {
  Box,
  Paper,
  Typography,
  Grid,
  MenuItem,
} from "@mui/material";
import { notification } from "antd";
import { SecondaryButton } from "../../Components/CustomButton";
import { InnerBox, OuterBox } from "../../Components/CustomBox";

import { useDispatch, useSelector } from "react-redux";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";
import AutocompleteDashboard from "../../Features/Autocomplete/AutocompleteDashboard";
import columnsIcon from "../../Assets/Icons/columnsIcon.svg";

import BasicTableDigitalTwinsList from "../../Features/Table/AntDesign/BasicTableDigitalTwinsList";
import TabWithColorDigitalTwins from "../../Features/Tabs/TabWithColorDigitalTwins";
import { BasicSelect } from "../../Components/CustomSelect";
import AddBatteryDialog from "../../Features/Dialog/DigitalTwin/AddBatteryConfigDialog";
import Events from "../../Analytics/Events";
import {
  getDigitalTwinConfigList,
  getDigitalTwinList,
} from "../../Api/DigitalTwinListApi";
import { getConnectedBatteries } from "../../Api/Api";
import { SetSessionExpired } from "../../Actions";
import GenericFilterContainer from "../../Features/Menu/GenericFilterContainer";

const Context = React.createContext({
  name: "Default",
});

export default function DigitalTwinsListMain(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxStartDate = useSelector(
    (state) => state.DigitalTwinsStartDate.value
  );
  const reduxEndDate = useSelector((state) => state.DigitalTwinsEndDate.value);
  const [api, contextHolder] = notification.useNotification();
  const tabvalue = useSelector((state) => state.DigitalTwinsTabValue.value);

  const [mounted, setMounted] = useState(true);
  const [connectedBatteries, setConnectedBatteries] = useState(0);
  const selectedFilterValues = useSelector((state) => state.SelectedFilterValues.value);

  const [listOfBatteries, setListOfBatteries] = useState({
    batteries: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [digitalTwinsCount, setDigitalTwinsCount] = useState(null);
  const [digitalTwinConfigsCount, setDigitalTwinConfigsCount] = useState(null);

  const newColumns = useSelector((state) => state.DigitalTwinsColumns.value);

  const batteryID = useSelector((state) => state.DigitalTwinsBatteryID.value);
  const org = useSelector((state) => state.DigitalTwinsOrg.value);
  const bmsOEM = useSelector((state) => state.DigitalTwinsBMS.value);
  const iotOEM = useSelector((state) => state.DigitalTwinsIOT.value);

  const columnsSelect = useSelector(
    (state) => state.DigitalTwinsColumnsSelect?.value
  );

  const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);
  const pageNumber = useSelector((state) => state.DigitalTwinsPageNumber.value);
  const rowsPerPage = useSelector(
    (state) => state.DigitalTwinsRowsPerPage.value
  );
  const sortChangedTo = useSelector(
    (state) => state.DigitalTwinsColumnSort.arr
  );

  useEffect(() => {
    GetListOfBatteries();
    if (!digitalTwinConfigsCount) {
      GetDigitalTwinConfigCount();
    }
  }, [
    tabvalue,
    rowsPerPage,
    pageNumber,
    sortChangedTo,
    selectedFilterValues,
    batteryID,
    reduxStartDate,
    reduxEndDate,
    timezoneChanged,
  ]);

  useEffect(() => {
    getConnectedBatteries().then((res) => {
      if (res.responseStatus.code === 200) {
        setConnectedBatteries(res.response.data[0]["count"]);
      } else if (res.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
  }, []);


  const openNotification = (placement, text) => {
    api.info({
      // message: `Notification ${placement}`,s
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };

  const columnSelectOptions = ["Diagnostic", "Static"];

  async function GetListOfBatteries() {
    listOfBatteries.responseStatus.code !== -1 &&
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: -1, msg: "" },
      });
    if (tabvalue === 0) {
      const sortBy = sortChangedTo[0]?.sortBy;
      const orderBy = sortChangedTo[0]?.orderBy;
      await getDigitalTwinList({
        filters: selectedFilterValues,
        pageNumber,
        rowsPerPage,
        sortBy,
        orderBy,
        batteryID,
        startDate: null,
        endDate: null,
      }).then((res) => {
        if (res.responseStatus.code === 200) {
          setListOfBatteries({
            batteries: res.response.data,
            responseStatus: res.response.responseStatus,
          });
          dispatch(SetDigitalTwinsListOfBatteries(res.response.data));
          dispatch(SetDigitalTwinsTotal(res.response.total?.total));
          setDigitalTwinsCount(res.response?.total?.total);
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          setListOfBatteries({
            batteries: [],
            responseStatus: {
              code: res.responseStatus?.code,
              msg: res.responseStatus?.msg,
            },
          });
          dispatch(SetDigitalTwinsTotal(0));
          setDigitalTwinsCount(0);
        }
      });
    }
    if (tabvalue === 1) {
      const sortBy = sortChangedTo[1]?.sortBy;
      const orderBy = sortChangedTo[1]?.orderBy;
      await getDigitalTwinConfigList({
        filters: selectedFilterValues,
        pageNumber,
        rowsPerPage,
        sortBy,
        orderBy,
      }).then((res) => {
        if (res.responseStatus.code === 200) {
          setListOfBatteries({
            batteries: res.response.data,
            responseStatus: res.responseStatus,
          });
          dispatch(SetDigitalTwinsListOfBatteries(res.response.data));
          dispatch(SetDigitalTwinsTotal(res.response.total?.total));
          setDigitalTwinConfigsCount(res.response?.total?.total);
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          setListOfBatteries({
            batteries: [],
            responseStatus: {
              code: res.responseStatus?.code,
              msg: res.responseStatus?.msg,
            },
          });
        }
      });
    }
  }

  const GetDigitalTwinConfigCount = async () => {
    let sortBy = sortChangedTo[1]?.sortBy;
    let orderBy = sortChangedTo[1]?.orderBy;
    await getDigitalTwinConfigList({
      filters: selectedFilterValues,
      pageNumber,
      rowsPerPage: 1,
      sortBy,
      orderBy,
    }).then((res) => {
      if (res.responseStatus.code === 200) {
        setDigitalTwinConfigsCount(res.response?.total?.total);
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      }
    });
    sortBy = sortChangedTo[0]?.sortBy;
    orderBy = sortChangedTo[0]?.orderBy;
    if (tabvalue === 1 && digitalTwinsCount === null) {
      await getDigitalTwinList({
        org,
        pageNumber: 1,
        rowsPerPage: 1,
        sortBy,
        orderBy,
        batteryID,
        startDate: null,
        endDate: null,
      }).then((res) => {
        if (res.responseStatus.code === 200) {
          setDigitalTwinsCount(res.response?.total?.total);
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
        }
      });
    }
  };

  function resetTheFilters() {
    dispatch(SetDigitalTwinsColumnsSelect(columnsSelect));
    dispatch(SetDigitalTwinsPageNumber(1));
  }

  const onChangeColumnSelect = (e) => {
    dispatch(SetDigitalTwinsColumnsSelect(e.target.value));
  };

  const FilterBox = () => {
    return (
      <>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
              {tabvalue === 0 && (
                <>
                  <AutocompleteDashboard
                    width={300}
                    onClearSearch={() =>
                      dispatch(SetDigitalTwinsBatteryID(""))
                    }
                    page={"DigitalTwins"}
                    openNotification={openNotification}
                  />
                  <BasicSelect
                    variant="outlined"
                    labelId="column-label"
                    value={columnsSelect}
                    notched={false}
                    label="Columns"
                    name="Columns"
                    size="small"
                    onChange={onChangeColumnSelect}
                    renderValue={(selected) => {
                      return (
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          <img src={columnsIcon} /> Columns: {selected}
                        </Typography>
                      );
                    }}
                  >
                    {columnSelectOptions.map((item, index) => (
                      <MenuItem value={item} name={item} key={index}>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 500,
                            fontFamily: "Roboto",
                          }}
                        >
                          {item}
                        </Typography>
                      </MenuItem>
                    ))}
                  </BasicSelect>
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={9}
            xl={9}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-end",
                xl: "flex-end",
              },
            }}
          >
            <Box className="filterDateRange">
              <GenericFilterContainer
                filtersOptions={props.pagesContent?.filtersOptions}
                filtersConfig={props.pagesContent?.filterConfigurations?.digitalTwinListTable?.filters}
                onSelectFilter={handleFilterSelection}
                defaultFilterValues={props.pagesContent.defaultFilterValues}
                activeTabValue={tabvalue}
                resetTheFilters={resetTheFilters}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const ChangeTheTab = (newValue) => {
    dispatch(SetDigitalTwinsTabValue(newValue));
    dispatch(SetDigitalTwinsPageNumber(1));
    let temp;
    setMounted(false);
    if (newValue === 0) {
      Events(`clicked Digital Twins Batteries`);
    } else if (newValue === 1) {
      Events(`clicked Digital Twins Configurations`);
    }
    const queryParams = decryptTheParams();
    temp = {
      ...queryParams,
      tabValue: newValue,
    };
    encryptTheParams(temp, navigate);
    setMounted(true);
  };

  const Tabs = [
    {
      id: 1,
      name: "All Digital Twins",
      count: digitalTwinsCount,
      color: ThemeProperties.red_400,
    },
    {
      id: 2,
      name: "All Battery Configurations",
      count: digitalTwinConfigsCount,
      color: ThemeProperties.green_400,
    },
  ];

  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFilterSelection = (key, label, values) => {
    Events("changed DigitalTwins List" + label);
  }

  return (
    <div>
      <OuterBox>
        <InnerBox>
          <Context.Provider value={contextValue}>
            {contextHolder}
            {/* HEADING */}
            <PageHeaderKPI
              name={"Digital Twin List"}
              nonClickableKpis={DigitalTwinListNonClickablekpis}
              connectedBatteries={connectedBatteries}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 0.5,
                mb: 4,
              }}
            >
              {/* Add New Twin Button */}
              {props.pagesContent.allowAddDigitalTwin && props?.permissions?.includes("add-twin") && tabvalue === 0 && (
                <Box sx={{ position: "absolute", right: 20, pt: 1, zIndex: 1 }}>
                  <SecondaryButton
                    variant="outlined"
                    color="primary"
                    sx={{ ml: 2, fontSize: 12 }}
                    onClick={() => {
                      tabvalue == 0
                        ? navigate("DigitalTwin/add-battery")
                        : setOpenDialog(true);
                    }}
                  >
                    {"Add New Twin"}
                  </SecondaryButton>
                </Box>
              )}
              {/* Add Config Button */}
              {props.pagesContent.allowAddBatteryConfiguration && props?.permissions?.includes("add-config") && tabvalue === 1 && (
                <Box sx={{ position: "absolute", right: 20, pt: 1, zIndex: 1 }}>
                  <SecondaryButton
                    variant="outlined"
                    color="primary"
                    sx={{ ml: 2, fontSize: 12 }}
                    onClick={() => {
                      tabvalue == 0
                        ? navigate("DigitalTwin/add-battery")
                        : setOpenDialog(true);
                    }}
                  >
                    {"Add New Config"}
                  </SecondaryButton>
                </Box>
              )}
              {/* TABS */}
              <TabWithColorDigitalTwins
                ChangeTheTab={ChangeTheTab}
                tabs={Tabs}
              />
              <br></br>
            </Box>
            {/* FILTERS */}
            <BasicTabPanel value={1} index={1} key={1}>
              <Paper
                elevation={0}
                sx={{
                  height: "100%",
                  borderRadius: "12px",
                  p: 1,
                  border: "1px solid #ececec",
                }}
              >
                {FilterBox()}
                <hr style={{ borderTop: "1px solid #ececec" }}></hr>
                <Box sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {mounted && (
                      <BasicTableDigitalTwinsList
                        data={listOfBatteries}
                        columns={newColumns}
                        rowSelection={false}
                        org={org}
                        iot={iotOEM}
                        bms={bmsOEM}
                        tabvalue={tabvalue}
                        // date={[reduxStartDate, reduxEndDate]}
                        batteryID={batteryID}
                        existingBatteries={[]}
                        openNotification={openNotification}
                        permissions={props?.permissions}
                      />
                    )}
                  </Grid>
                </Box>
              </Paper>
            </BasicTabPanel>
          </Context.Provider>
        </InnerBox>
      </OuterBox>
      <AddBatteryDialog
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </div>
  );
}
