import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import Loader from "../../Helper/Loaders/Loader"
import BatteryHealthMain from "./BatteryHealthMain"
import { usePageMeta } from "../../Hooks/usePageMeta"
import { BatteryHealthLoaderBox } from "../../Components/CustomBox"
import { SetBatteryHealthColumns } from "../../Actions/BatteryHealth"
import { NotificationProvider } from "../../Hooks/useNotification"

function BatteryHealth(props) {
  const dispatch = useDispatch()
  const { pagesContent } = usePageMeta("batteryHealth")

  useEffect(() => {
    dispatch(SetBatteryHealthColumns(pagesContent.columns || []))
    pagesContent["mounted"] = true
  }, [pagesContent])

  return (
    <div>
      {pagesContent?.mounted ? (
        <NotificationProvider>
          <BatteryHealthMain pagesContent={pagesContent} />
        </NotificationProvider>
      ) : (
        <BatteryHealthLoaderBox>
          <Loader />
        </BatteryHealthLoaderBox>
      )}
    </div>
  )
}

export default BatteryHealth
