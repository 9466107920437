import moment from "moment";
import momentTZ from "moment-timezone";
import Cookies from "universal-cookie";
import { formatDateNumeric } from "./DateFormatters";

const cookies = new Cookies();
const testTimezone = "Asia/Kolkata";

function sendTheConvertedDate(m, format) {
  if (testTimezone === cookies.get("timeZone")) return m.format(format);
  else return m.utc().format(format);
}

var ist_offset = -5.5 * 60;
// const timeZone = cookies.get("timeZone");

function UTCtoISTConverter(date) {
  var d = new Date(date);

  d.setMinutes(d.getMinutes() - ist_offset);

  return d;
}

function getChartTimeStamp(date) {
  var format = "YYYY-MM-DD hh:mm:ss A";
  var zone = cookies.get("timeZone");

  return moment.utc(date, "YYYY-MM-DD HH:mm:ss")?.tz(zone)?.format(format);
}

function getDatePickerDate(date, daysToAdd = 0) {
  var zone = cookies.get("timeZone");

  if (date !== undefined)
    return moment.utc(new Date(date))?.tz(zone)?.add(daysToAdd, "days")?.format("YYYY-MM-DD");
  else return moment.utc(new Date())?.tz(zone)?.add(daysToAdd, "days")?.format("YYYY-MM-DD");
}

function getMetricTimeStamp(date) {
  var format = "DD MMM'YY hh:mm A";
  var zone = cookies.get("timeZone");

  return moment.utc(date, "YYYY-MM-DD HH:mm:ss")?.tz(zone)?.format(format);
}

function convertToZoneDateNumeric(datestring) {
  return moment(convertZonetoUTCDate(datestring), "YYYY-MM-DD").toDate();
}

function convertToZoneDate(datestring) {
  return moment(convertZonetoUTCDate(datestring)).toDate();
}

function convertUTCtoZone(date, seconds) {
  var dString = date + " UTC";
  var g = new Date(
    (typeof dString === "string" ? new Date(dString) : dString).toLocaleString(
      "en-US",
      { timeZone: cookies.get("timeZone") }
    )
  );

  if (seconds) {
    var finalDate = moment(g).format("DD MMM'YY, hh:mm:ss A");
    if (finalDate === "Invalid date") return date;
    else return finalDate;
  } else {
    var finalDate2 = moment(g).format("DD MMM'YY, hh:mm A");
    if (finalDate2 === "Invalid date") return date;
    else return finalDate2;
  }
}

function convertUTCtoZoneYYYYMMDD(date) {
  var dString = date + " UTC";
  var g = new Date(
    (typeof dString === "string" ? new Date(dString) : dString).toLocaleString(
      "en-US",
      { timeZone: cookies.get("timeZone") }
    )
  );
  var finalDate = moment(g).format("YYYY-MM-DD HH:mm:ss");
  if (finalDate === "Invalid date") return date;
  else return finalDate;
}

function convertZonetoUTCDate(date) {
  var dString = date + " 00:00:00";

  var format = "YYYY-MM-DD";
  var zone = cookies.get("timeZone");

  var m = moment.tz(dString, format, zone);
  // console.log(m.utc().format(format))
  let convertedDate = sendTheConvertedDate(m, format);
  return convertedDate;
}

function convertZonetoUTCStart(date) {
  if (!date) {
    return null
  }

  var dString = date + " 00:00:00";

  var format = "YYYY-MM-DD HH:mm:ss";
  var zone = cookies.get("timeZone");

  var m = moment.tz(dString, format, zone);
  return m.utc().format(format);
}

function convertZonetoUTCEnd(date) {
  if (!date) {
    return null
  }

  var dString = date + " 00:00:00";
  var d = new Date(dString);
  d.setMinutes(d.getMinutes() + 24 * 60);

  var newDString = formatDateNumeric(d) + " 00:00:00";

  var format = "YYYY-MM-DD HH:mm:ss";
  var zone = cookies.get("timeZone");

  var m = moment.tz(newDString, format, zone).subtract(1,'seconds');
  return m.utc().format(format);
}

function convertAndGetTimeStampInRequiredFormat(date, format)
{
  if (!date) {
    return date
  }
  var zone = cookies.get("timeZone");

  return moment?.utc(date, 'YYYY-MM-DD HH:mm:ss')?.tz(zone)?.format(format)
}
function getCSVTimeStamp(date, format) {
  if (!date) {
    return date;
  }
  var zone = cookies.get("timeZone");

  return moment.utc(date, "DD MMM'YY, hh:mm:ss A").tz(zone).format(format);
}

const getCurrentTimestamp = (format="DD MMM'YY, hh:mm:ss A") => {
  var zone = cookies.get("timeZone");
  return moment.utc().tz(zone).format(format);
}


export {
  UTCtoISTConverter,
  convertUTCtoZone,
  convertUTCtoZoneYYYYMMDD,
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
  convertToZoneDate,
  convertToZoneDateNumeric,
  getChartTimeStamp,
  getMetricTimeStamp,
  getDatePickerDate,
  getCSVTimeStamp,
  getCurrentTimestamp,
  convertAndGetTimeStampInRequiredFormat
};
