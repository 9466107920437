export const SetFilterValues = (data = {}) => {
  return {
    type: 'selectedFilters',
    value: data,
  };
};

export const SetTableSortColumn = (data = {}) => {
  return {
    type: 'tableSortColumn',
    value: data,
  };
};

export const setTableSortOrder = (data = {}) => {
  return {
    type: 'tableSortOrder',
    value: data,
  };
};