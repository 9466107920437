import { PaperHighlight } from "../../Components/CustomPaper"
import GenericHeader from "../Header/GenericHeader"
import { CardBodyContainer } from "../../Components/CustomBox"

const CardWithHeader = ({
  pageLabel,
  title,
  subtitle,
  cardStyle,
  bodyStyle,
  iconElement,
  csvName,
  csvData,
  generateCsvData,
  onMouseEnter,
  openNotification,
  showCSVDownload,
  closeNotification,
  children
}) => {
  return (
    <PaperHighlight elevation={0} onMouseEnter={onMouseEnter} sx={{height: "540px", ...cardStyle}}>
      <GenericHeader
        iconElement={iconElement}
        heading={title}
        description={subtitle}
        pageLabel={pageLabel}
        csvName={csvName}
        csvData={csvData}
        showCSVDownload={showCSVDownload}
        generateCsvData={generateCsvData}
        openNotification={openNotification}
        closeNotification={closeNotification}
      />
      <CardBodyContainer sx={bodyStyle}>{children}</CardBodyContainer>
    </PaperHighlight>
  )
}

CardWithHeader.defaultProps = {
  pageLabel: "",
  title: "",
  subtitle: null,
  iconElement: null,
  csvName: "",
  showCSVDownload: false,
  cardStyle: {
    height: "540px",
  },
  openNotification: () => {},
  closeNotification: () => {},
  generateCsvData: () => {}
}

export default CardWithHeader
