const ThemeProperties = {
  //table
  table_row: "#5c5c5c",
  // tabs
  purple_tabs: "rgba(118, 141, 254, 0.25)",

  // colors
  purple: "#4D44D8", //7856FF 463dbb
  purpleSelected: "#cac7f6", //"#E2E3FF",
  backgroundPurple: "#F2F3FB",
  backgroundPurpleDarker: "#ececec", //"#f3f1ff",
  hoverPurple: "#EEEFFF",
  grey: "#e8e8e8",
  lightGrey: "#ececec",
  darkGreyBlue: "#8D9FB2",
  white: "#fff",
  black: "#000",
  dark_red: "#FF6364",
  dark_yellow: "rgba(244, 196, 48,0.9)",
  dark_orange: "rgba(255, 140, 0,1)",
  light_yellow: "#FFEFC1",

  red_arrow_bg: "#fcebed",
  red_100: "#ffcdd2",
  blue_100: "#bbdefb",
  green_100: "#dcedc8", //mui light green
  pink_100: "#f8bbd0",
  blueGrey_100: "#cfd8dc",
  darkerGrey: "#D9D9D9",

  red_400: "#ef5350",
  blue_400: "#42a5f5",
  green_400: "#9ccc65", //mui dark green

  greyBackground: "#F8F9FA",
  mainTextColor: "#000", //#22105C
  greyTextColor: "#606060",
  dark_grey: "#C0C0C0",

  //gradient
  bluePurpleGradient:
    "linear-gradient(170.6deg, #FFFFFF -20.37%, #92E7FF -10.24%, #45D5DF 2.76%, #49BDDD 5.93%, #4f44e0 79.42%, #4f44e0 103.93%, #4f44e0 116.43%)",

  // font family
  primaryFont: "Helvetica",
  secondaryFont: "Inter",

  //Specifications
  borderRadius: "20px",

  //charts colors
  c_labelColor: "#22105C",
  c_red: "#FF7557",
  c_cyan: "#80E1D9",
  c_cyan1: "#99e7e1",
  c_yellow: "#F8BC3B",
  c_brown: "#B2596E",

  c_blue: "#72BEF4",

  c_skin: "#FFB27A",
  c_ocean: "#0D7EA0",
  c_gray: "#E9E9E9",

  c_green: "#3BA974",
  c_lightGreen: "#68bb59",
  c_pink: "#FEBBB2",

  c_purple: "#CA80DC",

  c_teal: "#5BB7AF",
  c_orange: "#ff8b3d",

  //Battery Status
  Idle: "#BDEAF8",
  Error: "#D64174",
  Discharging: "#7BCB12",
  Connected_to_charger: "#E68B19",
  Charging: "#FFE98A",
  Unknown: "#BDC9D6",

  //Entities
  Current: "#4f44e0",
  Voltage: "#FF7557",
  SOC: "#F8BC3B",
  Max_Temperature: "#229087",
  SOH: "#CA80DC",
  Equivalent_Cycles: "#B2596E",
  Diff_Voltage: "#0876CC",
  Min_Voltage: "#DD731C",
  Capacity: "#6C3483",
  Power: "#C8CD37",
  Energy: "#0000FF",

  //Alerts - darker to lighter
  Alert1: "#DD731D",
  Alert2: "#F1842B",
  Alert3: "#F6A25D",
  Alert4: "#FFD5B3",
  Alert5: "#FFEFE1",

  //Errors - darker to lighter
  Error1: "#D64174",
  Error2: "#E4567B",
  Error3: "#EC7191",
  Error4: "#FE9DB6",
  Error5: "#FFDBE4",

  //Purple - darker to lighter
  Purple1: "#6157e3",
  Purple2: "#7269e6",
  Purple3: "#847ce9",
  Purple4: "#958fec",
  Purple5: "#a7a2f0",
  Purple6: "#b9b4f3",

  Anomaly1: "#D95D67",
  Anomaly2: "#e17d85",
  Anomaly3: "#e89ea4",
  Anomaly4: "#f0bec2",
  Anomaly5: "#f7dfe1",

  //charts fontSizes
  c_axis_title: "14px",
  c_axis_title_weight: 600,
  c_axis_title_color: "#22105C",

  c_axis_labels: "12px",
  c_axis_labels_weight: 400,
  c_axis_labels_color: "#666666",

  c_legend_labels: "12px",
  c_legend_labels_weight: 400,
  c_legend_labels_color: "#666666",

  //mapInfowindow
  map_critical: "#CF0000",
  map_high: "#FF6364",
  map_medium: "#FFD168",
  map_low: "#A1E9FF",

  safety_1: "#cf0000",
  safety_2: "#d41a1a",
  safety_3: "#d93333",
  safety_4: "#dd4d4d",
  safety_5: "#e26666",
  safety_6: "#e78080",
  safety_7: "#ec9999",
  safety_8: "#f1b3b3",
  safety_9: "#f5cccc",

  operational_1: "#ffd168",
  operational_2: "#ffd677",
  operational_3: "#ffda86",
  operational_4: "#ffdf95",
  operational_5: "#ffe3a4",
  operational_6: "#ffe8b4",
  operational_7: "#ffedc3",
  operational_8: "#fff1d2",
  operational_9: "#fff6e1",

  violation_1: "#a1e9ff",
  violation_2: "#aaebff",
  violation_3: "#b4edff",
  violation_4: "#bdf0ff",
  violation_5: "#c7f2ff",
  violation_6: "#d0f4ff",
  violation_7: "#d9f6ff",
  violation_8: "#e3f8ff",
  violation_9: "#ecfbff",

  map_soc_high: "#11C967",
  map_soc_medium: "#F8BC3B",
  map_soc_low: "#FF6364",
  map_bms_error: "#2196F3",

  assembly_low: "#FF6364",
  assembly_medium: "#F8BC3B",
  assembly_high: "#11C967",
};

export { ThemeProperties };
