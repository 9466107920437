import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  IconButton,
  Popover,
} from "@mui/material";

import PulseLoader from "react-spinners/MoonLoader";
import {
  LiveMetricsBox,
  DigitalTwinChartLoader,
  ChartFilterBox,
  ChartFilter,
  CustomSelect,
} from "../../../../Components/CustomBox.js";
import { SecondaryButton } from "../../../../Components/CustomButton";
import DownloadCSV from "../../../../Helper/CSVOperations/DownloadCSV.js"
import NameTheCSV from "../../../../Helper/CSVOperations/NameTheCSV.js"
import CustomMiniDrawer from "../../../../Components/CustomMiniDrawer";
import DigitalTwinApexMultiAxis from "../../../../Charts/Line/DigitalTwinApexMultiAxis";
import SlickCarousel from "../../../../Features/Carousel/SlickCarousel";
import LiveMetricsCarousel from "../../../../Features/Carousel/SlickCarouselLiveMetrics.js";
import {
  checkBoxIcon,
  checkedIcon,
} from "../../../../Assets/Icons/DigitalTwin/CheckBoxIcon";

import {
  operations,
  tableFilters,
} from "../../../DigitalTwin/TabsSection/BatteryVitals/_Constants";
import BatteryMetricSelect from "../../../../Features/Menu/BatteryMetricSelect.js";
import {
  getGranularityDataV2,
  getPackComparisionV4,
} from "../../../../Api/DigitalTwin.js";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../../Helper/QueryParams/EncryptDecrypt.js";
import { useNavigate } from "react-router-dom";
import {
  DateDiffInDays,
  formatDate,
  formatDateNumeric,
  formatDateWithoutYear,
  getArrayOfFormattedDates,
  getArrayOfNumericDates,
  getWeekNumber,
  monthDictionary,
} from "../../../../Helper/DatePicker/DateFormatters.js";
import {
  convertToZoneDate,
  convertUTCtoZone,
  convertUTCtoZoneYYYYMMDD,
  convertAndGetTimeStampInRequiredFormat,
  getDatePickerDate,
} from "../../../../Helper/DatePicker/DateConverter.js";
import CustomSwitch from "../../../../Components/CustomSwitch.js";
import CustomIcon from "../../../../Components/CustomIcon.js";
import CODE from "../../../../Static/Constants/StatusCodes.js";
import TextWithCopyIcon from "../../../../Helper/Operations/TextWithCopyIcon.js";
import moment from "moment";
import { Tooltip, notification } from "antd";
import Events from "../../../../Analytics/Events.js";
import {
  selectedMetricsDataForVitalTab,
  chartColorCoding,
  multiplyFactors,
  disableMetricsForHierarchyLevel,
} from "../_Constants.js";
import Sidebar from "./Sidebar.js";
import {
  calculateDynamicRange,
  getDefaultRawDataXAxisRanges,
  getXAxisLabelForWeeklyGranularity,
} from "../../../../Helper/Charts/ChartHelper.js";
import { getCustomerEntityName, getSystemIDForEntity } from "../../../../Helper/DigitalTwin/DigitalTwinHelper.js";

const VitalTab = (props) => {
  const {
    pagesContent,
    hierarchy,
    batteryID,
    dateFilters,
    hierarchySequence,
    alertsCountHierarchical,
    alertList,
    getAlerts,
    alertsFilters,
    alertsSorts,
    isAlertFetching,
    alertsForAnnotation,
    openNotification,
    changeTheDate,
    liveMetricDataForBattery,
    hierarchyLiveMetricData,
  } = props;
  const navigate = useNavigate();
  const queryParams = decryptTheParams();
  const maxHierarchyLimit = 16;
  const defaultHierarchyLevel = "pack";
  const availableMetrics = pagesContent?.metaInfo?.availableMetrics || null
  let defaultHierarchy = [];
  switch (defaultHierarchyLevel) {
    case "battery":
      defaultHierarchy = ["battery"];
      break;
    case "pack":
      defaultHierarchy =
        hierarchy?.batteryStructure
          ?.slice(0, maxHierarchyLimit)
          ?.map((item) => item.name.toLowerCase()) || [];
  }

  const liveMetricData = useSelector((state) => state.BatteryLiveMetric);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isChartEmpty, setIsChartEmpty] = useState(true);
  const [rerenderChart, setRerenderChart] = useState(false);
  const [isFetchingChartData, setIsFetchingChartData] = useState(true);
  const [optionData, setOptionData] = useState({});
  const [liveMetric, setLiveMetric] = useState([null, null, null, null, null]);
  const [arrayOfDates, setArrayOfDatesFormatted] = useState([]);
  const [arrayOfDatesNumeric, setArrayOfDatesNumeric] = useState([]);
  const [pauseRerendering, setPauseRerendering] = useState(false);
  const [chartMetricsValue, setChartMetricValue] = useState({});
  const [dataSeriesForChart, setDataSeriesForChart] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [calendarDateDiff, setCalendarDateDiff] = useState(0);
  const [annotationData, setAnnotationData] = useState([]);
  const [chartRef, setChartRef] = useState(null);

  const [dateType, setDateType] = useState(queryParams?.dateType || "Raw Data");
  const [selectedComparisonModeMetric, setSelectedComparisonModeMetric] =
    useState(availableMetrics?.[0] ||"current");
  const [showAnnotation, setShowAnnotation] = useState(
    queryParams?.showAnnotation || "false"
  );
  const [selectedHierarchy, setSelectedHierarchy] = useState(
    queryParams?.selectedHierarchy
      ? queryParams?.selectedHierarchy?.split(",")
      : defaultHierarchyLevel === "battery"
      ? ["battery"]
      : hierarchy?.batteryStructure
          ?.slice(0, maxHierarchyLimit)
          ?.map((item) => item.name.toLowerCase()) || []
  );
  const [selectedAlertID, setSelectedAlertID] = useState(
    queryParams?.selectedAlertID || null
  );
  const [selectedAlertAnnotationID, setSelectedAlertAnnotationID] = useState(
    queryParams?.alertAnnotationID || null
  );
  const [isComparisonModeOn, setIsComparisonModeOn] = useState(
    queryParams?.selectedHierarchy 
      ? queryParams?.selectedHierarchy.split(",")?.length > 1
      : selectedHierarchy.length > 1
  );
  const [selectedHierarchyLevel, setSelectedHierarchyLevel] = useState(
    queryParams?.hierarchyLevel || defaultHierarchyLevel
  );
  const [tabValue, setTabValue] = useState(
    queryParams?.sidebarTabValue || "batterySystemTab"
  );
  const [operationType, setOperationType] = useState(
    queryParams?.operationType || "avg"
  );
  const [carousalStartDate, setCarousalStartDate] = useState({
    dateString: queryParams?.batteryPackMetrics,
  });
  const [selectedMetrics, setSelectedMetrics] = useState(
    selectedMetricsDataForVitalTab
  );
  const defaultLiveMetricToShowInSlide =
    viewportWidth > 1500
      ? isDrawerOpen
        ? 4
        : 5
      : viewportWidth > 1200
      ? isDrawerOpen
        ? 4
        : 4
      : viewportWidth > 1000
      ? isDrawerOpen
        ? 2
        : 3
      : viewportWidth > 760
      ? isDrawerOpen
        ? 2
        : 2
      : isDrawerOpen
      ? 1
      : 2;

  const [metricsValueForComparisonMode, setMetricsValueForComparisonMode] =
    useState({
      metricConfig: selectedMetricsDataForVitalTab.find(
        (item) => item.slug === selectedComparisonModeMetric
      ),
      series: [],
    });

  const [finalObjectCSV, setFinalObjectCSV] = useState({
    data: [],
    responseStatus: {
      code: 0,
    },
  });

  const [api, contextHolder] = notification.useNotification();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeNotification = () => {
    api.destroy();
  };

  const handleTabChange = (tab) => {
    Events(
      "On digital twin page under Vital Tab, the sidebar tabs changed. Active tab is " +
        (tab === "alertTab" ? "Alert tab" : "Battery System tab")
    );

    let hierarchyToBeSet = ["battery"];
    let hierarchyLevelToBeSet = "battery";
    const packCount = hierarchy?.batteryConfig?.packCount
    
    if (tab === "batterySystemTab" || packCount === 1) {
      hierarchyToBeSet = defaultHierarchy;
      hierarchyLevelToBeSet = defaultHierarchyLevel;
    }

    const params = {
      ...queryParams,
      sidebarTabValue: tab,
      hierarchyLevel: hierarchyLevelToBeSet,
      selectedHierarchy: hierarchyToBeSet.join(","),
      selectedAlertID: "",
      expandedAccordionName: "",
    };

    if (isComparisonModeOn) {
      setSelectedComparisonModeMetric("current");
    }
    setTabValue(tab);
    setIsFetchingChartData(true);
    setSelectedHierarchy(hierarchyToBeSet);
    setSelectedHierarchyLevel(hierarchyLevelToBeSet);
    setSelectedAlertAnnotationID(null);
    setSelectedAlertID(null);
    setIsComparisonModeOn(hierarchyToBeSet.length > 1);
    encryptTheParams(params, navigate, true);
  };

  const onMetricFilterCheck = (value, index) => {
    setSelectedMetrics((prevState) => {
      const newItems = [...prevState];
      return newItems.map((data, i) => {
        if (i === index) {
          Events(
            `On digital twin page under Vital Tab, ${value ? "show" : "hide"} ${
              data.label
            } metric for Vital section chart.`
          );
          return { ...data, checked: value };
        }

        return data;
      });
    });
  };

  const handleExportSVG = () => {
    if (chartRef.current) {
      chartRef.current.chart.dataURI().then((data) => {
        const imgURI = data.imgURI;

        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', '100%');

        const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        image.setAttribute('x', '0');
        image.setAttribute('y', '0');
        image.setAttribute('width', '100%');
        image.setAttribute('height', '100%');
        image.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', imgURI);

        svg.appendChild(image);

        const serializer = new XMLSerializer();
        const svgString = serializer.serializeToString(svg);

        const blob = new Blob([svgString], { type: 'image/svg+xml' });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'chart.svg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  };

  const getDownloadSection = () => {
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "0px 12px",
          height: "50px",
        }}
      >
        <IconButton
          fontSize="small"
          aria-describedby={id}
          onClick={handleClick}
          sx={{
            backgroundColor: open ? "rgba(118, 140, 255, 0.1)" : "white",
            borderRadius: "5px",
            height: "20px",
            width: "20px",
          }}
        >
          <MoreVertIcon fontSize="small" style={{ fontSize: "18px" }} />
        </IconButton>
         <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
          transformOrigin={{ vertical: 'top', horizontal: 'right', }}
          PaperProps={{
            style: {
              marginTop: "10px",
              backgroundColor: "white",
              width: "170px",
              boxShadow: "0px 0px 11px 0px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              padding: "10px",
            },
          }}
        >
          {[
            {
              label: "Download Image",
              event: "Landing clicked Csv Download",
              action: () => {
                handleClose()
                handleExportSVG()
              }
            },
            {
              label: "Download Data",
              event: "Chart clicked Csv Download",
              action: () => {
                getChartDataCsv()
                handleClose();
              }
            }
          ].map(({ label, event, action }) => (
            <SecondaryButton
              key={label}
              onClick={() => {
                action();
                Events(event);
              }}
              sx={{ pr: 1, width: "100%", }}
            >
              <CustomIcon name={label === "Download Image" ? "downloadImage" : "downloadData"} />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  letterSpacing: "-0.011em",
                  textAlign: "left",
                  color: "#444B51",
                  paddingLeft: "10px",
                }}
              >
                {label}
              </Typography>
            </SecondaryButton>
          ))}
        </Popover>
      </Box>
    );
  };

  // Need to create styled components for the elements to remove styling from this file
  const getIdSection = () => {
    let title;
    switch (selectedHierarchyLevel) {
      case "pack":
        title = "Pack ID";
        break;
      case "module":
        title = "Module ID";
        break;
      case "cell":
        title = "Cell ID";
        break;
      default:
        title = "Battery ID";
        break;
    }

    if (pagesContent?.metaInfo?.lastPingedMetricsBatteryIdHeader) {
      title = pagesContent?.metaInfo?.lastPingedMetricsBatteryIdHeader
    }

    const entityId =
      (selectedHierarchyLevel === "battery"
        ? batteryID
        : getCustomerEntityName(hierarchy, selectedHierarchy[0]) ||
          selectedHierarchy[0]) || batteryID;
    return (
      <Box
        style={{
          width: "330px",
          paddingRight: "8px",
          marginRight: "10px",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "600",
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
              color: "#828A92",
              whiteSpace: "nowrap",
            }}
          >
            {title}:
          </Typography>
          {TextWithCopyIcon(String(entityId).toUpperCase(), title, {
            fontFamily: "Roboto",
            paddingLeft: "10px",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "600",
            color: "#444B51",
            whiteSpace: "nowrap",
          })}
        </Box>
        <Box
          style={{ 
            display: 'flex',
            flexWrap: 'nowrap'
          }}
        >
          <Typography
            style={{
              fontSize: "12px",
              lineHeight: "14px",
              fontWeight: "500",
              color: "#828A92",
            }}
          >
            {"Last Pinged Metrics:"}
          </Typography>
          <Typography
            style={{
              fontSize: "12px",
              lineHeight: "14px",
              fontWeight: "500",
              color: "#444B51",
              marginLeft: "3px",
            }}
          >
            {convertAndGetTimeStampInRequiredFormat(hierarchyLiveMetricData?.[selectedHierarchy[0]]?.last_pinged_time, "DD MMM'YY, hh:mm A") || 'NA'}
          </Typography>
        </Box>
      </Box>
    );
  };

  const getDefaultSelectedMetric = (level) => {
    const config = [
      {
        label: "Current (A)",
        slug: "current",
        status: "active",
        checked: level ? (availableMetrics === null || availableMetrics?.includes('current')) && !disableMetricsForHierarchyLevel[level].includes("current") : true,
        showInComparisonMode: true,
        yAxisDecimalPlaces: 1,
        color: "#7855FF",
        unit: "A",
        yMin: -20,
        yMax: 20,
      },
      {
        label: "Voltage (V)",
        slug: "voltage",
        status: "active",
        checked: level ? (availableMetrics === null || availableMetrics?.includes('voltage')) && !disableMetricsForHierarchyLevel[level].includes("voltage") : true,
        showInComparisonMode: true,
        yAxisDecimalPlaces: 1,
        color: "#FF7557",
        unit: "V",
        yMin: 20,
        yMax: 35,
      },
      {
        label: "SOC (%)",
        slug: "soc",
        status: "active",
        checked: level ? (availableMetrics === null || availableMetrics?.includes('soc')) && !disableMetricsForHierarchyLevel[level].includes("soc") : true,
        showInComparisonMode: true,
        yAxisDecimalPlaces: 0,
        color: "#F8BB3B",
        unit: "%",
        yMin: 0,
        yMax: 100,
      },
      {
        label: "Max Temperature (°C)",
        slug: "temperature",
        status: "active",
        checked: level ? (availableMetrics === null || availableMetrics?.includes('temperature')) && !disableMetricsForHierarchyLevel[level].includes("temperature") : true,
        showInComparisonMode: true,
        yAxisDecimalPlaces: 0,
        color: "#229087",
        unit: "°C",
        yMin: -20,
        yMax: 80,
      },
      {
        label: "Capacity (Ah)",
        slug: "capacity",
        status: "inactive",
        checked: level ? (availableMetrics === null || availableMetrics?.includes('capacity')) && !disableMetricsForHierarchyLevel[level].includes("capacity") : true,
        showInComparisonMode: true,
        yAxisDecimalPlaces: 1,
        color: "#3E5AAB",
        unit: "Ah",
        yMin: 0,
        yMax: 125,
      },
      {
        label: "Energy Used (kWh)",
        slug: "energy",
        status: "inactive",
        checked: level ? (availableMetrics === null || availableMetrics?.includes('energy')) && !disableMetricsForHierarchyLevel[level].includes("energy") : true,
        showInComparisonMode: true,
        yAxisDecimalPlaces: 2,
        color: "#84BCEF",
        unit: "kWh",
        yMin: 0,
        yMax: 4,
      },
      {
        label: "Cell Imbalance (%)",
        slug: "balanceState",
        status: "inactive",
        checked: level ? (availableMetrics === null || availableMetrics?.includes('balanceState')) && !disableMetricsForHierarchyLevel[level].includes("balanceState") : true,
        showInComparisonMode: true,
        yAxisDecimalPlaces: 1,
        color: "#A75E6E",
        unit: "%",
        yMin: 0,
        yMax: 20,
      },
    ];
    return config
  };

  const handleHierarchyItemSelect = (selectedItems, level) => {
    setIsComparisonModeOn(selectedItems.length > 1);
    if (selectedItems.length > 1) {
      // Somehow array was not resetting the value. we have to create a new variable here
      let selectedMetric = getDefaultSelectedMetric(level);
      setSelectedMetrics([...selectedMetric]);
    }
    setSelectedHierarchy(selectedItems);
    const selectedLevel = selectedItems.length ? level || "battery" : "battery";
    setSelectedHierarchyLevel(selectedLevel);

    const params = {
      ...decryptTheParams(),
      hierarchyLevel: selectedLevel,
      selectedHierarchy: selectedItems.join(","),
    };
    encryptTheParams(params, navigate, true);
  };

  const handleClickOnAlert = (
    entityIDList,
    uniqueAlertID,
    level,
    alertDbTime,
    expandedAccordionName
  ) => {
    setPauseRerendering(true);
    setSelectedHierarchyLevel(level);
    setSelectedHierarchy(entityIDList);

    let alertStartDate = convertAndGetTimeStampInRequiredFormat(
      alertDbTime,
      "YYYY-MM-DD"
    );

    const annotationID = new Date(
      convertAndGetTimeStampInRequiredFormat(alertDbTime)
    ).getTime();

    const params = {
      ...queryParams,
      hierarchyLevel: level,
      selectedHierarchy: entityIDList.join(","),
      batteryPackMetrics: alertStartDate,
      showAnnotation: "true",
      selectedAlertID: uniqueAlertID,
      alertAnnotationID: annotationID,
      expandedAccordionName: expandedAccordionName,
      redirectedFromAlertPage: "false",
    };

    setShowAnnotation("true");
    setCarousalStartDate({ dateString: alertStartDate });
    setSelectedAlertID(uniqueAlertID);
    setPauseRerendering(false);
    setDateType("Raw Data");
    changeTheDate(alertStartDate, getDatePickerDate(), params);
  };

  // Need to create styled components for the elements to remove styling from this file
  const getComparisonPlot = () => {
    return (
      <Box
        style={{
          paddingBottom: "14px",
          borderBottom: "1px solid #E9EFF5",
          height: "116px",
        }}
      >
        <Box
          style={{
            paddingLeft: "20px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "600",
              paddingTop: "10px",
              color: "#444B51 ",
            }}
          >
            {"Comparison Plot"}
          </Typography>
        </Box>
        <Box
          style={{
            paddingLeft: "20px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "#444B51",
            }}
          >
            {"Last Pinged Metrics"}
          </Typography>
        </Box>
        <Box style={{ width: isDrawerOpen ? "77vw" : "85vw" }}>
          <LiveMetricsCarousel
            style={{
              width: "100%",
            }}
            hierarchyLiveMetricData={hierarchyLiveMetricData}
            selectedComparisonModeMetric={selectedComparisonModeMetric}
            hierarchy={hierarchy}
            selectedHierarchy={selectedHierarchy}
            selectedHierarchyLevel={selectedHierarchyLevel}
            chartColorCoding={chartColorCoding}
            slidesToShow={defaultLiveMetricToShowInSlide}
          />
        </Box>
      </Box>
    );
  };

  // Need to create styled components for the elements to remove styling from this file
  const getLiveMetricSection = () => {
    return liveMetric?.map((metric) => {
      let pingStatus =
        hierarchyLiveMetricData?.[selectedHierarchy?.[0]?.toLowerCase()]
          ?.pingStatus;
      if (selectedHierarchyLevel === "battery") {
        pingStatus =
          liveMetricData?.batteryPingedStatus === "Online"
            ? "Active"
            : "Inactive";
      }
      const renderValue = () => {
        if (metric?.value && typeof metric?.value === "object") {
          return (
            <Box
              style={{
                display: "flex",
                flexWrap: "nowrap",
                fontFamily: "Roboto",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#444B51",
                }}
              >
                {Number.isFinite(metric?.value?.min)
                  ? Number.isInteger(metric?.value?.min)
                    ? metric?.value?.min + ` ${metric?.unit}`
                    : metric?.value?.min?.toFixed(1) + ` ${metric?.unit}`
                  : "NA"}
              </Typography>
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: "10px",
                  lineHeight: "20px",
                  color: "#828A92",
                  whiteSpace: "nowrap",
                }}
              >
                {"(min) "}
              </Typography>
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#444B51",
                  paddingLeft: "3.5px",
                }}
              >
                {`| ${Number.isFinite(metric?.value?.max)
                  ? Number.isInteger(metric?.value?.max)
                    ? metric?.value?.max + ` ${metric?.unit}`
                    : metric?.value?.max?.toFixed(1) + ` ${metric?.unit}`
                  : "NA"
                  }`}
              </Typography>
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: "10px",
                  lineHeight: "20px",
                  color: "#828A92",
                }}
              >
                {"(max)"}
              </Typography>
            </Box>
          );
        } else {
          return (
            <Typography
              style={{
                fontFamily: "Roboto",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#444B51",
                whiteSpace: "nowrap",
                textAlign: "left",
              }}
            >
              {Number.isFinite(metric?.value) ? metric?.value + ` ${metric?.unit}` : "NA"}
            </Typography>
          );
        }
      };

      return (
        <LiveMetricsBox width="182px" color={"rgba(203, 203, 204, 0.5)"}>
          {metric ? (
            <Box
              style={{
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(68, 75, 81, 1)",
                }}
              >
                <Tooltip
                  placement="top"
                  title={
                    pingStatus === "Active" ? "Live Signal Values" : "Offline"
                  }
                  zIndex={2000}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "7px",
                    }}
                  >
                    <CustomIcon
                      name={pingStatus === "Active" ? "online" : "offline"}
                      style={{
                        width: "14px",
                        height: "10px",
                      }}
                    />
                  </Box>
                </Tooltip>

                <Typography
                  style={{
                    marginLeft: "3px",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#828A92",
                    whiteSpace: "nowrap",
                    marginBottom: "4px",
                    paddingLeft: "3px",
                  }}
                >
                  {metric?.label}
                </Typography>
              </Box>
              {renderValue()}
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "between",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <PulseLoader color={"#213444"} size={20} speedMultiplier={1} />
            </Box>
          )}
        </LiveMetricsBox>
      );
    });
  };

  const handleAddMetric = (item, indexToDelete) => {
    Events(
      `On digital twin page under Vital Tab, added ${item.label} metric from the select metrics dropdown`
    );
    const newData = [...selectedMetrics];
    const EmptyIndex = newData.findIndex((item) => item === null);
    item.status = "active";
    item.checked = true;
    newData[EmptyIndex] = item;
    newData.splice(indexToDelete, 1);
    setSelectedMetrics(newData);
    const temp = {
      ...queryParams,
      selectedMetrics: newData,
    };
  };

  const handleDateTypeChange = (value) => {
    if (dateType === value) {
      return;
    }
    Events(
      `On digital twin page under Vital Tab, date type filter is set to ${value} for the chart.`
    );
    setIsFetchingChartData(true);
    setDateType(value);
    const temp = {
      ...queryParams,
      dateType: value,
      batteryPackMetrics: "",
    };
    setCarousalDate("", temp);
  };

  const handleOperationTypeChange = (value) => {
    Events(
      `On digital twin page under Vital Tab, operation type filter is set to ${value} for the chart.`
    );
    setOperationType(value);
    const temp = {
      ...queryParams,
      operationType: value,
      batteryPackMetrics: carousalStartDate.dateString,
    };
    encryptTheParams(temp, navigate, true);
  };

  const handleRemoveMetric = (item, index) => {
    Events(
      `On digital twin page under Vital Tab, removed ${item.label} metric from the select metrics dropdown`
    );
    const newData = [...selectedMetrics];
    item.status = "inactive";
    newData[index] = null;
    newData.push(item);
    setSelectedMetrics(newData);
  };

  const lastSunday = (year, month) => {
    var date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    date.setDate(date.getDate() - dayDiff);
    return date;
  };

  const setCarousalDate = (dateString, params = {}) => {
    const temp = {
      ...queryParams,
      ...params,
      batteryPackMetrics: dateString,
    };
    setCarousalStartDate({ dateString });
    encryptTheParams(temp, navigate, true);
  };

  const onComparisonModeMetricSelect = (e) => {
    setSelectedComparisonModeMetric(e.target?.value);
  };

  const setDefaultChartDataForComparisonMode = (metricsData, startDate) => {
    const defaultData = getDefaultRawDataXAxisRanges(startDate);

    metricsData?.series.forEach((item, index) => {
      metricsData.series[index].data = defaultData;
    });
  };

  const getChartDataForComparisonMode = async (startDate, endDate) => {
    const metricsData = {
      metricConfig: selectedMetrics?.find(
        (item) => item?.slug === selectedComparisonModeMetric
      ),
      series: [],
      yMin: selectedMetrics?.find(
        (item) => item?.slug === selectedComparisonModeMetric
      )?.yMin,
      yMax: selectedMetrics?.find(
        (item) => item?.slug === selectedComparisonModeMetric
      )?.yMax,
    };

    let chartIsEmpty = true;

    await Promise.all(
      selectedHierarchy.map(async (selectedEntity, entityIndex) => {
        const systemID = getSystemIDForEntity(hierarchy, selectedEntity)
        const entityLabel = getCustomerEntityName(hierarchy, selectedEntity) || selectedEntity.toUpperCase()
        metricsData.series.push({
          name:
            entityLabel.toUpperCase() + ` (${metricsData.metricConfig.unit})`,
          data: [],
        });
        if (dateType === "Raw Data") {
          const res = await getPackComparisionV4(
            props.batteryID,
            systemID,
            selectedHierarchyLevel,
            selectedComparisonModeMetric,
            [startDate, endDate]
          );
          if (res?.responseStatus?.code === CODE.SUCCESS) {
            chartIsEmpty = false;
            res?.response?.batteryData?.map((item) => {
              metricsData.series?.[entityIndex]?.data?.push({
                x: convertUTCtoZone(item.batteryDateTime, true),
                y:
                  item?.metricsArray?.[0]?.value === null
                    ? null
                    : item?.metricsArray?.[0]?.value *
                      multiplyFactors[selectedComparisonModeMetric],
              });
            });
          } else {
            metricsData.series[entityIndex].data = [];
          }
        } else {
          const res = await getGranularityDataV2(
            batteryID,
            systemID,
            selectedHierarchyLevel,
            selectedComparisonModeMetric,
            [startDate, endDate],
            dateType,
            operationType
          );

          if (res?.responseStatus?.code === CODE.SUCCESS) {
            chartIsEmpty = false;
          }
          res?.response?.batteryData?.forEach((item, index) => {
            item?.metricsArray?.[0]
              ? item?.metricsArray?.forEach((metric) => {
                  metricsData.series?.[entityIndex]?.data?.push({
                    x:
                      dateType === "Daily"
                        ? formatDateWithoutYear(
                            new Date(item?.range?.startDate)
                          )
                        : dateType === "Weekly"
                        ? getXAxisLabelForWeeklyGranularity(
                            new Date(item?.range?.startDate),
                            new Date(item?.range?.endDate),
                            res?.response?.batteryData?.length
                          )
                        : monthDictionary.find(
                            (month) =>
                              month.name ===
                              item?.range?.startDate.split("-")[1]
                          )?.value,
                    y:
                      metric?.value === null || metric?.value === undefined
                        ? null
                        : metric.value *
                          multiplyFactors[selectedComparisonModeMetric],
                  });
                })
              : metricsData.series?.[entityIndex]?.data?.push({
                  x:
                    dateType === "Daily"
                      ? formatDateWithoutYear(new Date(item?.range?.startDate))
                      : dateType === "Weekly"
                      ? formatDateWithoutYear(
                          new Date(item?.range?.startDate)
                        ) +
                        " - " +
                        formatDateWithoutYear(new Date(item?.range?.endDate))
                      : monthDictionary.find(
                          (month) =>
                            month.name === item?.range?.startDate.split("-")[1]
                        )?.value,
                  y: null,
                });
          });
        }
      })
    );

    if (dateType === "Raw Data" && chartIsEmpty) {
      setDefaultChartDataForComparisonMode(metricsData, startDate);
    }

    setIsChartEmpty(chartIsEmpty);
    updateMetricDataForComparisonMode(metricsData);
    setIsFetchingChartData(false);
  };

  const updateMetricDataForComparisonMode = (metricsData) => {
    let yAxisValues = [];
    let maxLengthOfData = metricsData.series?.[0].data.length;
    let indexWithMaxLength = 0;
    let isDataNotOfEqualLength = false;
    metricsData.series.forEach((entityData, index) => {
      if (entityData.data.length !== maxLengthOfData) {
        isDataNotOfEqualLength = true;
        maxLengthOfData = Math.max(entityData.data.length, maxLengthOfData);
        indexWithMaxLength =
          maxLengthOfData === entityData.data.length
            ? index
            : indexWithMaxLength;
      }
      if (chartColorCoding?.[index]) {
        metricsData.series[index]["color"] = chartColorCoding?.[index];
      }
      const values = entityData?.data?.map((item) => item?.y);
      yAxisValues = yAxisValues.concat(values);
    });

    if (isDataNotOfEqualLength) {
      metricsData.series.forEach((entityData, index) => {
        if (entityData.data.length != 0) {
          return;
        }

        metricsData.series[index].data = metricsData.series[
          indexWithMaxLength
        ].data.map((item) => {
          return {
            x: item.x,
            y: null,
          };
        });
      });
    }

    const metricsConfig = selectedMetrics.find(
      (item) => item?.slug === selectedComparisonModeMetric
    );
    let minYValueSlice = metricsConfig.yMin;
    let maxYValueSlice = metricsConfig.yMax;
    const metricThresholds =
      pagesContent.metaInfo?.batteryVitalsRanges?.[selectedHierarchyLevel]?.[selectedComparisonModeMetric];
    if (metricThresholds) {
      minYValueSlice = metricThresholds.min;
      maxYValueSlice = metricThresholds.max;
    }

    yAxisValues = yAxisValues.filter(
      (item) => item !== undefined && item !== null
    );

    const minYValue = Math.min(...yAxisValues);
    const maxYValue = Math.max(...yAxisValues);

    if (minYValue < minYValueSlice || maxYValue > maxYValueSlice) {
      const dynamicRange = calculateDynamicRange(
        minYValue,
        maxYValue,
        minYValueSlice,
        maxYValueSlice
      );
      minYValueSlice = dynamicRange.min;
      maxYValueSlice = dynamicRange.max;
    }

    metricsData.yMin = minYValueSlice;
    metricsData.yMax = maxYValueSlice;

    setMetricsValueForComparisonMode(metricsData);
  };

  const updateMetricData = (metricsData) => {
    for (let key in metricsData) {
      if (key === "ranges") {
        continue;
      }

      let yAxisValues = metricsData[key].map((data) => {
        if (data && typeof data === "object") {
          return data.y;
        } else {
          return data;
        }
      });

      const metricsConfig = selectedMetrics.find((item) => item?.slug === key);

      let minYValueSlice = metricsConfig.yMin;
      let maxYValueSlice = metricsConfig.yMax;
      const metricThresholds =
        pagesContent.metaInfo?.batteryVitalsRanges?.[selectedHierarchyLevel]?.[key];
      if (metricThresholds) {
        minYValueSlice = metricThresholds.min;
        maxYValueSlice = metricThresholds.max;
      }

      yAxisValues = yAxisValues.filter(
        (item) => item !== undefined && item !== null
      );

      const minYValue = Math.min(...yAxisValues);
      const maxYValue = Math.max(...yAxisValues);

      if (minYValue < minYValueSlice || maxYValue > maxYValueSlice) {
        const dynamicRange = calculateDynamicRange(
          minYValue,
          maxYValue,
          minYValueSlice,
          maxYValueSlice
        );
        minYValueSlice = dynamicRange.min;
        maxYValueSlice = dynamicRange.max;
      }

      metricsData.ranges = {
        ...metricsData.ranges,
        [key]: {
          min: minYValueSlice,
          max: maxYValueSlice,
        },
      };
    }
    setChartMetricValue(metricsData);
  };

  const isBatteryLevelHierarchy = () => {
    return selectedHierarchyLevel === "battery";
  };

  const getChartData = (startDate = null, endDate = null) => {
    if (!startDate || !endDate || selectedHierarchy?.length === 0) {
      return;
    }
    setIsFetchingChartData(true);

    if (isComparisonModeOn) {
      getChartDataForComparisonMode(startDate, endDate);
      return;
    }
    const systemID = getSystemIDForEntity(hierarchy, selectedHierarchy[0]) || selectedHierarchy[0].toUpperCase()
    const metricsData = {
      soc: [],
      temperature: [],
      current: [],
      voltage: [],
      capacity: [],
      energy: [],
      balanceState: [],
      ranges: {
        soc: {
          min: 0,
          max: 100,
        },
        temperature: {
          min: -20,
          max: 80,
        },
        current: {
          min: -20,
          max: 20,
        },
        voltage: {
          min: 20,
          max: 35,
        },
        capacity: {
          min: 0,
          max: 125,
        },
        energy: {
          min: 0,
          max: 4,
        },
        balanceState: {
          min: 0,
          max: 20,
        },
      },
    };

    dateType === "Raw Data"
      ? getPackComparisionV4(
        props.batteryID,
        systemID,
        selectedHierarchyLevel,
        [
          "SOC",
          "temperature",
          "CURRENT",
          "VOLTAGE",
          "capacity",
          "energy",
          "balanceState",
        ],
        [startDate, endDate]
      ).then((res) => {
        if (res?.responseStatus?.code === CODE.SUCCESS) {
          setIsChartEmpty(false);
          res?.response?.batteryData?.map((item) => {
            metricsData.soc.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y:
                item["metricsArray"][0]["value"] === null
                  ? null
                  : item["metricsArray"][0]["value"] * multiplyFactors.soc,
            });
            metricsData.temperature.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y:
                item["metricsArray"][1]["value"] === null
                  ? null
                  : item["metricsArray"][1]["value"] *
                  multiplyFactors.temperature,
            });
            metricsData.current.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y:
                item["metricsArray"][2]["value"] === null
                  ? null
                  : item["metricsArray"][2]["value"] *
                  multiplyFactors.current,
            });
            metricsData.voltage.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y:
                item["metricsArray"][3]["value"] === null
                  ? null
                  : item["metricsArray"][3]["value"] *
                  multiplyFactors.voltage,
            });
            metricsData.capacity.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y:
                item["metricsArray"][4]["value"] === null
                  ? null
                  : item["metricsArray"][4]["value"] *
                  multiplyFactors.capacity,
            });
            metricsData.energy.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y:
                item["metricsArray"][5]["value"] === null
                  ? null
                  : item["metricsArray"][5]["value"] * multiplyFactors.energy,
            });
            metricsData.balanceState.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y:
                item["metricsArray"][6]["value"] === null
                  ? null
                  : item["metricsArray"][6]["value"] *
                  multiplyFactors.balanceState,
            });
          })
        } else {
          setIsChartEmpty(true);
          for (let key in metricsData) {
            if (key === "ranges") {
              continue;
            }
            metricsData[key] = [
              { x: startDate + " 00:00:00", y: null },
              { x: startDate + " 01:00:00", y: null },
              { x: startDate + " 02:00:00", y: null },
              { x: startDate + " 03:00:00", y: null },
              { x: startDate + " 04:00:00", y: null },
              { x: startDate + " 05:00:00", y: null },
              { x: startDate + " 06:00:00", y: null },
              { x: startDate + " 07:00:00", y: null },
              { x: startDate + " 08:00:00", y: null },
              { x: startDate + " 09:00:00", y: null },
              { x: startDate + " 10:00:00", y: null },
              { x: startDate + " 11:00:00", y: null },
              { x: startDate + " 12:00:00", y: null },
              { x: startDate + " 13:00:00", y: null },
              { x: startDate + " 14:00:00", y: null },
              { x: startDate + " 15:00:00", y: null },
              { x: startDate + " 16:00:00", y: null },
              { x: startDate + " 17:00:00", y: null },
              { x: startDate + " 18:00:00", y: null },
              { x: startDate + " 19:00:00", y: null },
              { x: startDate + " 20:00:00", y: null },
              { x: startDate + " 21:00:00", y: null },
              { x: startDate + " 22:00:00", y: null },
              { x: startDate + " 23:00:00", y: null },
              { x: startDate + " 23:59:59", y: null },
            ];
          }
        }
        updateMetricData(metricsData);
        setNewOptionData({ dateType });
        setIsFetchingChartData(false);
      })
      : getGranularityDataV2(
        batteryID,
        systemID,
        selectedHierarchyLevel,
        [
          "SOC",
          "temperature",
          "CURRENT",
          "VOLTAGE",
          "capacity",
          "energy",
          "balanceState",
        ],
        [
          startDate || dateFilters?.startDate,
          endDate || dateFilters?.endDate,
        ],
        dateType,
        operationType
      ).then((res1) => {
        let dateRanges = [];
        setIsChartEmpty(
          isBatteryLevelHierarchy() ||
          res1?.responseStatus?.code !== CODE.SUCCESS
        );
        res1?.response?.batteryData?.forEach((item) => {
          if (
            isBatteryLevelHierarchy() &&
            item?.range?.startDate === "None"
          ) {
            // In battery system case we get 2 empty dataset at the end of result which causes issue with x axis dates in chart
            // Hence skipping these 2 cases
            return;
          }
          item?.metricsArray?.[0] &&
            item?.metricsArray?.forEach((metric) => {
              metricsData[metric.type]?.push(
                metric.value === null
                  ? null
                  : metric.value * multiplyFactors[metric.type]
              );
            });

            if (!item?.metricsArray?.[0]) {
              for (let key in metricsData) {
                if (key === "ranges") {
                  continue;
                }
                metricsData?.[key]?.push(null);
              }
            }

            switch (dateType) {
              case "Daily":
                const date = new Date(item?.range?.startDate);
                dateRanges.push(formatDateWithoutYear(date));
                break;

              case "Weekly":
                const startDate = new Date(item?.range?.startDate);
                const endDate = new Date(item?.range?.endDate);
                const seriesLength = res1?.response?.batteryData.length;
                dateRanges.push(
                  getXAxisLabelForWeeklyGranularity(
                    startDate,
                    endDate,
                    seriesLength
                  )
                );
                break;

              case "Monthly":
                const month = moment(item?.range?.startDate, "YYYY-MM");
                if (
                  carousalStartDate?.dateString.substring(0, 4) ===
                  `${month.year()}`
                ) {
                  dateRanges.push(moment(month, "YYYY-MM").format("MMM"));
                }
            }
          });

          const data = { ...optionData, dateType };
          data["xAxisCategories"] = dateRanges;
          setNewOptionData(data);
          updateMetricData(metricsData);
          setIsFetchingChartData(false);
        });
  };

  const structureRealTimeDataForNormalMode = (data) => {
    const chartLiveMetricData = [
      {
        slug: "current",
        label: "Current",
        width: "150px",
        value:
          selectedHierarchyLevel === "battery"
            ? {
                min: data?.minCurrent,
                max: data?.maxCurrent,
              }
            : data?.current,
        unit: "A",
      },
      {
        slug: "voltage",
        label: "Voltage",
        width: "150px",
        value:
          selectedHierarchyLevel === "battery"
            ? {
                min: data?.minVoltage,
                max: data?.maxVoltage,
              }
            : data?.voltage,
        unit: "V",
      },
      {
        slug: "soc",
        label: "SOC",
        width: "150px",
        value:
          selectedHierarchyLevel === "battery"
            ? {
                min: data?.minSOC,
                max: data?.maxSOC,
              }
            : data?.soc,
        unit: "%",
      },
      {
        slug: "temperature",
        label: "Max Temperature",
        width: "150px",
        value:
          selectedHierarchyLevel === "battery"
            ? {
                min: data?.minTemperature,
                max: data?.maxTemperature,
              }
            : data?.temperature,
        unit: "°C",
      },
      {
        slug: "balanceState",
        label: "Capacity",
        width: "150px",
        value:
          selectedHierarchyLevel === "battery"
            ? {
                min: data?.minCapacity,
                max: data?.maxCapacity,
              }
            : data?.capacity,
        unit: "Ah",
      },
    ];
    setLiveMetric(chartLiveMetricData.filter(filter => availableMetrics?.includes(filter.slug)));
  }

  const toggleAnnotation = (value) => {
    Events(
      `On digital twin page under Vital Tab, Annotation is turned ${
        value ? "on" : "off"
      } for the chart.`
    );
    const temp = { ...queryParams, showAnnotation: `${value}` };
    encryptTheParams(temp, navigate, true);
    setShowAnnotation(`${value}`);
  };

  const setNewOptionData = (data) => {
    setOptionData(data);
  };

  const getChartXAxisCategoryFromDateForWeekly = (dateString) => {
    const dateRanges = optionData?.xAxisCategories;

    let matchedRange = null;

    dateRanges?.forEach((rangeString) => {
      // Function to convert month abbreviation to its numerical representation
      const monthMap = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
      };

      // Split string 1 to get start and end dates
      const [startDayStr, startMonthStr, endDayStr, endMonthStr] =
        rangeString.split(/[\s-]+/);

      // Construct start date string in YYYY-MM-DD format
      const startDateStr = `${new Date().getFullYear()}-${
        monthMap[startMonthStr]
      }-${startDayStr?.padStart(2, "0")}`;

      // Construct end date string in YYYY-MM-DD format
      const endDateStr = `${new Date().getFullYear()}-${
        monthMap[endMonthStr]
      }-${endDayStr?.padStart(2, "0")}`;

      // Parse start and end dates
      const startDate = new Date(startDateStr);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(endDateStr);
      endDate.setHours(23, 59, 59, 999);

      const dateToSearch = new Date(dateString);
      dateToSearch.setHours(0, 0, 0, 0);

      // Check if date2 falls within the range specified by startDate and endDate
      const isDateInRange =
        dateToSearch >= startDate && dateToSearch <= endDate;

      if (isDateInRange) {
        matchedRange = rangeString;
      }
    });

    return matchedRange;
  };

  const structureAlertDataForAnnotation = (filteredData) => {
    // Create an object to store timestamp counts
    let timestampCounts = {};
    let timestampData = {};

    if (filteredData.length === 0) {
      return filteredData;
    }

    const dbTimeForSelectedAlert = selectedAlertID?.split("#")?.[1];

    // Count occurrences of each timestamp
    filteredData?.forEach((item) => {
      let timestamp = new Date(
        convertUTCtoZoneYYYYMMDD(item.customerDBTime)
      ).getTime();

      switch (dateType) {
        case "Daily":
          timestamp = formatDateWithoutYear(
            new Date(convertUTCtoZoneYYYYMMDD(item.customerDBTime))
          );
          break;
        case "Weekly":
          timestamp = getChartXAxisCategoryFromDateForWeekly(
            convertAndGetTimeStampInRequiredFormat(
              item.customerDBTime,
              "YYYY-MM-DD"
            )
          );

          break;
        case "Monthly":
          timestamp = convertAndGetTimeStampInRequiredFormat(
            item.customerDBTime,
            "MMM"
          );
          break;
      }

      if (dateType == "Raw Data") {
        const intervalInMinutes = 30 * 60 * 1000;
        const intervalIndex =
          Math.floor(timestamp / intervalInMinutes) * intervalInMinutes;

        if (item.customerDBTime === dbTimeForSelectedAlert) {
          setSelectedAlertAnnotationID(`${intervalIndex}`);
        }
        if (!timestampData[intervalIndex]) {
          timestampData[intervalIndex] = [];
        }

        timestampCounts[intervalIndex] =
          (timestampCounts[intervalIndex] || 0) + 1;
        timestampData[intervalIndex].push({
          insertionTime: item.customerDBTime,
          severity: item.severity,
          alertDescription: item.alertMetaName,
          alertStatus: item.alertStatus,
        });
      } else {
        if (item.customerDBTime === dbTimeForSelectedAlert) {
          setSelectedAlertAnnotationID(`${timestamp}`);
        }
        if (!timestampData[timestamp]) {
          timestampData[timestamp] = [];
        }
        timestampCounts[timestamp] = (timestampCounts[timestamp] || 0) + 1;
        timestampData[timestamp].push({
          insertionTime: item.customerDBTime,
          severity: item.severity,
          alertDescription: item.alertMetaName,
          alertStatus: item.alertStatus,
        });
      }
    });

    // Transform object into an array of objects
    const result = Object.keys(timestampCounts).map((timestamp) => ({
      x: parseInt(timestamp, 10),
      count: timestampCounts[timestamp],
      data: timestampData[timestamp],
      id: timestamp,
    }));

    return result;
  };

  const generateAnnotationData = (startDateString, endDateString) => {
    if (showAnnotation !== "true") {
      setAnnotationData([]);
      return;
    }
    const startDate = new Date(startDateString);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(endDateString);
    endDate.setHours(23, 59, 59, 999);
    const filteredAlertData = alertsForAnnotation.filter((item) => {
      const itemDate = new Date(convertUTCtoZoneYYYYMMDD(item.customerDBTime));

      const isLevelMatched = selectedHierarchyLevel === item.level;
      let annotationEntity = null;
      switch (item.level) {
        case "cell":
          annotationEntity = item.cellID;
          break;
        case "module":
          annotationEntity = item.moduleID;
          break;
        case "pack":
          annotationEntity = item.systemID;
          break;
        case "battery":
          annotationEntity = "battery";
      }

      const isEntityMatched = selectedHierarchy.find(
        (item) => item?.toLowerCase() === annotationEntity?.toLowerCase()
      );

      return (
        itemDate >= startDate &&
        itemDate <= endDate &&
        isLevelMatched &&
        isEntityMatched
      );
    });

    const newAnnotationData =
      structureAlertDataForAnnotation(filteredAlertData);
    setAnnotationData(newAnnotationData);
  };

  const createCarousalDateRanges = () => {
    if (dateType === "Raw Data") {
      if (dateFilters?.startDate || dateFilters?.endDate) {
        var tempFormatted = getArrayOfFormattedDates(
          dateFilters?.startDate,
          dateFilters?.endDate
        );
        var tempNumeric = getArrayOfNumericDates(
          dateFilters?.startDate,
          dateFilters?.endDate
        );

        setArrayOfDatesFormatted(tempFormatted);
        setArrayOfDatesNumeric(tempNumeric);
        setCarousalDate(carousalStartDate.dateString || tempNumeric[0]);
      }
      return;
    }

    if (dateType === "Daily") {
      var tempFormatted = getArrayOfFormattedDates(
        dateFilters?.startDate,
        dateFilters?.endDate
      );
      var tempNumeric = getArrayOfNumericDates(
        dateFilters?.startDate,
        dateFilters?.endDate
      );

      var buckets = [],
        tempDatesToShow = [],
        tempDatesToSave = [];
      for (var i = 0; i < tempFormatted.length; i = i + 15) {
        var tempStart = tempFormatted[i],
          tempEnd =
            tempFormatted[i + 14] === undefined
              ? tempFormatted[tempFormatted.length - 1]
              : tempFormatted[i + 14],
          tempStartSave = tempNumeric[i],
          tempEndSave =
            tempNumeric[i + 14] === undefined
              ? tempNumeric[tempNumeric.length - 1]
              : tempNumeric[i + 14];

        buckets.push({
          start: tempStart,
          end: tempEnd,
          textToShow: tempStart + " to " + tempEnd,
          textToSave: tempStartSave + ":" + tempEndSave,
        });
        tempDatesToShow.push(tempStart + " to " + tempEnd);
        tempDatesToSave.push(tempStartSave + ":" + tempEndSave);
      }

      setArrayOfDatesFormatted(tempDatesToShow);
      setArrayOfDatesNumeric(tempDatesToSave);
      setCarousalDate(carousalStartDate?.dateString || tempDatesToSave[0]);
    } else if (dateType === "Weekly") {
      let diffInDays = DateDiffInDays(
        dateFilters?.startDate,
        dateFilters?.endDate
      );
      if (diffInDays > 105) {
        var myStart = formatDate(convertToZoneDate(dateFilters?.startDate));
        var myEnd = formatDate(convertToZoneDate(dateFilters?.endDate));

        var tempDateRanges = [];

        var tempStart = new Date(myStart);
        let tempEnd = new Date(tempStart.setDate(tempStart.getDate() + 105));

        tempDateRanges.push({
          start: new Date(myStart),
          end: lastSunday(tempEnd.getUTCFullYear(), tempEnd.getUTCMonth() + 1),
          startWeek: getWeekNumber(new Date(myStart)),
          endWeek: getWeekNumber(
            lastSunday(tempEnd.getUTCFullYear(), tempEnd.getUTCMonth() + 1)
          ),
        });

        var chi = new Date(tempDateRanges[0]["end"]);
        var nextStartDate = new Date(chi.setDate(chi.getDate() + 1));

        var i = 1;
        while (nextStartDate < new Date(myEnd)) {
          let confusious = new Date(
            nextStartDate.setDate(nextStartDate.getDate() + 105)
          );

          tempDateRanges.push({
            start: new Date(
              tempDateRanges[i - 1]["end"].getTime() + 24 * 60 * 60 * 1000
            ),
            end: lastSunday(
              confusious.getUTCFullYear(),
              confusious.getUTCMonth() + 1
            ),
          });

          i++;
        }

        tempDateRanges[tempDateRanges.length - 1]["end"] = new Date(myEnd);

        let finalRanges = [];
        tempDateRanges.map((item) => {
          let tempStart = getWeekNumber(new Date(item.start)),
            tempEnd = getWeekNumber(new Date(item.end));
          finalRanges.push({
            start: formatDateNumeric(item.start),
            end: formatDateNumeric(item.end),
            startWeek: tempStart,
            endWeek: tempEnd,
            textToShow:
              moment(item.start).format("DD MMM, YY") +
              " - " +
              moment(item.end).format("DD MMM, YY"),
            textToSave:
              formatDateNumeric(item.start) + ":" + formatDateNumeric(item.end),
          });
        });
        let tempDatesToShow = [],
          tempDatesToSave = [];

        finalRanges.map((item) => {
          tempDatesToShow.push(item.textToShow);
          tempDatesToSave.push(item.textToSave);
        });

        setArrayOfDatesFormatted(tempDatesToShow);
        setArrayOfDatesNumeric(tempDatesToSave);
        setCarousalDate(carousalStartDate?.dateString || tempDatesToSave[0]);
      } else {
        setArrayOfDatesFormatted([
          moment(dateFilters?.startDate).format("DD MMM, YY") +
            " - " +
            moment(dateFilters?.endDate).format("DD MMM, YY"),
        ]);
        setArrayOfDatesNumeric([
          formatDateNumeric(convertToZoneDate(dateFilters?.startDate)) +
            ":" +
            formatDateNumeric(convertToZoneDate(dateFilters?.endDate)),
        ]);
        setCarousalDate(
          carousalStartDate?.dateString ||
            formatDateNumeric(convertToZoneDate(dateFilters?.startDate)) +
              ":" +
              formatDateNumeric(convertToZoneDate(dateFilters?.endDate))
        );
      }
    } else if (dateType === "Monthly") {
      let tempStartYear = convertToZoneDate(
        dateFilters?.startDate
      ).getFullYear();
      let tempEndYear = convertToZoneDate(dateFilters?.endDate).getFullYear();
      let tempYears = [];
      for (var i = tempStartYear; i <= tempEndYear; i++) tempYears.push(i);

      let tempDateRanges = [];
      tempYears.map((item) => {
        var theFirst = new Date(item, 0, 1).toDateString();
        var theLast = new Date(item, 11, 31).toDateString();
        tempDateRanges.push({
          start: theFirst,
          end: theLast,
          year: item,
        });
      });

      tempDateRanges[0]["start"] = convertToZoneDate(dateFilters?.startDate);
      tempDateRanges[tempDateRanges.length - 1]["end"] = convertToZoneDate(
        dateFilters?.endDate
      );

      let finalRanges = [],
        tempDatesToShow = [],
        tempDatesToSave = [];
      tempDateRanges.map((item) => {
        var tempYear = item.year,
          tempShow =
            formatDateNumeric(new Date(item.start)) +
            ":" +
            formatDateNumeric(new Date(item.end));

        finalRanges.push({
          start: item.start,
          end: item.end,
          textToShow: tempYear,
          textToSave: tempShow,
        });

        tempDatesToShow.push(tempYear);
        tempDatesToSave.push(tempShow);
      });

      setArrayOfDatesFormatted(tempDatesToShow);
      setArrayOfDatesNumeric(tempDatesToSave);
      setCarousalDate(carousalStartDate?.dateString || tempDatesToSave[0]);
    }
  };

  const updateMetricsValue = (selectedHierarchyLevel, selectedMetrics) => {
    const newConfig = selectedMetrics.map((metric) => {
      if (!metric) {
        return metric;
      }
      const slug = metric?.slug
      metric.checked = (availableMetrics === null || availableMetrics?.includes(slug)) && !disableMetricsForHierarchyLevel[selectedHierarchyLevel]?.includes(slug)
      return metric
    })
    return newConfig
  }

  useEffect(() => {
    if (isComparisonModeOn) {
      return;
    }
    let selectedMetric = updateMetricsValue(selectedHierarchyLevel, selectedMetrics);
    setSelectedMetrics([...selectedMetric]);
  }, [selectedHierarchyLevel]);

  useEffect(() => {
    if (
      !pagesContent.mounted || 
      pauseRerendering ||
      hierarchy === null ||
      decryptTheParams().redirectedFromAlertPage === "true"
    ) {
      return;
    }

    const dateString =
      carousalStartDate?.dateString || queryParams?.batteryPackMetrics;
    if (!dateString) {
      return;
    }
    const dates = dateString?.split(":");
    const startDate = dates?.[0] || null;
    const endDate = dates?.[1] || dates?.[0];
    if (dateType === "Raw Data" && operationType != "avg") {
      setOperationType("avg");
      return;
    }
    getChartData(startDate, endDate);
  }, [
    hierarchy,
    carousalStartDate,
    selectedComparisonModeMetric,
    operationType,
    pagesContent.mounted
  ]);

  useEffect(() => {
    if (finalObjectCSV?.responseStatus?.code === CODE.LOADING)
      openNotification("bottomLeft", "Preparing to download ... ", "info");
    else if (finalObjectCSV?.responseStatus?.code === CODE.SUCCESS) {
      closeNotification();
      const timer = setTimeout(() => {
        openNotification("bottomLeft", "Content Downloaded", "success");
      }, 100);
      return () => clearTimeout(timer);
    }
    else if (finalObjectCSV?.responseStatus?.code === CODE.NODATA) {
      closeNotification();

      const timer = setTimeout(() => {
        openNotification("bottomLeft", "No data found", "error");
      }, 100);
      return () => clearTimeout(timer);
    }
    return () => {
      closeNotification();
    };
  }, [finalObjectCSV]);

  // Old Code. Need to refactor it.
  useEffect(() => {
    if (pauseRerendering) {
      return;
    }
    const dataArray = [];
    for (let i = 0; i < 4; i++) {
      let newData = {
        name: selectedMetrics?.[i]?.label,
        data: selectedMetrics?.[i]?.checked
          ? chartMetricsValue?.[selectedMetrics?.[i]?.slug] || []
          : [],
        color: selectedMetrics?.[i]?.color,
        yMin: chartMetricsValue?.ranges?.[selectedMetrics?.[i]?.slug]?.min,
        yMax: chartMetricsValue?.ranges?.[selectedMetrics?.[i]?.slug]?.max,
      };

      if (selectedMetrics?.[i]?.status !== "active") {
        dataArray.push(newData);
        continue;
      }

      if (selectedMetrics?.[i]?.checked) {
        newData["data"] = chartMetricsValue?.[selectedMetrics?.[i]?.slug] || [];
      } else {
        newData["data"] = chartMetricsValue?.[selectedMetrics?.[i]?.slug]?.map(
          (item) => {
            return item?.x ? { x: item.x, y: null } : null;
          }
        );
      }
      dataArray.push(newData);
    }
    setDataSeriesForChart({ dataArray, dateType });
  }, [chartMetricsValue, selectedMetrics]);

  // Old Code. Need to refactor it later.
  useEffect(() => {
    if (pauseRerendering) {
      return;
    }
    const data = {
      ...optionData,
      dateType,
    };
    setNewOptionData(data);
    createCarousalDateRanges();
  }, [selectedHierarchy, dateFilters, dateType, operationType]);

  // Old Code. Need to refactor it later.
  useEffect(() => {
    if (pauseRerendering) {
      return;
    }
    if (dateFilters?.startDate && dateFilters?.endDate) {
      const dateDiff = DateDiffInDays(
        dateFilters?.startDate,
        dateFilters?.endDate
      );
      const temp = { ...queryParams };

      if (
        queryParams?.startDateSB !== dateFilters?.startDate ||
        queryParams?.endDateSB !== dateFilters?.endDate
      ) {
        temp["dateType"] = "Raw Data";
        temp["batteryPackMetrics"] = "";
        temp["startDateSB"] = dateFilters?.startDate;
        temp["endDateSB"] = dateFilters?.endDate;
        handleOperationTypeChange("avg");
        handleDateTypeChange("Raw Data");
        setCarousalDate(dateFilters?.startDate, temp);
      } else {
        encryptTheParams(temp, navigate, true);
      }
      setCalendarDateDiff(dateDiff);
    }
  }, [dateFilters]);

  // useEffect(() => {
  //   if (pauseRerendering) {
  //     return;
  //   }
  //   fetchRealTimeKPI();
  //   const interval = setInterval(fetchRealTimeKPI, 60 * 1000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const dataForSelectedHierarchy =
      selectedHierarchyLevel === "battery"
        ? liveMetricDataForBattery
        : hierarchyLiveMetricData?.[selectedHierarchy?.[0]?.toLowerCase()];
    structureRealTimeDataForNormalMode(dataForSelectedHierarchy);
  }, [liveMetricDataForBattery, hierarchyLiveMetricData]);

  useEffect(() => {
    if (isComparisonModeOn) {
      // fetchRealTimeKPIForHierarchy();
      return;
    }
    const dataForSelectedHierarchy =
      selectedHierarchyLevel === "battery"
        ? liveMetricDataForBattery
        : hierarchyLiveMetricData?.[selectedHierarchy?.[0]?.toLowerCase()];
    structureRealTimeDataForNormalMode(dataForSelectedHierarchy);
  }, [selectedHierarchy]);

  useEffect(() => {
    if (pauseRerendering) {
      return;
    }
    const dates = carousalStartDate?.dateString?.split(":");
    const startDate = dates?.[0] || null;
    const endDate = dates?.[1] || dates?.[0];
    generateAnnotationData(startDate, endDate);
  }, [carousalStartDate, showAnnotation, alertsForAnnotation, optionData]);

  useEffect(() => {
    if (pauseRerendering || tabValue !== "batterySystemTab") {
      return;
    }
    if (isComparisonModeOn) {
      Events(
        "On digital twin page under Vital Tab, comparison Mode is turned On"
      );
      openNotification("bottomLeft", "Comparison Mode");
      return;
    }
    Events(
      "On digital twin page under Vital Tab, comparison Mode is turned Off"
    );
    setMetricsValueForComparisonMode({
      metricConfig: selectedMetricsDataForVitalTab.find(
        (item) => item.slug === "current"
      ),
      series: [],
    });
    setSelectedComparisonModeMetric("current");
  }, [isComparisonModeOn]);

  const handleDrawerChange = (isOpen) => {
    Events(
      "On digital twin page under Vital Tab, the sidebar drawer is " +
        (isOpen ? "opened" : "closed")
    );
    setIsDrawerOpen(isOpen);
  };

  const structureCsvDataForComparisonMode = (dateType) => {
    let structuredData = []
    const seriesData = metricsValueForComparisonMode.series
    for (let index = 0; index < seriesData.length; index++) {
      const element = seriesData[index];
      element.data.map((item, index) => {
        structuredData[index] = {...structuredData[index], ...{
          Metric: metricsValueForComparisonMode.metricConfig.label,
          Timestamp: dateType === "Raw Data" ? item.x.replace(",", "").replace("'", " ") : item.x,
          "Operation Type": operationType,
          [element.name.toUpperCase()]: item.y?.toFixed(2),
        }}
      })
    }

    return structuredData
  }
  
  const structureCsvDataForNormalMode = (dateType) => {
    let structuredData = []

    if (dateType === "Raw Data") {
      const metrics = dataSeriesForChart.dataArray || [];
      const maxMetrics = 4;  // Since we only show the 4 metrics at a time

      metrics.slice(0, maxMetrics).forEach((metric) => {
        if (metric?.name) {
          metric.data.forEach((item, index) => {
            structuredData[index] = {
              ...structuredData?.[index],
              Timestamp: item.x.replace(",", "").replace("'", " "),
              "Operation Type": operationType,
              [metric.name]: item.y?.toFixed(2),
            };
          });
        }
      });

      return structuredData
    }
    
    structuredData = optionData.xAxisCategories.map((optionDate, index) => {
      const itemData = {
        Date: optionDate,
      };
      dataSeriesForChart.dataArray.forEach(item => {
        selectedMetrics.map(metrics => {
          if (metrics?.label === item?.name && metrics?.status === "active" && metrics?.checked) {
            let dynamicColumn = item.name === "Temperature (°C)" ? "Temperature (C)" : item.name
            itemData[dynamicColumn] = item.data[index]?.toFixed(2);
          }
        })
      });
      itemData["Operation Type"] = operationType
      return itemData;
    });

    return structuredData
  }

  const getChartDataCsv = () => {
    setFinalObjectCSV({
      data: [],
      responseStatus: {
        code: -1,
      },
    })
    const selectedDateType = dataSeriesForChart.dateType

    let finalData = []

    if (isComparisonModeOn) {
      finalData = structureCsvDataForComparisonMode(selectedDateType)
    } else {
      finalData = structureCsvDataForNormalMode(selectedDateType)
    }

    setFinalObjectCSV({
      data: finalData,
      responseStatus: {
        code: 200,
      },
    });

    DownloadCSV(NameTheCSV("BatteryVitals", ""), finalData);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRerenderChart((prev) => !prev);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [isDrawerOpen]);

  // Need to create styled components for the elements to remove styling from this file
  return (
    <Box
      sx={{
        ...props.style,
        borderBottom: "1px solid #CDDEEF",
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <Box
        style={{
          borderRight: "1px solid #CDDEEF",
          position: "fixed",
          height: "100%",
          background: "white",
          zIndex: 2,
        }}
      >
        <CustomMiniDrawer
          drawerBody={
            <Sidebar
              pagesContent={pagesContent}
              isDrawerOpen={isDrawerOpen}
              tabValue={tabValue}
              handleTabChange={handleTabChange}
              batteryID={batteryID}
              selectedAlertID={selectedAlertID}
              alertsFilters={alertsFilters}
              alertsSorts={alertsSorts}
              alertList={alertList}
              getAlerts={getAlerts}
              hierarchy={hierarchy}
              hierarchySequence={hierarchySequence}
              hierarchyLiveMetricData={hierarchyLiveMetricData}
              defaultHierarchyLevel={defaultHierarchyLevel}
              defaultHierarchy={defaultHierarchy}
              selectedHierarchy={selectedHierarchy}
              selectedHierarchyLevel={selectedHierarchyLevel}
              handleHierarchyItemSelect={handleHierarchyItemSelect}
              alertsCountHierarchical={alertsCountHierarchical}
              isAlertFetching={isAlertFetching}
              handleClickOnAlert={handleClickOnAlert}
            />
          }
          open={isDrawerOpen}
          onDrawerStateChange={(isOpen) => {
            handleDrawerChange(isOpen);
          }}
        />
      </Box>
      <Box
        className="digital-twin-chart-section"
        style={{
          width: "100%",
          height: "100%",
          paddingBottom: "20px",
          marginLeft: isDrawerOpen ? "262px" : "104px",
          zIndex: 0,
        }}
      >
        {tabValue === "predictiveAlertsTab" || (
          <Box>
            {/* Live Metric Section */}
            {!isComparisonModeOn ? (
              <Box
                style={{
                  width: "100%",
                  paddingLeft: "20px",
                  paddingBottom: "14px",
                  marginTop: "14px",
                  borderBottom: "1px solid #E9EFF5",
                }}
              >
                {getIdSection()}
                <Box
                  style={{
                    display: "flex",
                    marginLeft: "-20px",
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                  }}
                >
                  {getLiveMetricSection()}
                </Box>
              </Box>
            ) : (
              <>{getComparisonPlot()}</>
            )}
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "13px",
                marginBottom: "7px",
                paddingRight: "38px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                {isComparisonModeOn || (
                  <CustomSelect>
                    <BatteryMetricSelect
                      style={{
                        marginRight: "20px",
                      }}
                      availableMetrics={availableMetrics}
                      selectedMetrics={selectedMetrics}
                      onClickAddMetric={handleAddMetric}
                      onClickRemoveMetric={handleRemoveMetric}
                      selectedHierarchyLevel={selectedHierarchyLevel}
                    />
                  </CustomSelect>
                )}
                {isComparisonModeOn ? (
                  <FormControl>
                    <RadioGroup
                      style={{
                        paddingLeft: "20px",
                      }}
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={availableMetrics?.[0] || 'current'}
                      name="radio-buttons-group"
                      onChange={onComparisonModeMetricSelect}
                    >
                      {selectedMetrics.map((item) => {
                        let disableRadio = disableMetricsForHierarchyLevel?.[selectedHierarchyLevel]?.includes(item?.slug) || !availableMetrics?.includes(item?.slug);

                        return (
                          item?.showInComparisonMode && (
                            <FormControlLabel
                              value={item?.slug}
                              control={
                                <Radio
                                  disabled={disableRadio}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      height: 12,
                                      width: 12,
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "Roboto",
                                    fontWeight: "400",
                                    fontSize: "13px",
                                    lineHeight: "15px",
                                    color: disableRadio ? "#828A92" : "#151920",
                                  }}
                                >
                                  {item?.label}
                                </Typography>
                              }
                            />
                          )
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <ChartFilterBox>
                    {selectedMetrics?.map((item, index) => {
                      const isDisabled = disableMetricsForHierarchyLevel[selectedHierarchyLevel]?.includes(item?.slug) || !availableMetrics?.includes(item?.slug)
                      const disableColor = '#828A92'
                      const checkBoxColor = isDisabled ? disableColor : item?.color
                      return (
                        item?.status === "active" && (
                          <ChartFilter
                            control={
                              <Checkbox
                                key={`filterMetric${index}`}
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  padding: 0,
                                  marginBottom: "2px",
                                }}
                                icon={checkBoxIcon(
                                  checkBoxColor,
                                  "15px",
                                  "15px"
                                )}
                                disabled={isDisabled}
                                checkedIcon={checkedIcon(
                                  checkBoxColor,
                                  "15px",
                                  "15px"
                                )}
                                checked={isDisabled ? false : item.checked}
                                onChange={(e) => {
                                  onMetricFilterCheck(e.target.checked, index);
                                }}
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                  marginLeft: "8px",
                                  color: isDisabled ? disableColor : "#151920",
                                }}
                              >
                                {item.label}
                              </Typography>
                            }
                          />
                        )
                      );
                    })}
                  </ChartFilterBox>
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "11px",
                  }}
                >
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                  >
                    {tableFilters.map((item, index) => {
                      const isActive = dateType === item?.value;
                      const isDisabled =
                        isFetchingChartData ||
                        calendarDateDiff < item?.dateDiffDisableCondition;
                      return (
                        <Tooltip
                          placement="top"
                          title={item.label}
                          zIndex={2000}
                        >
                          <Button
                            sx={{ textTransform: "none" }}
                            style={{
                              padding: 0,
                              margin: 0,
                              background:
                                calendarDateDiff <
                                item?.dateDiffDisableCondition
                                  ? "#F3F5F6"
                                  : "transparent",
                              borderColor: "#E9EFF5",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderLeftWidth: index === 0 ? "1px" : "0",
                            }}
                            key={`calendarGroupingFilter${index}`}
                            onClick={() => handleDateTypeChange(item?.value)}
                            disabled={isDisabled}
                          >
                            <Box
                              style={{
                                width: "100%",
                                paddingTop: "6px",
                                paddingBottom:
                                  isActive && !isDisabled ? "1px" : "4px",
                                borderBottom:
                                  isActive && !isDisabled
                                    ? "3px solid #6D72F6"
                                    : "none",
                                borderRadius:
                                  index === 0
                                    ? "0 0 0 3px"
                                    : index === tableFilters.length - 1
                                    ? "0 0 3px 0"
                                    : "none",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: isDisabled ? "#828A92" : "#444B51",
                                  lineHeight: "20px",
                                }}
                              >
                                {item.key}
                              </Typography>
                            </Box>
                          </Button>
                        </Tooltip>
                      );
                    })}
                  </ButtonGroup>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                  >
                    {operations.map((item, index) => {
                      const isActive = operationType === item?.value;
                      const isDisabled =
                        isFetchingChartData || dateType === "Raw Data";
                      return (
                        <Tooltip
                          placement="top"
                          title={item.label}
                          zIndex={2000}
                        >
                          <Button
                            style={{
                              padding: 0,
                              margin: 0,
                              borderColor: "#E9EFF5",
                              background:
                                dateType === "Raw Data"
                                  ? "#F3F5F6"
                                  : "transparent",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderLeftWidth: index === 0 ? "1px" : "0",
                            }}
                            sx={{ textTransform: "none" }}
                            key={`operationsFilter${index}`}
                            onClick={() =>
                              handleOperationTypeChange(item?.value)
                            }
                            disabled={isDisabled}
                          >
                            <Box
                              style={{
                                width: "100%",
                                paddingTop: "6px",
                                paddingBottom:
                                  isActive && !isDisabled ? "1px" : "4px",
                                borderBottom:
                                  isActive && !isDisabled
                                    ? "3px solid #6D72F6"
                                    : "none",
                                borderRadius:
                                  index === 0
                                    ? "0 0 0 3px"
                                    : index === operations.length - 1
                                    ? "0 0 3px 0"
                                    : "none",
                              }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: "12px",
                                  paddingRight: "12px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: isDisabled ? "#828A92" : "#444B51",
                                  lineHeight: "20px",
                                }}
                              >
                                {item.key}
                              </Typography>
                            </Box>
                          </Button>
                        </Tooltip>
                      );
                    })}
                  </ButtonGroup>
                </Box>
                {getDownloadSection()}
              </Box>
            </Box>
            {isComparisonModeOn || (
              <Typography
                style={{
                  marginTop: "-10px",
                  paddingLeft: "49px",
                  fontWeight: "400",
                  fontSize: "10px",
                  lineHeight: "16px",
                  color: "#87939D",
                }}
              >
                Max. 4 can be selected
              </Typography>
            )}

            {/* Metrics Filter Section */}
            <Box
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                paddingRight: "56px",
                marginTop: isComparisonModeOn ? "0px" : "-4px",
                marginBottom: "9px",
              }}
            >
              <Tooltip placement="top" title={"Annotations"} zIndex={2000}>
                <Box>
                  <CustomSwitch
                    checked={showAnnotation === "true"}
                    onChange={toggleAnnotation}
                  />
                </Box>
              </Tooltip>
            </Box>

            {/* Chart Section */}
            <Box
              style={{
                height: "457px",
              }}
            >
              {isFetchingChartData && (
                <DigitalTwinChartLoader>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <PulseLoader
                      color={"#B2AFC3"}
                      size={30}
                      speedMultiplier={1}
                    />
                  </Box>
                  <Typography
                    style={{
                      color: "#B2AFC3",
                      marginTop: "10px",
                      fontSize: "14px",
                    }}
                  >
                    Loading
                  </Typography>
                </DigitalTwinChartLoader>
              )}

              {!isFetchingChartData && (
                <DigitalTwinApexMultiAxis
                  setChartRef={setChartRef}
                  key={`${rerenderChart}${isFetchingChartData}`}
                  isFetchingChartData={isFetchingChartData}
                  hierarchy={hierarchy}
                  isChartEmpty={isChartEmpty}
                  selectedHierarchyLevel={selectedHierarchyLevel}
                  isDrawerOpen={isDrawerOpen}
                  annotationData={annotationData}
                  batteryID={batteryID}
                  dataSeries={dataSeriesForChart}
                  selectedMetrics={selectedMetrics}
                  chartColorCoding={chartColorCoding}
                  viewportWidth={viewportWidth}
                  isComparisonModeOn={isComparisonModeOn}
                  selectedComparisonModeMetricData={
                    metricsValueForComparisonMode
                  }
                  optionData={optionData}
                  startDate={carousalStartDate?.dateString}
                  selectedAlertAnnotationID={selectedAlertAnnotationID}
                />
              )}

              {!isFetchingChartData && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "-5px",
                  }}
                >
                  <Box style={{ width: "60vw" }}>
                    <SlickCarousel
                      Data={arrayOfDates}
                      actualDates={arrayOfDatesNumeric}
                      setTheDate={setCarousalDate}
                      startDate={carousalStartDate?.dateString}
                      slidesToShow={dateType === "Raw Data" ? 7 : 4}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VitalTab;
