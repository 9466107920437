import { decryptTheParams } from "../Helper/QueryParams/EncryptDecrypt";

const initialSearchID = {
  value: decryptTheParams().selectedSearchID || ""
}

const initialSearchType = ""

const initialConfigValue = {
  value: {}
}

const SelectedSearchType = (state = initialSearchType, action) => {
  switch (action.type) {
    case "selectedSearchType":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const SelectedSearchID = (state = initialSearchID, action) => {
  switch (action.type) {
    case "selectedSearchID":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const SelectedSearchConfig = (state = initialConfigValue, action) => {
  switch (action.type) {
    case "selectedSearchConfig":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

export { SelectedSearchType,  SelectedSearchID, SelectedSearchConfig }